const USER_TYPE = {
  BUSINESS_CRYPTO: "BUSINESS-CRYPTO",
  HIGH_SCHOOL: "HIGH-SCHOOL",
  HIGH_SCHOOL_STAFF: "HIGH-SCHOOL-STAFF",
  INSTITUTION: "INSTITUTION",
  INSTITUTION_REPRESENTATIVE: "INSTITUTION-REPRESENTATIVE",
  NOVVA_ADMIN: "NOVVA-ADMIN",
  NOVVA_SELLER: "NOVVA-SELLER",
  STUDENT: "STUDENT",
  STUDENT_PARENT: "STUDENT-PARENT",
  AGENT: "AGENT", // deprecated
  COMMUNITY_COLLEGE: "COMMUNITY-COLLEGE", // deprecated
  COUNSELOR: "COUNSELOR", // deprecated
};

const NOVVA_APP_SOCKET_RESOURCE = "novva-app";

const NOVVA_APP_SOCKET_EVENT = {
  connections: `${NOVVA_APP_SOCKET_RESOURCE}/connections`,
  disconnect: `${NOVVA_APP_SOCKET_RESOURCE}/disconnect`,
  chatMessage: `${NOVVA_APP_SOCKET_RESOURCE}/chat-message`,
  typing: `${NOVVA_APP_SOCKET_RESOURCE}/typing`,
  stopTyping: `${NOVVA_APP_SOCKET_RESOURCE}/stop-typing`,
  joinedExhibitor: `${NOVVA_APP_SOCKET_RESOURCE}/joined-exhibitor`,
  joinedVisitor: `${NOVVA_APP_SOCKET_RESOURCE}/joined-visitor`,
  leave: `${NOVVA_APP_SOCKET_RESOURCE}/leave`,
  viewedMessage: `${NOVVA_APP_SOCKET_RESOURCE}/viewed-mensage`,
  receivedMessage: `${NOVVA_APP_SOCKET_RESOURCE}/received-mensage`,
  checkOnline: `${NOVVA_APP_SOCKET_RESOURCE}/check-online`,
  joinRoom: `${NOVVA_APP_SOCKET_RESOURCE}/join-room`,
  videoCallInvite: `${NOVVA_APP_SOCKET_RESOURCE}/video-call-invite`,
  deleteMessageLocal: `${NOVVA_APP_SOCKET_RESOURCE}/delete-message-local`,
  echo: `${NOVVA_APP_SOCKET_RESOURCE}/echo`,
};

const MESSAGE_STATUS = {
  SENDING: "SENDING",
  SENT: "SENT",
  VIEWED: "VIEWED",
};

module.exports = {
  USER_TYPE,
  NOVVA_APP_SOCKET_EVENT,
  MESSAGE_STATUS,
};
