<template>
  <div class="wallet">
    <Sidebar />
    <NavBar current="Wallet" />
    <LoadingContainer v-if="loadingAll"  />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Wallet</h1>
                    <h4 class="header-subtitle">View, add and manage your credits used towards engaging with new students who are using the NOVVA app.</h4>
                  </div>
                  <div class="col-auto">
                    <MonthSelector v-on:monthSelectorChanged="dateChanged" />
                  </div>
                  <div class="col-auto">
                    <router-link
                      :to="hasSubscription() ? '' : $paths.wallet.add_credits"
                      tag="a"
                      class="nav-item btn btn-primary me-3"
                      :class="{ 'disabled': hasSubscription() }"
                    >
                      <span class="fas fa-coins display-6"></span> Add credits
                    </router-link>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul
                      class="nav nav-tabs header-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link"
                          :class="{'active': selectedTab == 0}"
                          id="deposits-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#deposits"
                          type="button"
                          role="tab"
                          aria-controls="deposits"
                          aria-selected="true"
                        >
                          Deposits
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 0,
                              'bg-secondary-soft': selectedTab != 0,
                            }"
                            >{{deposits.length || 0}}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link"
                          :class="{'active': selectedTab == 1}"
                          id="purchases-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#purchases"
                          type="button"
                          role="tab"
                          aria-controls="purchases"
                          aria-selected="false"
                        >
                          Spending
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{purchases.length || 0}}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <!-- DEPOSITS TAB -->
              <div
                class="mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 0}"
                id="deposits"
                role="tabpanel"
                aria-labelledby="deposits-tab"
              >
                <div class="row align-items-center">
                  <div class="col">
                    <form class="search-table-form">
                      <div
                        class="
                          input-group
                          input-group-flush
                          input-group-merge
                          input-group-reverse
                        "
                      >
                        <input
                          class="form-control list-search "
                          type="search"
                          placeholder="Search by name"
                          v-model="searchDeposits"
                        />
                        <span class="input-group-text">
                          <i class="fas fa-search ps-3"></i>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>

                <EmptyState
                  title="No Deposits"
                  msg="It looks like you have no deposits"
                  v-if="deposits.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-coins fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="deposits.length > 0"
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.deposits"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in findDeposits()"
                      v-bind:key="item.id"
                      class="cursor-pointer"
                    >
                      <td class="text-start">
                        <div class="avatar avatar-md me-3">
                          <img
                            v-if="item.boughtBy.image"
                            class="avatar-img rounded-circle rounded-3"
                            :src="item.boughtBy.image"
                            alt="Representative image"
                          />
                          <img
                            v-else
                            style="object-fit: contain"
                            class="avatar-img rounded-circle rounded-3"
                            src="@/assets/img/no-img-placeholder.png"
                            alt="Representative image"
                          />
                        </div>
                        <strong>
                          {{ isRepresentative(item.boughtBy) ? item.boughtBy.name : item.boughtBy.profile.institution.contact.name}}
                        </strong>
                      </td>
                      <td>{{$moment(item.createdAt).format('MM-DD-YYYY')}}</td>
                      <td>{{ item.totalCredits }}</td>
                      <td>
                        <strong>Us${{ item.creditPack.amount }}</strong>
                      </td>
                      <td>
                        <div v-if="item.invoice">
                          <span v-if="item.invoice.status == 'PAID'" class="ms-auto badge bg-success-soft">
                            <strong>Paid</strong>
                          </span>
                          <span v-else class="ms-auto badge bg-danger-soft">
                            <strong>Pending</strong>
                          </span>
                        </div>
                      </td>
                      <td style="width: 20px;">
                        <span 
                          v-if="item.creditPack"
                          class="fa fa-ellipsis-h cursor-pointer"
                          @click="openModalRepresentatives(item.availableTo)">
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- BALANCE TAB -->

              <div
                class="mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 1}"
                id="purchases"
                role="tabpanel"
                aria-labelledby="purchases-tab"
              >
                <div class="row align-items-center">
                  <div class="col-6">
                    <form class="search-table-form">
                      <div
                        class="
                          input-group
                          input-group-flush
                          input-group-merge
                          input-group-reverse
                        "
                      >
                        <input
                          class="form-control list-search "
                          type="search"
                          placeholder="Search by name"
                          v-model="searchPurchases"
                        />
                        <span class="input-group-text">
                          <i class="fas fa-search ps-3"></i>
                        </span>
                      </div>
                    </form>
                  </div>
                  <div class="col-6 mt-4">
                    <BaseSelectTags
                      :id="'component-exams'"
                      v-model="representativesSelected"
                      :is-object="true"
                      :options="representatives"
                      :placeholder="'Filter by accounts'"
                      autocomplete="off"
                      @input="findPurchases()"
                    />
                  </div>
                </div>
                <EmptyState
                  title="No Transactions"
                  msg="It looks like you have no transactions"
                  v-if="purchases.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-receipt fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="purchases.length > 0"
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.purchases"
                        :key="column"
                        :class="{ 'text-start': [0, 1].includes(index) }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in findPurchases()"
                      v-bind:key="item.id"
                    >
                      <td class="text-start">
                        <div class="avatar avatar-md me-3">
                          <img
                            v-if="item.boughtBy.image"
                            class="avatar-img rounded-circle rounded-3"
                            :src="item.boughtBy.image"
                            alt="Representative image"
                          />
                          <img
                            v-else
                            style="object-fit: contain"
                            class="avatar-img rounded-circle rounded-3"
                            src="@/assets/img/no-img-placeholder.png"
                            alt="Representative image"
                          />
                        </div>
                        <strong>
                          {{ isRepresentative(item.boughtBy) ? item.boughtBy.name : item.boughtBy.profile.institution.contact.name}}
                        </strong>
                      </td>
                      <td @click="openStudent(item)" class="text-start cursor-pointer">
                        <strong>{{item.student.name}}</strong>
                      </td>
                      <td>{{$moment(item.createdAt).format('MM-DD-YYYY')}}</td>
                      <td class="fw-bold">{{ item.userCreditPack ? item.usedCredits : 'Subscription' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent id="availableTo_modal" title="Credit Usage">
      <template v-slot:body>
        <h4>These credits have been shared with the following users:</h4>
        <table
          v-if="availableTo && availableTo.length > 0"
          class="
            table table-sm table-hover table-nowrap
            card-table
            bg-white
          "
        >
          <thead>
            <tr>
              <th
                v-for="(column, index) in columns.availableTo"
                :key="column"
                class="text-start"
                :width="index != 0 ? '1%' : 'auto'"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody class="list">
            <tr
              v-for="item in availableTo"
              v-bind:key="item.id"
              class="cursor-pointer"
            >
              <td syle="width:5%;">
                <div class="avatar avatar-xs">
                  <img
                    v-if="item.image"
                    class="avatar-img rounded-circle rounded-3"
                    :src="item.image"
                    alt="Representative image"
                  />
                  <img
                    v-else
                    style="object-fit: contain"
                    class="avatar-img rounded-circle rounded-3"
                    src="@/assets/img/no-img-placeholder.png"
                    alt="Representative image"
                  />
                </div>
              </td>
              <td style="width: 95%" class="text-start">
                <strong>{{item.name}}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import WalletController from "./WalletController";
export default WalletController;
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>