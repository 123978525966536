<template>
  <div>
    <Sidebar />
    <NavBar current="Member Detail" :link="$paths.team.list" linkName="Team" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-8" v-else>
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Add Member</h6>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs nav-overflow header-tabs">
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab >= 0 ? selectedTab = 0 : false"
                    :class="{ active: selectedTab == 0 }"
                    id="info-tab"
                    data-bs-target="#info"
                    type="button"
                    role="tab"
                  >
                    Basic info
                  </a>
                </li>
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab >= 1 ? selectedTab = 1 : false"
                    :class="{ active: selectedTab == 1 }"
                    id="territories-tab"
                    data-bs-target="#territories"
                    type="button"
                    role="tab"
                    @disabled="selectedTab < 1"
                  >
                    Territories
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="row justify-content-between align-items-center mb-5">
          <div class="tab-content" id="myTabContent">
            <!-- BASIC INFO -->
            <div
              class="table-responsive mt-1 tab-pane fade overflow-hidden"
              :class="{'show active': selectedTab == 0}"
              id="info"
              role="tabpanel"
              aria-labelledby="info-tab"
            >
              <form>
                <div class="col">
                  <div class="row align-items-end mb-4">
                    <div class="col-auto">
                      <div class="avatar avatar-xxl header-avatar-top">
                        <img
                          :src="form.image"
                          alt="..."
                          id="profile-img"
                          class="avatar-img rounded-circle border-white"
                        />
                        <span
                          v-if="loadingMemberImg"
                          class="spinner-border spinner-border-sm text-primary"
                          role="status"
                          style="
                            bottom: -4px;
                            right: -5px;
                            position: absolute;
                            z-index: 20;
                            width: 30px;
                            height: 30px;
                          "
                          aria-hidden="true"
                        ></span>
                        <span
                          v-else
                          class="fas fa-pen text-primary"
                          style="
                            bottom: -4px;
                            right: -5px;
                            position: absolute;
                            font-size: 1.3rem;
                            z-index: 20;
                          "
                        ></span>
                        <input
                          ref="input"
                          type="file"
                          name="image"
                          accept="image/*"
                          style="left: 0; z-index: 21"
                          @change="setMemberImage"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="form-label"> {{labels.firstname}} </label>
                        <input
                          type="email"
                          :class="{ 'is-invalid': $v.form.firstname.$error }"
                          class="form-control"
                          v-model.trim="$v.form.firstname.$model"
                        />
                        <div class="invalid-feedback">
                          <small>Please fill in this field correctly</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="form-label"> {{labels.lastname}} </label>
                        <input
                          type="email"
                          :class="{ 'is-invalid': $v.form.lastname.$error }"
                          class="form-control"
                          v-model.trim="$v.form.lastname.$model"
                        />
                        <div class="invalid-feedback">
                          <small>Please fill in this field correctly</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <div class="form-group">
                        <label class="form-label"> Position </label>
                        <input
                          type="email"
                          :class="{ 'is-invalid': $v.form.position.$error }"
                          class="form-control"
                          v-model.trim="$v.form.position.$model"
                        />
                        <div class="invalid-feedback">
                          <small>Please fill in this field correctly</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"> Email </label>
                        <input
                          type="text"
                          :class="{ 'is-invalid': $v.form.email.$error }"
                          class="form-control"
                          v-model.trim="$v.form.email.$model"
                        />
                        <div class="invalid-feedback">
                          <small>Please fill in this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button
                        size="sm"
                        class="btn w-100 btn-primary my-3"
                        @click.prevent="nextStep()"
                        :disabled="invalidBasicInfo"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- TERRITORIES -->
            <div 
              id="territories"
              role="tabpanel"
              aria-labelledby="territories-tab"
              class="table-responsive mt-5 tab-pane fade overflow-hidden"
              :class="{'show active': selectedTab == 1}"
            >
              <label class="form-label">Responsible for the following territories:</label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group"
                    :label="labels.territory"
                    label-for="territory"
                  >
                    <BaseFormInputLocation
                      id="territory"
                      v-model="region"
                      :value="region"
                      :types="['(regions)']"
                      placeholder="Enter the name of the country or region"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    size="sm"
                    class="btn btn-sm btn-primary"
                    @click.prevent="addTerritory(region)"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div class="row" v-if="territories.length > 0">
                <div class="col-12">
                  <div class="form-group">
                    <ul class="list-group list-group-horizontal territories-list">
                      <li class="list-group-item" v-for="item in territories" v-bind:key="item.id">
                        {{ toLocationFormat(item) }} 
                        <span
                          class="cursor-pointer fas fa-times text-danger ms-2"
                          @click.prevent="removeTerritory(item.id)"
                        >
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    size="sm"
                    class="btn w-100 btn-primary my-3"
                    @click.prevent="saveMember"
                    :disabled="!territories.length"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Container>
    <ModalComponent
      title="Crop Image"
      description="To ensure the most professional look, please try and use a logo with 1:1 (square) dimensions"
      id="memberImageModal"
    >
      <template v-slot:body>
        <vue-cropper
          :modal="false"
          :movable="false"
          :zoomable="false"
          :original="true"
          :background="true"
          ref="cropperMember"
          :src="imgSrc"
          alt="Source Image"
          :viewMode="2"
          :aspect-ratio="1"
          :minContainerWidth="550"
          :minContainerHeight="$helper.cropMinHeight"
          :aspectRatio="1/1"
          :initialAspectRatio="1/1"
        >
        </vue-cropper>
      </template>
      <template v-slot:footer>
        <button type="button" @click="cropMemberImage" class="btn btn-primary">
          Crop
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AddMemberController from "./AddMemberController";
export default AddMemberController;
</script>

<style lang="scss">
#profile-img {
  -webkit-box-shadow: 5px 5px 15px -6px #000000;
  box-shadow: 5px 5px 15px -6px #000000;
}
.avatar-img {
  /* width: 200px; */
}

.header-avatar-top {
  width: 100%;
}
input[type="file"] {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.card-programs {
  max-width: 178px;
  min-height: 210px;
  background-color: #F2F2F2;
  .card-body {
    padding-top: 0px;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }
  .card-header {
    border: none;
    padding: none;
    height: 8px;
    padding-right: 6px;
  }
}

.programs-list {
  margin-top: 25px;

  li {
    background-color: #F2F2F2;
    width: 180px;
    height: 210px;
    padding: 20px;
    border-radius: 10px !important;
    margin-right: 15px;
    font-weight: bold;
    color: #434040;
    border: none;
  }
}

.territories-list {
  margin-top: 25px;

  li {
    background-color: #F2F2F2;
    padding: 7px;
    border-radius: 10px !important;
    margin-right: 15px;
    font-weight: bold;
    color: #434040;
    border: none;
  }
}
</style>