<template>
  <div class="comment">
    <div class="row">
      <div class="col-auto cursor-pointer" @click="openStudent(comment)">
        <ImagePlaceholder :src="comment.user.image">
          <template v-slot:placeholder>
            <span class="fas fa-user-circle text-primary icon-placeholder"></span>
          </template>
        </ImagePlaceholder>
      </div>
      <div class="col ms-n2">
        <div class="comment-body w-100">
          <div class="row">
            <div class="col">
              <h5 class="comment-title h4 cursor-pointer" @click="openStudent(comment)">{{ getCommentUserName(comment) }} 
                <small class="text-muted">({{comment.user.profile.location.country}})</small>
              </h5>
            </div>
            <div class="col-auto">
              <time class="comment-time">
                {{ $helper.getRemainingTime(comment.createdAt, true) }}
              </time>
            </div>
          </div>
          <p class="comment-text">
            {{ comment.content }}
          </p>
        </div>
        <div class="actions d-flex">
          <button
              v-if="showActionReply"
              class="btn btn-sm px-0 btn-link"
              @click="viewReplies">Reply</button>
          <button
              v-if="hasReplies && !showReplies"
              class="btn btn-sm px-0 btn-link"
              @click="viewReplies">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"/>
            </svg> {{ repliesCount }} {{ repliesCount > 1 ? 'Replies' : 'Reply'}}
          </button>
        </div>

        <div v-if="showReplies" class="replies">
          <div v-for="(reply, index) in comment.replies" class="comment mt-3" v-bind:key="'replies-' + index">
            <div class="row">
              <div class="col-auto cursor-pointer" @click="openStudent(reply)">
                <ImagePlaceholder :src="reply.user.image">
                  <template v-slot:placeholder>
                    <span class="fas fa-user-circle text-primary icon-placeholder"></span>
                  </template>
                </ImagePlaceholder>
              </div>
              <div class="col ms-n2">
                <div class="comment-body w-100">
                  <div class="row">
                    <div class="col">
                      <h5 class="comment-title h4 cursor-pointer" @click="openStudent(reply)">{{ getCommentRepplyUserName(reply) }} 
                        <small class="text-muted">
                            {{ reply.user.type == 'STUDENT' ? `(${reply.user.profileStudent.location.country})` : ''}}
                        </small>
                      </h5>
                    </div>
                    <div class="col-auto">
                      <time class="comment-time">
                        {{ $helper.getRemainingTime(reply.createdAt, true) }}
                      </time>
                    </div>
                  </div>
                  <p class="comment-text">
                    {{ reply.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="canReply && showReplies" class="input-reply mt-3">
          <input
              type="text"
              v-model.trim="reply"
              :placeholder="`Reply to ${ comment.user.name }`"
              class="form-control"
              @keyup.enter="sendReply"
              :disabled="loadingReply"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_TYPE } from "@/utils/Constants";

export default {
  name: "CommentComponent",
  props: {
    comment: {
      type: Object,
      required: true
    },
    canReply: {
      type: Boolean,
      default: false
    },
    followers: {
      type: Array
    }
  },
  data() {
    return {
      showReplies: false,
      reply: '',
      loadingReply: false
    }
  },
  computed: {
    repliesCount() {
      return this.comment.replies.length;
    },
    hasReplies() {
      return this.repliesCount > 0;
    },
    showActionReply() {
      return this.canReply && !this.showReplies && !this.hasReplies;
    }
  },
  methods: {
    openStudent(item) {
      if (item.user.type == USER_TYPE.STUDENT) {
        const newRoute = this.$router.resolve({ path: `${this.$paths.students.detail}/${item.user._id}` })
        window.open(newRoute.href, '_blank');
      }
    },
    getCommentUserName(comment) {
      let isFollower = this.followers.includes(comment.user._id)
      return isFollower || comment.user.type != USER_TYPE.STUDENT ? comment.user.name : comment.user.profile.firstname
    },
    getCommentRepplyUserName(repply) {
      let isFollower = this.followers.includes(repply.user._id)
      return isFollower || repply.user.type != USER_TYPE.STUDENT ? repply.user.name : repply.user.profileStudent.firstname
    },
    viewReplies() {
      this.showReplies = true;
    },
    async sendReply() {
      if(this.reply === '') return;

      this.loadingReply = true;
      try {
        const { data: { data } } = await this.$socialService.createReply(this.comment._id, {
          comment: this.reply,
          timezone: 'US/Central'
        });

        this.reply = ''
        this.comment.replies.push(data)
        this.$emit('commentAdded', data)
      } catch (e) {
        console.log(e)
      } finally{
        this.loadingReply = false;
      }
    }
  }
}
</script>

<style scoped>
.comment-body {
  border-top-left-radius: 0;
}
.input-reply {
  margin-left: 3.5rem;
}
.icon-placeholder {
  font-size: 2.6em;
}
</style>
