<template>
  <div class="mobile-profile-preview">
    <div class="open-viewer shadow" id="button-profile-preview" @click="fetchProfile">
      <div class="open-viewer-tooltip">Preview your profile</div>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <i class="fas fa-mobile-alt fa-2x"></i>
      </div>
    </div>
    <template v-if="modal">
      <div class="preview-modal">
        <div class="close-preview d-flex justify-content-center align-items-center" @click="handleClosePreview">
          <div
            class="cursor-pointer text-white"
          >
            <i class="fas fa-times-circle fa-lg me-1"></i> Close preview
          </div>
        </div>
        <LoadingContainer v-if="loading"/>
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="mobile-container">
            <div class="profile-carousel">
              <template v-if="photos.length > 0">
                <transition-group tag="div" name="slide">
                  <div
                    class="carousel-image"
                    v-for="(photo, photoIndex) in photos"
                    :key="`carousel-image-${photoIndex}`"
                    v-show="photoIndex === currentPhoto"
                  >
                    <img :src="photo" />
                  </div>
                </transition-group>
              </template>
            </div>
            <div class="profile-header">
              <div class="profile-logo">
                <img :src="institution.image" />
              </div>
              <div class="profile-match">100% match</div>
              <div class="profile-info">
                <h1>{{ institution.name }}</h1>
                <h3>
                  {{ institution.location.city }},
                  {{ institution.location.region }} -
                  {{ institution.location.country }}
                </h3>
              </div>
              <div class="profile-stats">
                <span class="posts-count">{{ postsCount }} Posts</span>
                <span class="followers-count"
                  >{{ followersCount }} Followers</span
                >
              </div>
              <div class="profile-tabs">
                <div class="tab-item">
                  <div class="tab-icon">
                    <img :src="icons.aboutIcon" />
                  </div>
                  <div class="tab-label">About</div>
                </div>
                <div class="tab-item">
                  <div class="tab-icon">
                    <img :src="icons.chatIcon" />
                  </div>
                  <div class="tab-label">Chat</div>
                </div>
                <div class="tab-item">
                  <div class="tab-icon">
                    <img :src="icons.meetIcon" />
                  </div>
                  <div class="tab-label">Meet</div>
                </div>
                <div class="tab-item">
                  <div class="tab-icon">
                    <img :src="icons.applyIcon" />
                  </div>
                  <div class="tab-label">Apply</div>
                </div>
                <div class="tab-item">
                  <div class="tab-icon">
                    <img :src="icons.unfollowIcon" />
                  </div>
                  <div class="tab-label">Unfollow</div>
                </div>
              </div>
            </div>
            <div class="profile-body">
              <template v-if="aboutSections.length > 0">
                <div
                  class="body-labels grab-bing"
                  ref="bodyLabels"
                  @mousedown="(ev) => handleMouseDown(ev, 'bodyLabels')"
                  @mouseup="handleMouseUp"
                >
                  <div
                    class="tab-label"
                    :class="{ active: tab.key === currentTab }"
                    v-for="tab in aboutSections"
                    @click="handleChangeTab(tab)"
                    :key="tab.key"
                  >
                    {{ tab.name }}
                  </div>
                </div>
              </template>
              <div class="body-tab">
                <template v-if="filteredTabContent.length > 0">
                  <div
                    class="body-item"
                    :class="{
                      'border-bottom':
                        tabItemIndex + 1 !== filteredTabContent.length,
                    }"
                    v-for="(tabItem, tabItemIndex) in filteredTabContent"
                    :key="tabItemIndex"
                  >
                    <template
                      v-if="
                        tabItem.title &&
                        !['VIDEO', 'FILE', 'PHOTOS'].includes(tabItem.type)
                      "
                    >
                      <div class="item-title">
                        <template v-if="tabItem.meta && tabItem.meta.icon">
                          <div class="item-title-icon">
                            <img :src="tabItem.meta.icon" />
                          </div>
                        </template>
                        <p
                          :class="{
                            'title-small':
                              tabItem.meta &&
                              tabItem.meta.titleSize === 'small',
                          }"
                        >
                          {{ tabItem.title }}
                        </p>
                      </div>
                    </template>

                    <template v-if="tabItem.type == 'VIDEO'">
                      <div class="item-video">
                        <iframe
                          :src="handleEmbeddedYoutubeURL(tabItem.value)"
                          :title="tabItem.title"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                          class="rounded-1 me-3 w-100"
                        ></iframe>
                        <template v-if="tabItem.title">
                          <div class="video-title">
                            {{ tabItem.title }}
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="tabItem.type == 'QUICK-LINKS'">
                      <div class="item-quick-links">
                        <div
                          class="quick-link-content"
                          v-for="(quickLink, quickLinkIndex) in tabItem.value"
                          :key="`quick-link-${quickLinkIndex}`"
                          v-if="quickLink.url"
                        >
                          <i class="quick-link-icon fas fa-chevron-right"></i>
                          <p class="quick-link-title">{{ quickLink.title }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="tabItem.type == 'GENERAL-LINKS'">
                      <div class="row item-general-links">
                        <div
                          class="general-link-content col-6"
                          v-for="(
                            generalLink, generalLinkIndex
                          ) in tabItem.value"
                          :key="`general-link-${generalLinkIndex}`"
                          v-if="generalLink.url"
                          @click="handleOpenLink(generalLink.url)"
                        >
                          <div class="general-link-icon">
                            <img :src="generalLink.meta.icon" />
                          </div>

                          <p class="general-link-title">
                            {{ generalLink.title }}
                          </p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="tabItem.type == 'TAGS'">
                      <template
                        v-if="tabItem.value && tabItem.value.length > 0"
                      >
                        <div class="item-tags">
                          <div
                            class="tag-content"
                            v-for="(tag, tagIndex) in tabItem.value"
                            :key="`tag-${tagIndex}`"
                          >
                            {{ tag }}
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-danger">
                          Information not provided yet
                        </div>
                      </template>
                    </template>
                    <template v-else-if="tabItem.type == 'FILE'">
                      <div
                        class="item-file"
                        @click="handleOpenLink(tabItem.value)"
                      >
                        <div class="file-icon">
                          <i class="far fa-file-alt"></i>
                        </div>
                        <div class="file-title">
                          {{ tabItem.title }}
                        </div>
                      </div>
                    </template>
                    <template v-else-if="tabItem.type == 'PHOTOS'">
                      <div class="item-albums">
                        <template
                          v-if="tabItem.value && tabItem.value.length > 0"
                        >
                          <div
                            class="album-photos grab-bing"
                            :ref="`album_${tabItemIndex}`"
                            @mousedown="
                              (ev) =>
                                handleMouseDown(ev, `album_${tabItemIndex}`)
                            "
                            @mouseup="handleMouseUp"
                          >
                            <div
                              class="photo-content"
                              v-for="(photo, photoIndex) in tabItem.value"
                              :key="`photo-${photoIndex}`"
                            >
                              <img :src="photo" />
                            </div>
                          </div>

                          <p class="album-title">
                            {{ tabItem.title }}
                          </p>
                        </template>
                        <template v-else>
                          <div class="text-danger">
                            Information not provided yet
                          </div>
                        </template>
                      </div>
                    </template>
                    <template
                      v-else-if="
                        ['TEXT', 'CURRENCY', 'PERCENT'].includes(tabItem.type)
                      "
                    >
                      <template
                        v-if="
                          ['string', 'number'].includes(typeof tabItem.value)
                        "
                      >
                        <template v-if="tabItem.value">
                          <template v-if="tabItem.type == 'TEXT'">
                            <div
                              class="item-content"
                              v-html="tabItem.value"
                            ></div>
                          </template>
                          <template v-else-if="tabItem.type == 'CURRENCY'">
                            <div class="item-content">
                              ${{ tabItem.value }} USD
                            </div>
                          </template>
                          <template v-else-if="tabItem.type == 'PERCENT'">
                            <div class="item-content">{{ tabItem.value }}%</div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="text-danger">
                            Information not provided yet
                          </div>
                        </template>
                      </template>
                      <template
                        v-else-if="
                          ['object', 'array'].includes(typeof tabItem.value)
                        "
                      >
                        <template
                          v-if="tabItem.value && tabItem.value.length > 0"
                        >
                          <div
                            v-for="(val, valIndex) in tabItem.value"
                            class="item-content"
                            v-html="val"
                            :key="valIndex"
                          ></div>
                        </template>
                        <template v-else>
                          <div class="text-danger">
                            Information not provided yet
                          </div>
                        </template>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingContainer from "@/components/LoadingContainer.vue";
import { mapActions } from "vuex";

export default {
  name: "MobileProfilePreview",
  data() {
    return {
      modal: false,
      loading: false,
      currentTab: null,
      tabContent: [],
      aboutSections: [],
      albums: [],
      currentPhoto: 0,
      cursorPos: [0, 0],
      isDragging: false,
      el: null,
      institution: {
        image: "",
        name: "",
        location: {
          city: "",
          region: "",
          country: "",
        },
      },
      postsCount: 0,
      followersCount: 0,
      icons: {
        aboutIcon: require("../assets/img/about_tab_icon_active.png"),
        chatIcon: require("../assets/img/chat_tab_icon_inactive.png"),
        meetIcon: require("../assets/img/meet_tab_icon_inactive.png"),
        applyIcon: require("../assets/img/apply_tab_icon_inactive.png"),
        unfollowIcon: require("../assets/img/unfollow_tab_icon_inactive.png"),
      },
      interval: null,
    };
  },
  mounted() {
    window.addEventListener("mouseup", this.handleMouseUp);
    // const ytScript = document.createElement("script");
    // ytScript.src = "https://www.youtube.com/iframe_api";

    // const firstScriptTag = document.getElementsByTagName("script")[0];
    // firstScriptTag.parentNode.insertBefore(ytScript, firstScriptTag);
  },
  destroyed() {
    window.removeEventListener("mouseup", this.handleMouseUp);
    clearInterval(this.interval);
  },
  watch: {
    photos: {
      handler(photos) {
        if (photos.length > 0) {
          this.interval = setInterval(() => {
            if (this.currentPhoto >= photos.length - 1) {
              this.currentPhoto = 0;
            } else if (this.currentPhoto < photos.length - 1) {
              this.currentPhoto++;
            }
          }, 6000);

          return;
        }

        clearInterval(this.interval);
      },
      immediate: true,
    },
  },
  computed: {
    photos() {
      let arr = [];
      this.albums.map((album) => {
        album.photos.map((photo) => arr.push(photo));
      });

      return arr;
    },
    filteredTabContent() {
      let arr = [];
      this.tabContent.map((tab) => {
        tab.collapseItems.map((item) => {
          let agreed = false;
          switch (item.type) {
            case "VIDEO":
              agreed = item.value ? true : false;
              break;
            case "QUICK-LINKS":
            case "GENERAL-LINKS":
              if (!item.value || item.value.length === 0) {
                agreed = false;
                break;
              }

              agreed = item.value.filter((v) => v.url).length > 0;
              break;
            default:
              if (item.meta?.hideOnNull) {
                if (!item.value) {
                  agreed = false;
                  break;
                }

                switch (typeof item.value) {
                  case "array":
                    agreed = item.value.length > 0;
                    break;
                  case "object":
                    agreed = Object.entries(item.value).length > 0;
                    break;
                }
              }

              agreed = true;
          }

          if (agreed) {
            arr.push(item);
          }
        });
      });
      return arr;
    },
  },
  methods: {
    ...mapActions(["setLoadingPreview"]),
    async fetchProfile() {
      this.loading = true;
      this.modal = true;
      this.setLoadingPreview(true)
      try {
        const {
          data: { data },
        } = await this.$universityService.fetchMobileProfilePreview();

        if (data) {
          if (data.aboutSections && data.aboutSections.length > 0) {
            this.aboutSections = [
              ...data.aboutSections.sort(
                (a, b) => parseInt(a.order) - parseInt(b.order)
              ),
            ].filter((elem) => elem.content?.length || elem.content);

            if (this.aboutSections.length > 0) {
              this.currentTab = this.aboutSections[0].key;
              this.tabContent = this.aboutSections[0].content;
            }
          }
          if (data.albums) {
            this.albums = [...data.albums];
          }
          if (data.image && data.name && data.institution) {
            this.institution.image = data.image;
            this.institution.name = data.name;
            this.institution.location = data.institution.location;
          }
          if (data.postsCount) {
            this.postsCount = data.postsCount;
          }

          if (data.followersCount) {
            this.followersCount = data.followersCount;
          }
        }
      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
        this.setLoadingPreview(false)
      }
    },
    handleClosePreview() {
      if (!this.isDragging) {
        this.modal = false;
      }
    },
    handleChangeTab(tab) {
      this.currentTab = tab.key;
      this.tabContent = tab.content;
    },
    handleMouseUp(e) {
      this.el = null;
      this.cursorPos = [0, 0];
      window.removeEventListener("mousemove", this.handleMouseHold);

      this.isDragging = false;
    },
    handleMouseDown(e, refs) {
      this.isDragging = true;
      this.el = this.$refs[refs];

      this.cursorPos = [e.pageX, e.pageY];

      window.addEventListener("mousemove", this.handleMouseHold);
    },
    handleMouseHold(e) {
      e.preventDefault();

      requestAnimationFrame(() => {
        const delta = [
          e.pageX - this.cursorPos[0],
          e.pageY - this.cursorPos[1],
        ];

        this.cursorPos = [e.pageX, e.pageY];

        if (!this.el) return;

        if (Array.isArray(this.el)) this.el = this.el[0];

        this.el.scrollBy({
          left: -delta[0],
          top: -delta[1],
        });
      });
    },
    handleOpenLink(url) {
      window.open(url, "_blank").focus();
    },
    handleEmbeddedYoutubeURL(url) {
      return this.$helper.getEmbededUrl(this.$helper.youtubeParser(url));
    },
  },
};
</script>

<style lang="scss" scoped>
#main-row {
  margin-left: 0px;
  padding-left: 0px;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.mobile-profile-preview {
  .grab-bing {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: grab;
  }

  .grab-bing:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: grabbing;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .open-viewer {
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 50%;
    background-color: #ed6125;
    color: #fff;
    width: 60px;
    height: 60px;
    z-index: 99;
    cursor: pointer;

    .open-viewer-tooltip {
      opacity: 0;
      position: absolute;
      top: 18px;
      left: -180px;
      white-space: nowrap;
      background-color: #000;
      color: #fff;
      padding: 3px 15px 3px 10px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      z-index: 98;
      transition: all 0.2s ease-in-out;

      &::before {
        content: "";
        width: 22px;
        height: 22px;
        background-color: #000;
        position: absolute;
        top: 3px;
        z-index: 97;
        right: -10px;
        border-radius: 4px;
        transform: rotate(45deg);
      }
    }

    &:hover {
      .open-viewer-tooltip {
        opacity: 1;
      }
    }
  }

  .preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000062;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-image: url('../assets/img/smartphone_background.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;

    .close-preview {
      background-color: black;
      border-radius: 12px;
      position: absolute;
      font-size: 13px;
      font-weight: bold;
      width: 130px;
      height: 32px;
      overflow-y: auto;
      overflow-x: hidden;
      top: calc(50% - 405px);
      left: calc(50% - 65px);
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    .mobile-container {
      border-radius: 36px !important;
      background-color: #fff;
      width: 325px;
      height: 710px;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 15px;
      position: relative;
      &::-webkit-scrollbar {
        width: 0px;
      }

      .profile-carousel {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        width: 100%;
        height: 200px;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7679665616246498) 20%,
          rgba(0, 0, 0, 0) 100%
        );

        &::-webkit-scrollbar {
          width: 0px;
        }

        .carousel-image {
          width: 100%;
          height: 100%;
        }
      }

      .profile-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: #f9f9f9;
        border-bottom: 1px solid #ecebeb;

        .profile-logo {
          width: 80px;
          height: 80px;
          overflow: hidden;
          border-radius: 8px;
          margin-top: -50px;
          margin-bottom: 15px;
        }

        .profile-match {
          background-color: #ed6125;
          color: #fff;
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 25px;
          padding: 1px 6px;
          font-size: 10px;
          font-weight: 600;
        }

        .profile-info {
          h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 8px;
            color: #434040;
            letter-spacing: 0.2px;
          }
          h3 {
            text-align: center;
            font-size: 14px;
            font-weight: 300;
            color: #434040;
            letter-spacing: 0.2px;
            margin-bottom: 8px;
          }
        }

        .profile-stats {
          display: flex;
          justify-content: center;

          span {
            margin: 0 10px;
            font-weight: 600;
            font-size: 14;
          }

          .posts-count {
            color: #ed6125;
          }

          .followers-count {
            color: #434040;
          }
        }

        .profile-tabs {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 15px;
          margin-bottom: 10px;

          .tab-item {
            margin: 0 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .tab-icon {
              width: 22px;
              height: 22px;
              margin-bottom: 5px;
            }
            .tab-label {
              font-size: 10px;
              font-weight: 600;
            }
          }
        }
      }

      .profile-body {
        width: 100%;
        .body-labels {
          width: 100%;
          display: flex;
          flex-direction: row;
          padding-top: 15px;
          overflow-x: auto;
          overflow-y: hidden;

          &::-webkit-scrollbar {
            width: 0px;
          }

          .tab-label {
            border-radius: 25px;

            color: #434040;
            cursor: pointer;
            font-weight: 600;
            font-size: 12px;
            padding: 0 8px;
            margin-right: 10px;
            white-space: nowrap;

            &:first-child {
              margin-left: 10px;
            }

            &.active {
              background-color: #ed6125;
              color: #fff;
            }
          }
        }

        .body-tab {
          padding: 0 15px;

          .body-item {
            padding: 10px 0;
          }

          .item-title {
            .item-title-icon {
              width: 60px;
              height: 60px;
              margin-right: 10;

              img {
                object-fit: contain;
              }
            }

            p {
              font-size: 18px;
              font-weight: 600;
              color: #434040;
              margin-bottom: 10px;

              &.title-small {
                font-size: 12px;
              }
            }
          }

          .item-content {
            color: #434040;
          }

          .item-tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-self: flex-start;

            .tag-content {
              background-color: #ecebeb;
              border-radius: 4px;
              padding: 2px 6px;
              margin-right: 6px;
              margin-bottom: 6px;
              font-size: 14px;
              font-weight: 300;
            }
          }

          .item-quick-links {
            padding: 0 10px;
            .quick-link-content {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 10px;

              .quick-link-icon {
                font-size: 12px;
                color: #ed6125;
              }
              .quick-link-title {
                margin-bottom: 0;
                margin-left: 3px;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
          .item-general-links {
            padding-bottom: 15px;
            .general-link-content {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              .general-link-icon {
                width: 22px;
                height: 22px;

                img {
                  object-fit: contain;
                }
              }

              .general-link-title {
                margin-top: 5px;
                margin-bottom: 0;
                margin-left: 3px;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }

          .item-video {
            padding-top: 15px;
            .video-title {
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            }
          }

          .item-file {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            border: 1px solid #ecebeb;
            border-radius: 8px;
            padding: 15px;
            .file-icon {
              font-size: 22px;
              color: #ed6125;
            }

            .file-title {
              margin-left: 10px;
              font-size: 14px;
              font-weight: 600;
            }
          }

          .item-albums {
            width: 100%;

            .album-photos {
              overflow-x: auto;
              overflow-y: hidden;
              display: flex;
              flex-direction: row;
              margin: 15px 0;
              width: auto;

              &::-webkit-scrollbar {
                width: 0px;
              }
              .photo-content {
                border-radius: 4px;
                margin-right: 10px;
                overflow: hidden;
                width: 120px;
                height: 80px;
                flex: none;
              }
            }
            .album-title {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
