import NetworkService from '@/services/NetworkService';

export default class MessagesService {
    constructor() {
        this.network = new NetworkService()
    }

    getChats() {
        return this.network.get(this.network.endpoints.Chats.list)
    }

    getChatsObj() {
        return this.network.get(this.network.endpoints.Chats.obj)
    }

    getChatMessages(studentId) {
        const url = `${this.network.endpoints.Chats.list}/${studentId}/messages`
        return this.network.get(url)
    }

    sendMessage(params) {
        const url = `${this.network.endpoints.Chats.messages}`
        return this.network.post(url, params)
    }

    readMessages(studentId) {
        const url = `${this.network.endpoints.Chats.list}/${studentId}/read`
        return this.network.put(url, {})
    }
}
