import { MESSAGE_STATUS } from "@/utils/Constants";

function SYNC_CHATS(state) {
  state.arrChats = Object.values(state.chats);
}
function SET_CHATS(state, data) {
  state.chats = { ...data };
}

function ADD_CHAT(state, data) {
  const { studentId, chat } = data;

  if (state.chats[studentId]) {
    state.chats[studentId].createdAt = Date.now();
    return;
  }

  state.chats[studentId] = chat;
}

function ADD_CHAT_MESSAGES(state, data) {
  const { studentId, messages } = data;

  if (!state.chats[studentId]) {
    state.chats[studentId] = {
      studentId: studentId,
    };
  }

  state.chats[studentId].messages = messages;
}

function ADD_CHAT_ID(state, data) {
  const { studentId, _id } = data;

  if (!state.chats[studentId]) {
    state.chats[studentId] = {
      studentId: studentId,
    };
  }

  state.chats[studentId]._id = _id;
}

function ADD_CHAT_INFO(state, data) {
  const { studentId, chatInfo } = data;

  if (!state.chats[studentId]) {
    state.chats[studentId] = {
      studentId: studentId,
    };
  }

  state.chats[studentId].chatInfo = chatInfo;
}

function ADD_CHAT_MESSAGE(state, data) {
  let { studentId, message, status, localId } = data;
  message = {
    ...message,
    status,
    localId,
  };
  if (state.chats[studentId]) {
    state.chats[studentId].lastMessage = [message];

    if (!state.chats[studentId].messages) {
      state.chats[studentId].messages = [];
    }

    state.chats[studentId].messages.unshift(message);
  }
}

function SET_MESSAGE_AS_VIEWED(state, data) {
  const { studentId } = data;

  if (!state.chats[studentId]) {
    return;
  }

  if (
    !state.chats[studentId].messages ||
    !Array.isArray(state.chats[studentId].messages)
  ) {
    return;
  }

  state.chats[studentId].messages = state.chats[studentId].messages.map((m) => {
    if (m.status !== MESSAGE_STATUS.VIEWED) {
      m.viewedAt = Date.now();
      m.status = MESSAGE_STATUS.VIEWED;
    }

    return m;
  });

  SYNC_CHATS(state);
}

function UPDATE_CHAT_MESSAGE(state, payload) {
  const { message, studentId } = payload;

  if (!state.chats[studentId]) {
    return;
  }

  if (!state.chats[studentId].messages) {
    return;
  }

  const index = state.chats[studentId].messages.findIndex(
    (m) => m.localId == message.localId
  );

  if (index > -1) {
    state.chats[studentId].messages[index] = message;

    delete state.chats[studentId].messages[index].localId;
  }
}

export {
  SYNC_CHATS,
  SET_CHATS,
  ADD_CHAT,
  ADD_CHAT_MESSAGES,
  ADD_CHAT_ID,
  ADD_CHAT_INFO,
  ADD_CHAT_MESSAGE,
  SET_MESSAGE_AS_VIEWED,
  UPDATE_CHAT_MESSAGE,
};
