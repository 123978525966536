import { NOVVA_APP_SOCKET_EVENT } from "@/utils/Constants";
import { mapGetters, mapActions } from "vuex";

import messages from "@/mixins/messages";

export default {
  mixins: [messages],
  computed: {
    ...mapGetters(["SOCKET_USER", "TOKEN"]),
    ...mapGetters("chat", ["CHATS"]),
    currentUser() {
      return this.SOCKET_USER;
    },
  },
  watch: {
    TOKEN: {
      handler() {
        if (this.$socket.disconnected) {
          this.connect();
        }
      },
      immediate: true,
    },
  },
  sockets: {
    [NOVVA_APP_SOCKET_EVENT.chatMessage]: async function ({ user, data }) {
      if (!["Messages", "Chat"].includes(this.$route.name)) {
        //EXIBE TOAST SEMPRE QUE ESTIVER FORA DA LISTAGEM DE CONVERSAS E DENTRO DA CONVERSA
        this.$eventBus.$emit("showToast", {
          title: user.name,
          msg: data.message,
          timeout: 4000,
        });
      }

      this.addChat({
        studentId: user._id,
        chat: {
          lastMessage: data,
          student: user,
          created: Date.now(),
        },
      });

      this.addMessage({
        studentId: user._id,
        message: { ...data, user },
      });
    },

    [NOVVA_APP_SOCKET_EVENT.viewedMessage]: function ({ user }) {
      this.setMessageAsViewed(user._id);
    },
    [NOVVA_APP_SOCKET_EVENT.checkOnline]: function ({ online }) {
      this.setChatOnline(online);
    },
    ["connect_error"]: function (e) {
      console.log(e);
      setTimeout(() => this.connect(), 1000);
    },
  },

  methods: {
    ...mapActions("chat", [
      "addChat",
      "setMessageAsViewed",
      "addMessage",
      "setChatOnline",
    ]),
    connect() {
      if (this.$socket.connected) {
        this.$socket.disconnect();
      }

      let token = this.TOKEN;
      if (!token) {
        token = localStorage.getItem("token");
      }

      if (token) {
        console.log(token);

        this.$socket.auth = {
          token,
          chatUser: this.currentUser,
        };

        this.$socket.connect();
      }
    },
  },
  beforeMount() {
    // if (token) {
    //   console.log(token);
    //   this.connect(token);
    // }
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", () => {});
  },
};
