<template>
  <div class="position-relative">
    <div
      class="overflow-hidden rounded-circle d-flex justify-content-center align-items-center"
      :style="`width: ${width}; height: ${height}`"
    >
      <img
        v-if="src && !showPlaceholder"
        :src="src"
        alt=""
        @error="imgError"
        class="w-100 h-100"
        style="object-fit: cover"
      />
      <div v-if="!src || showPlaceholder">
        <slot name="placeholder"> </slot>
      </div>
    </div>

    <template v-if="online">
      <div class="online-indicator"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PrivateField",
  props: {
    src: String,
    width: {
      type: String,
      default: "32px",
    },
    height: {
      type: String,
      default: "32px",
    },
    online: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPlaceholder: false,
    };
  },
  methods: {
    imgError() {
      this.showPlaceholder = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}

.online-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1acf00;
}
</style>
