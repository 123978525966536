<template>
  <div>
    <div v-if="!show">
      <label class="form-label mb-1">{{ label }}</label>
      <div class="input-group input-group-merge mb-3">
        <div
          class="
            input-group-text
            border-right-0
            border-top-right-radius-0
            border-bottom-right-radius-0
            border-0
          "
          id="inputGroup"
        >
          <span class="fas fa-lock"></span>
        </div>
        <input
          disabled
          class="
            form-control
            border-top-left-radius-0 border-bottom-left-radius-0 border-left-0
            border-0
          "
          type="text"
          value="To see content, you need to accept"
          aria-label="Input group appended"
          aria-describedby="inputGroup"
        />
      </div>
    </div>
    <ReadOnlyInput v-if="show" :label="label" :value="value" />
  </div>
</template>

<script>
import ReadOnlyInput from "./ReadOnlyInput.vue";
export default {
  name: "PrivateField",
  props: {
    label: String,
    show: Boolean,
    value: String,
  },
  components: { ReadOnlyInput },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}
label {
  display: inline-block;
  color: #ed6125;
  font-weight: bold;
}
* {
  color: #4e4b4b;
}
</style>
