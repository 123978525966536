import { USER_TYPE, NOVVA_APP_SOCKET_EVENT } from "@/utils/Constants";
import messages from "@/mixins/messages";
import { mapGetters, mapActions } from "vuex";
import Textarea from "./Textarea";
import { MESSAGE_STATUS } from "@/utils/Constants";

export default {
  name: "Chat",
  components: {
    Textarea,
  },
  mixins: [messages],
  data() {
    return {
      maxTextLength: 400,
      textMessage: "",
      item: {
        image: "https://i.pravatar.cc/150",
      },
      userStudentType: USER_TYPE.STUDENT,
      userInstitutionType: USER_TYPE.INSTITUTION,
      messageStatus: MESSAGE_STATUS,
      messages: [],
      isTabVisible: true,
      typing: {},
    };
  },
  computed: {
    ...mapGetters("chat", ["GET_CHAT_MESSAGES", "GET_CHAT_INFO"]),
    userSession() {
      return this.$store.state.userSession;
    },
    chatInfo() {
      const getChatInfo = this.GET_CHAT_INFO;

      return getChatInfo(this.studentId);
    },
    items() {
      const getChatMessages = this.GET_CHAT_MESSAGES;

      return getChatMessages(this.studentId);
    },
  },
  watch: {
    isTabVisible(val) {
      if (val) {
        this.viewed();
      }
    },
    textMessage(value) {
      if (value) {
        this.$socket.emit(NOVVA_APP_SOCKET_EVENT.typing, {
          room: this.visitorRoom,
        });
      } else {
        this.$socket.emit(NOVVA_APP_SOCKET_EVENT.stopTyping, {
          room: this.visitorRoom,
        });
      }
    },
    studentId(val, old) {
      this.$socket.emit(NOVVA_APP_SOCKET_EVENT.stopTyping, {
        room: `PRIVATE:STUDENT:${old}`,
      });

      sessionStorage.setItem(
        `${this.currentUser.slug}/${old}/message`,
        this.textMessage
      );

      const message = sessionStorage.getItem(
        `${this.currentUser.slug}/${val}/message`
      );
      this.textMessage = message ? message : "";

      this.viewed();
    },
    messages() {
      if (this.messages.length) {
        const text = this.messages.shift();
        this.dispatchMessage(text);
      }
    },
  },
  sockets: {
    [NOVVA_APP_SOCKET_EVENT.typing]: function ({ user }) {
      if (!this.typing[user._id]) {
        this.$set(this.typing, user._id, []);
      }

      const index = this.typing[user._id].indexOf(user);

      if (index < 0) {
        this.typing[user._id].push(user);
      }
    },

    [NOVVA_APP_SOCKET_EVENT.stopTyping]: function ({ user }) {
      if (!this.typing[user._id]) {
        this.$set(this.typing, user._id, []);
      }

      const index = this.typing[user._id].indexOf(user);
      if (index >= 0) {
        this.typing[user._id].splice(index, 1);
      }
    },

    [NOVVA_APP_SOCKET_EVENT.chatMessage]: async function ({ user }) {
      if (this.isTabVisible) {
        this.viewed();
      }
    },

    [NOVVA_APP_SOCKET_EVENT.leave]: function ({ user }) {
      if (!this.typing[this.studentId]) {
        this.$set(this.typing, this.studentId, []);
      }

      const index = this.typing[this.studentId].indexOf(user.name);
      if (index >= 0) {
        this.typing[this.studentId].splice(index, 1);
      }
    },
  },
  methods: {
    ...mapActions("chat", ["getChat", "sendViewed"]),
    detectTabChanging() {
      this.isTabVisible = document.visibilityState === "visible";
    },
    dispatchMessage(text) {
      this.send({ text });

      this.textMessage = "";
      this.$refs.message.$el.focus();
    },
    sendMessage() {
      this.messages.push(this.textMessage);
      this.textMessage = "";
    },
    viewed() {
      this.sendViewed(this.studentId);

      // const timestamp = this.$moment()
      //   .utc()
      //   .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

      // this.$socket.emit(NOVVA_APP_SOCKET_EVENT.viewedMessage, {
      //   room: this.visitorRoom,
      //   data: {
      //     timestamp,
      //     userId: this.currentUser._id,
      //   },
      // });
    },
    async getItem() {
      try {
        await this.getChat(this.studentId);
      } catch (e) {
        console.warn({ e });
      }
    },
  },
  created() {
    document.addEventListener("visibilitychange", this.detectTabChanging);

    this.getItem();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.message.$el.focus();
      this.viewed();
    });
  },
  beforeDestroy() {
    sessionStorage.setItem(
      `${this.currentUser.slug}/${this.studentId}/message`,
      this.textMessage
    );

    document.removeEventListener("visibilitychange", this.detectTabChanging);
  },
};
