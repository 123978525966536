const paths = {
    home: '/home',
    signin: '/signin',
    social: '/community',
    audience: '/audience',
    forgotPassword: '/forgot-password',
    passwordRecovery: '/recovery-password',
    onboarding: '/onboarding',
    notifications: '/notifications',
    profile: '/profile',
    meeting: '/meeting',
    account: '/account',
    signup: {
        info: '/signup-info',
        account: '/signup-account',
        confirmation: '/signup-confirmation',
        accountActivation: '/account-activation',
    },
    students: {
        detail: '/student-detail',
    },
    university: {
        list: '/resources',
        scholarship: {
            add: '/resources/scholarship/add',
            detail: '/resources/scholarship/detail'
        },
        brochure: {
            add: '/resources/brochure/add',
            detail: '/resources/brochure/detail'
        },
        addPhoto: '/resources/add-photo',
        addVideo: '/resources/add-video',
    },
    wallet: {
        list: '/wallet',
        add_credits: '/wallet/add-credits',
    },
    team: {
        list: '/team',
        detail: '/team/member-detail',
        add: '/team/add-member',
        edit: '/team/edit-member'
    },
    agenda: {
        list: '/diary',
        appointment_detail: '/diary/appointment-detail',
        edit: '/diary/edit'
    },
    messages: {
        list: '/messages',
        chat: '/messages/chat'
    },
    legal: '/legal'

}

export default paths