<template>
  <div>
    <Sidebar />
    <NavBar current="Profile" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-8" v-else>
      <div class="header header-tabs">
        <div
          id="carouselExampleControls"
          class="carousel slide mt-5"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner rounded-3">
            <div
              class="carousel-item"
              v-for="(pic, index) in item.bgImages"
              :key="index"
              :class="{ active: index == 0 }"
            >
              <img
                :src="pic"
                class="d-block w-100 rounded-3 carousel-image"
                alt="..."
              />
            </div>
            <router-link
              tag="div"
              class="carousel-item rounded-3 cursor-pointer"
              :class="{ active: !item.bgImages || item.bgImages.length == 0 }"
              :to="$paths.university.addPhoto"
            >
              <img
                src="@/assets/img/add_background_image.png"
                style="object-fit: cover"
                class="d-block w-100 rounded-3 carousel-image"
                alt="..."
              />
            </router-link>
          </div>

          <button
            class="carousel-control-prev control-btn"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="fas fa-angle-left fa-4x control-arrow"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next control-btn"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="fas fa-angle-right fa-4x control-arrow"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <!-- <img
          src="https://picsum.photos/500/250"
          class="header-img-top mt-5 rounded-3"
          alt="..."
        /> -->
        <div class="header-body mt-n5 mt-md-n7">
          <div class="row align-items-end mb-4">
            <div class="col-auto ms-4">
              <div class="avatar avatar-xxl header-avatar-top">
                <img
                  :src="item.institution.image"
                  alt="..."
                  id="profile-img"
                  class="avatar-img rounded-3 border border-4 border-white"
                />
                <span
                  v-if="loadingProfileImg"
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                  style="
                    bottom: -4px;
                    right: -5px;
                    position: absolute;
                    z-index: 20;
                    width: 30px;
                    height: 30px;
                  "
                  aria-hidden="true"
                ></span>
                <span
                  v-else
                  class="fas fa-pen text-primary"
                  style="
                    bottom: -4px;
                    right: -5px;
                    position: absolute;
                    font-size: 1.3rem;
                    z-index: 20;
                  "
                ></span>
                <input
                  ref="input"
                  type="file"
                  name="image"
                  accept="image/*"
                  style="left: 0; z-index: 21"
                  @change="setProfileImage"
                />
              </div>
            </div>
            <div class="col-auto d-flex align-items-end justify-content-end ms-auto">
              <ButtonEditProfile />
              <button
                :disabled="loadingPreview"
                class="btn btn-xs btn-primary me-4"
                @click="fetchProfileClick()"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <i v-if="!loadingPreview" class="fas fa-mobile-alt me-2"></i>
                  <span
                    v-else
                    class="spinner-border spinner-border-sm text-grey me-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Preview your profile
                </div>
              </button>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col">
              <h1 class="header-title">{{ item.name }}</h1>
              <p class="text-muted">{{ item.location }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <input
                id="name-input"
                v-if="focusInputs.name"
                v-model.trim="$v.form.name.$model"
                :class="{ 'is-invalid': $v.form.name.$error }"
                placeholder="University name"
                class="form-control"
                v-on:blur="handleBlur('name')"
              />
              <h2 v-else class="h2 mb-0">
                {{ item.institution.name }}
                <button @click="editClicked('name')" class="btn btn-link ps-1">
                  <span class="fas fa-pen"></span>
                </button>
              </h2>
              <div v-if="focusInputs.location">
                <BaseInputLocation
                  id="map"
                  type="location"
                  placeholder="Type your city and wait for the options"
                  :class="{ 'is-invalid': $v.form.location.$error }"
                  @input="addressChanged"
                />
                <span class="text-danger">{{ locationErrorMsg }}</span>
              </div>
              <div v-else>
                <span v-if="item.institution && item.institution.location">
                  {{ item.institution.location.city }},
                  {{ item.institution.location.region }} -
                  {{ item.institution.location.country }}
                  <button
                    @click="
                      focusInputs.location
                        ? handleBlur('location')
                        : editClicked('location')
                    "
                    class="btn btn-link ps-1"
                  >
                    <span
                      v-if="!focusInputs.location"
                      class="fas fa-pen"
                    ></span>
                    <small v-else class="text-primary">Cancel</small>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <hr class="mb-5 mt-4" />
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ path: $paths.audience, query: { tab: 1 } }"
                    tag="a"
                    class="nav-link text-dark"
                    type="button"
                    role="tab"
                  >
                    <strong class="me-2">{{ item.followersCount }}</strong>
                    <span class="text-muted">Followers</span>
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="$paths.audience"
                    tag="a"
                    class="nav-link text-dark"
                    type="button"
                    role="tab"
                  >
                    <strong class="me-2">{{
                      item.requestsToFollowCount
                    }}</strong>
                    <span class="text-muted">Follow requests</span>
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ path: $paths.social, query: { tab: 1 } }"
                    tag="a"
                    class="nav-link text-dark"
                    type="button"
                  >
                    <strong class="me-2">{{ item.postsCount }}</strong>
                    <span class="text-muted">Posts</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="mb-5 mt-4" />
      <div class="row">
        <div class="col-12">
          <h2 class="h2 mb-0">
            About
            <button @click="editClicked('about')" class="btn btn-link ps-1">
              <span class="fas fa-pen"></span>
            </button>
          </h2>

          <quill-editor
            id="about-text-area"
            v-if="focusInputs.about"
            v-model.trim="$v.form.about.$model"
            ref="refQuillEditor"
            :options="quillOptions"
            :class="{ 'is-invalid': $v.form.about.$error }"
            rows="5"
            placeholder="Tell us about your university"
            class="form-control"
            v-on:blur="handleBlur('about')"
          ></quill-editor>
          <span v-else v-html="item.institution.description"></span>
        </div>
      </div>
      <hr class="my-5" />
      <div class="row">
        <div class="col-12" ref="columnWidth">
          <h2 class="h2">Videos</h2>
          <!-- <div v-if="item.videos.length > 0"> -->
          <Slider
            :length="item && item.videos && item.videos.length"
            :width="columnWidth"
            :gap="1"
            :itemWidth="200"
            :height="112.5"
            :extraItems="1"
          >
            <router-link
              :to="$paths.university.addVideo"
              class="video-card text-muted d-flex justify-content-center align-items-center rounded-3 bg-light me-3"
            >
              <i class="fas fa-plus-circle me-1 display-6"></i>
              <strong> Add Video </strong>
            </router-link>
            <iframe
              v-for="(video, index) in item.videos"
              :key="index"
              width="200"
              height="112.5"
              :src="video.embededUrl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="rounded-3 me-3"
            ></iframe>
          </Slider>
          <!-- </div> -->
          <!-- <router-link
            v-else
            :to="$paths.university.addVideo"
            class="video-card text-muted d-flex justify-content-center align-items-center rounded-3 bg-light"
          >
            <i class="fas fa-plus-circle me-1 display-6"></i>
            <strong> Add Video </strong>
          </router-link> -->
        </div>
      </div>
      <hr class="my-5" />
      <div class="row">
        <div class="col-12">
          <h2 class="h2">Scholarships</h2>
          <!-- <div v-if="item.scholarships.length > 0"> -->
          <Slider
            :length="item && item.scholarships && item.scholarships.length"
            :width="columnWidth"
            :gap="1"
            :itemWidth="250"
            :height="125"
            :extraItems="1"
          >
            <router-link
              :to="$paths.university.scholarship.add"
              class="scholarships-card text-muted d-flex justify-content-center align-items-center rounded-3 border me-3"
            >
              <i class="fas fa-plus-circle me-1 display-6"></i>
              <strong> Add Scholarships </strong>
            </router-link>
            <div
              class="scholarships-card rounded-3 border p-4 me-3 text-dark"
              v-for="(scholarship, index) in item.scholarships"
              :key="index"
            >
              <strong class="mb-0">{{ scholarship.name }}</strong>
              <p></p>
              <router-link
                :to="`${$paths.university.scholarship.detail}/${scholarship._id}`"
                tag="a"
                class="mt-auto"
                >See more</router-link
              >
            </div>
          </Slider>
          <!-- </div> -->
        </div>
      </div>
      <hr class="my-5" />
      <div class="row">
        <div class="col-12">
          <h2 class="h2">Brochures</h2>
          <!-- <div v-if="item.brochures.length > 0"> -->
          <Slider
            :length="item.brochures.length"
            :width="columnWidth"
            :gap="1"
            :itemWidth="250"
            :height="112.5"
            :extraItems="1"
          >
            <router-link
              :to="$paths.university.brochure.add"
              class="brochures-card text-muted d-flex me-3 justify-content-center align-items-center rounded-3 bg-light"
            >
              <i class="fas fa-plus-circle me-1 display-6"></i>
              <strong>Add Brochures</strong>
            </router-link>

            <router-link
              tag="div"
              class="brochures-card rounded-3 border p-4 me-3 text-dark cursor-pointer"
              v-for="(brochure, index) in item.brochures"
              :to="{
                path: `${$paths.university.brochure.detail}/${brochure._id}`,
              }"
              :key="index"
            >
              <p><span class="fas fa-file-pdf fa-2x"></span></p>
              <strong class="mb-0">{{ brochure.title }}</strong>
            </router-link>
          </Slider>
          <!-- </div> -->
        </div>
      </div>
      <hr class="my-5" />
      <div class="row">
        <div class="col-12">
          <h2 class="h2">Photos</h2>
          <!-- <div v-if="item.brochures.length > 0"> -->
          <Slider
            :length="item.photos ? item.photos.length : 0"
            :width="columnWidth"
            :gap="1"
            :itemWidth="300"
            :height="150"
            :extraItems="1"
          >
            <router-link
              :to="$paths.university.addPhoto"
              class="photo-card text-muted d-flex me-3 ms-1 justify-content-center align-items-center rounded-3 bg-light"
            >
              <i class="fas fa-plus-circle me-1 display-6"></i>
              <strong>Add Photo</strong>
            </router-link>

            <div
              class="photo-card rounded-3 border me-3 text-dark cursor-pointer"
              v-for="(photo, index) in item.photos"
              :key="index"
            >
              <img :src="photo" class="rounded-3 w-100 h-100" />
              <!-- <p><span class="fas fa-file-pdf fa-2x"></span></p>
              <strong class="mb-0">{{ brochure.title }}</strong> -->
            </div>
          </Slider>
          <!-- </div> -->
        </div>
      </div>
      <MobileProfilePreview />
    </Container>

    <ModalComponent
      title="Crop Image"
      description="To ensure the most professional look, please try and use a logo with 1:1 (square) dimensions"
      id="profileImageModal"
    >
      <template v-slot:body>
        <vue-cropper
          :modal="false"
          :movable="false"
          :zoomable="false"
          :original="true"
          :background="true"
          ref="cropperProfile"
          :src="imgSrc"
          alt="Source Image"
          :viewMode="2"
          :aspect-ratio="1"
          :minContainerWidth="550"
          :minContainerHeight="$helper.cropMinHeight"
          :aspectRatio="1 / 1"
          :initialAspectRatio="1 / 1"
        >
        </vue-cropper>
      </template>
      <template v-slot:footer>
        <button type="button" @click="cropProfileImage" class="btn btn-primary">
          Crop & Upload
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ProfileController from "./ProfileController";
export default ProfileController;
</script>

<style scoped lang="scss">
input[type="file"] {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.video-card {
  width: 200px;
  height: calc((200px / 16) * 9);
}
.brochures-card {
  width: 250px;
  height: 112.5px;
}
.photo-card {
  width: 300px;
  height: 150px;
}
.scholarships-card {
  width: 250px;
  height: 125px;
}
.carousel-image {
  height: 415px;
}
#profile-img {
  -webkit-box-shadow: 5px 5px 15px -6px #000000;
  box-shadow: 5px 5px 15px -6px #000000;
}
.avatar-img {
  /* width: 200px; */
}

.header-avatar-top {
  width: 100%;
}
.control-arrow {
  text-shadow: #000000bf 4px 1px 15px;
}
.control-btn {
  opacity: 1 !important;
  /* background: #0000000d; */
}
</style>
