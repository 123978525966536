<template>
  <div class="signin">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 px-lg-6 mb-5 mt-7">
          <img src="@/assets/img/logo.jpg" alt="" class="small-logo mb-6" />
          <div class="my-5">
            <h1 class="header-title mb-4">Sign up</h1>
            <h4 class="text-primary">2. Account information</h4>
          </div>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label class="form-label"> Email Address </label>
              <input
                class="form-control"
                v-model.trim="$v.form.email.$model"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="email"
                placeholder="name@address.com"
              />
              <div class="invalid-feedback">
                <small>Please fill in this field correctly</small>
              </div>
            </div>
            <div class="form-group mb-6">
              <div class="row">
                <div class="col">
                  <label class="form-label"> Password </label>
                </div>
              </div>

              <div class="input-group input-group-merge">
                <input
                  v-model.trim="$v.form.password.$model"
                  :class="{ 'is-invalid': $v.form.password.$error }"
                  class="form-control border-right-0"
                  :type="passwordFieldType"
                  placeholder="Enter your password"
                />
                <span class="input-group-text cursor-pointer fix-input-group-border">
                  <i
                    @click="togglePassword()"
                    class="fas"
                    :class="{
                      'fa-eye': passwordFieldType == 'text',
                      'fa-eye-slash': passwordFieldType == 'password',
                    }"
                  ></i>
                </span>
                <div class="invalid-feedback">
                  <small>Please, password must have least 8 characters</small>
                </div>
              </div>
            </div>

            <p class="">
              <small class="text-dark">
                By clicking continue, you agree with our
                <router-link :to="`${$paths.signup.legal}/terms`" tag="a">
                  Terms of use
                </router-link>
                and
                <router-link :to="`${$paths.signup.legal}/policy`" tag="a">
                  Privacy policy
                </router-link>
              </small>
            </p>

            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>

            <button
              :disabled="$v.form.$invalid || loading"
              class="btn w-100 btn-primary my-3"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Save and continue
            </button>
          </form>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-7 d-none d-lg-block">
          <div
            id="bgimg"
            class="bg-cover h-100 min-vh-100 mt-n1 me-n3"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SignupAccountController from "./SignupAccountController";
export default SignupAccountController;
</script>

<style scoped>
#bgimg {
  background-image: url("../../../assets/img/register_bg.png");
}
</style>