import EmptyState from "@/components/EmptyState.vue";
import MonthSelector from "@/components/MonthSelector.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { email, required } from "vuelidate/lib/validators";
import moment from 'moment'
import LoadingContainer from "@/components/LoadingContainer.vue";
import { Modal } from "bootstrap";
import { USER_TYPE } from '@/utils/Constants'
import BaseSelectTags from '@/components/BaseSelectTags.vue'

export default {
  name: "Wallet",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 0)
    },
    
  },
  mounted() {
    this.loadingAll = true
    this.getData()
    this.selectedTab = this.$route.query.tab || 0
    this.getInstitutionRepresentatives()
    this.getUserSubscription()
  },
  data() {
    return {
      loadingAll: false,
      currentDate: this.$moment().format('YYYY/MM'),
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      columns: {
        deposits: [
          'User Name',
          'Date',
          'Credits',
          'Value',
          'Status',
        ],
        purchases: [
          'Used By',
          'Used with',
          'Date',
          'Credits',
        ],
        representatives: [
          '',
          ''
        ]
      },
      deposits: [],
      allPurchases: [],
      purchases: [],
      searchDeposits: '',
      searchPurchases: '',
      representativesSelected: [],
      representatives: [],
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },
      },
      availableTo: [],
      userSubscription: []
    };
  },
  methods: {
    async getUserSubscription() {
      this.userSubscription = (await this.$userService.getUserSubscription()).data.data
    },
    findPurchases() {
      return this.purchases.filter(purchase => {
        var search = true
        var representatives = true

        if (this.searchPurchases) {
          let regex = new RegExp(`${this.searchPurchases.toLowerCase()}.*`)
          let name = this.isRepresentative(purchase.boughtBy) ? purchase.boughtBy.name : purchase.boughtBy.profile.institution.contact.name
          search = name.toLowerCase().match(regex)
        }

        if (this.representativesSelected.length) {
          representatives = this.representativesSelected.includes(purchase.boughtBy._id)
        }
        
        return representatives && search
      })

    },
    async getInstitutionRepresentatives() {
      const res = await this.$userService.getInstitutionRepresentatives(true)
      const representatives = res.data.data

      let isInstitution = this.$store.state.userSession.type == 'INSTITUTION' 

      let mainAccount = isInstitution 
        ? this.$store.state.userSession 
        : this.$store.state.userSession.account

      let name = mainAccount.profile.institution.contact.name

      let institutionData = {
        name: name + ' (Main account)',
        value: mainAccount._id.toString()
      }

      let formatedRepresentatives = representatives.map(representative => {
        return {
          name: representative.name,
          value: representative._id.toString()
        }
      })

      formatedRepresentatives.unshift(institutionData)

      this.representatives = formatedRepresentatives
    },
    
    findDeposits() {
      if (this.searchDeposits) {
        return this.deposits.filter(deposit => {
          let regex = new RegExp(`${this.searchDeposits.toLowerCase()}.*`)
          let name = this.isRepresentative(deposit.boughtBy) ? deposit.boughtBy.name : deposit.boughtBy.profile.institution.contact.name

          return name.toLowerCase().match(regex)
        })
      }

      return this.deposits
    },
    async getData() {
      try {
        const r1 = await this.$financialService.getDeposits(this.currentDate)
        const r2 = await this.$financialService.getPurchases(this.currentDate)
        this.$set(this, 'deposits', r1.data.data.filter( d => d.invoice !== null ))
        this.$set(this, 'purchases', r2.data.data)
      } catch (e) {

      } finally {
        this.loadingAll = false
      }
    },
    dateChanged(date) {
      this.currentDate = date.date.replace('-', '/')
      this.getData()

    },
    handleSelectTab(index) {
      this.$router.push({ path: `${this.$paths.wallet.list}`, query: { tab: index } }).catch(e => { })
    },
    openModalRepresentatives(availableTo) {
      if (!availableTo) return;
      this.availableTo = availableTo;

      const el = document.getElementById('availableTo_modal');
      this.newPostModal = new Modal(el, {
        backdrop: 'static',
        keyboard: false
      });

      this.newPostModal.toggle();
    },
    openStudent(item) {
      const newRoute = this.$router.resolve({ path: `${this.$paths.students.detail}/${item.student._id}` })
      window.open(newRoute.href, '_blank');
    },
    isRepresentative(user) {
      return user.type === USER_TYPE.INSTITUTION_REPRESENTATIVE
    },
    hasSubscription() {
      return this.userSubscription && this.userSubscription._id ? true : false
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    MonthSelector,
    BaseSelectTags
  },
};