var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-vertical fixed-start navbar-expand-md navbar-light border-right",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"sidebarCollapse"}},[_vm._m(2),_c('ul',{staticClass:"navbar-nav px-3"},[_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.home,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-1"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.dashboardActive : _vm.icons.dashboard}}),_vm._v(" Dashboard ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.agenda.list,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-2"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.agendaActive : _vm.icons.agenda}}),_vm._v(" Agenda ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.social,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-3"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.communityActive : _vm.icons.community}}),_vm._v(" Community ")])]}}])})],1),_c('hr',{staticClass:"navbar-divider my-3"}),_c('h6',{staticClass:"navbar-heading px-3"},[_vm._v("University")]),_c('ul',{staticClass:"navbar-nav px-3"},[_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.profile,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-4"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.profileActive : _vm.icons.profile}}),_vm._v(" Profile ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.messages.list,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-5"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.messagesActive : _vm.icons.messages}}),_vm._v(" Messages ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.audience,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-6"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.audienceActive : _vm.icons.audience}}),_vm._v(" Audience ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.university.list,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-7"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.resourcesActive : _vm.icons.resources}}),_vm._v(" Resources ")])]}}])})],1),_c('hr',{staticClass:"navbar-divider my-3"}),_c('h6',{staticClass:"navbar-heading px-3"},[_vm._v("Other")]),_c('ul',{staticClass:"navbar-nav px-3"},[_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.team.list,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-8"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.teamActive : _vm.icons.team}}),_vm._v(" Team ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.account,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-9"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.accountActive : _vm.icons.account}}),_vm._v(" Account ")])]}}])}),_c('router-link',{staticClass:"nav-item",attrs:{"active-class":"router-link-active","to":_vm.$paths.wallet.list,"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"nav-link cursor-pointer",class:{ 'text-black': isActive },attrs:{"id":"step-tour-10"},on:{"click":navigate}},[_c('img',{staticClass:"sidebar-icon",attrs:{"src":isActive ? _vm.icons.walletActive : _vm.icons.wallet}}),_vm._v(" Wallet ")])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#sidebarCollapse","aria-controls":"sidebarCollapse","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-user d-md-none"},[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","id":"sidebarIcon","role":"button","data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('div',{staticClass:"avatar avatar-sm avatar-online"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-end",attrs:{"aria-labelledby":"sidebarIcon"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"./profile-posts.html"}},[_vm._v("Profile")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"./account-general.html"}},[_vm._v("Settings")]),_c('hr',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"./sign-in.html"}},[_vm._v("Logout")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"mt-4 mb-3 d-md-none"},[_c('div',{staticClass:"input-group input-group-rounded input-group-merge input-group-reverse"},[_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Search","aria-label":"Search"}}),_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fe fe-search"})])])])}]

export { render, staticRenderFns }