<template>
  <div class="audience">
    <Sidebar />

    <NavBar current="Student Detail" :link="$paths.audience" linkName="Audience" />
    <LoadingContainer v-if="loadingAll" />
    <div v-else class="main-content">
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 col-xl-8">

            <div class="header mt-md-5">
              <div class="header-body pt-0 border-0">
                <div class="row align-items-end">
                  <div class="col">
                    <h1 class="header-title">
                      Student details
                    </h1>
                  </div>
                </div>
                <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      :class="{ active: selectedTab == 0 }"
                      id="general-info-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#general-info"
                      type="button"
                      role="tab"
                      aria-controls="general-info"
                      aria-selected="true"
                    >
                      General info
                      <span
                        class="ms-auto badge"
                        :class="{
                          'bg-primary-soft': selectedTab == 0,
                          'bg-secondary-soft': selectedTab != 0,
                        }"
                      ></span
                      >
                    </a>
                  </li>
                  <li v-if="itemsMatchedData" class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      :class="{ active: selectedTab == 1 }"
                      id="items-matched-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#items-matched"
                      type="button"
                      role="tab"
                      aria-controls="items-matched"
                      aria-selected="false"
                    >
                      Items matched
                      <span
                        class="ms-auto badge"
                        :class="{
                          'bg-primary-soft': selectedTab == 1,
                          'bg-secondary-soft': selectedTab != 1,
                        }"
                      ></span
                      >
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      :class="{ active: selectedTab == 2 }"
                      id="following-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#following"
                      type="button"
                      role="tab"
                      aria-controls="following"
                      aria-selected="false"
                    >
                      Following
                      <span
                        class="ms-auto badge"
                        :class="{
                          'bg-primary-soft': selectedTab == 2,
                          'bg-secondary-soft': selectedTab != 2,
                        }"
                      ></span
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <!-- GENERAL INFO -->
              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 0 }"
                id="general-info"
                role="tabpanel"
                aria-labelledby="general-info-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-2">
                      <div class="col-12">
                        <h3 class="text-color">An overview of {{ studentData.profile.firstname }}’s profile and academic & personal preferences.</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-6">
                        <label>School Name</label>
                        <span class="private-field">
                          {{
                            studentData.profile.highSchool
                            ? `${studentData.profile.highSchool.name || 'Not informed'}`
                            : 'Not informed'
                          }}

                        </span>
                      </div>
                      <div class="form-group col-6">
                        <label>School Type</label>
                        <span class="private-field">
                          {{
                            studentData.profile.highSchoolType
                            ? `${studentData.profile.highSchoolType}`
                            : 'Not informed'
                          }}

                        </span>
                      </div>

                      <div class="form-group col-12">
                        <PrivateExams
                          :show="true"
                          label="Exams/Tests this student has taken OR plans to take"
                          :value="studentData.profile.exams"
                        />
                      </div>
                    
                      <div class="form-group col-12">
                        <PrivateFieldMultiselect
                          :show="true"
                          label="Type of university and length of study of interest"
                          :value="studentData.profile.parser.programTypeYears"
                        />
                      </div>
                      <div class="form-group col-12">
                        <PrivateFieldOfStudyMajors
                          :show="true"
                          label="Field of study and Majors/Courses of interest"
                          :value="studentData.profile.fieldsOfStudy"
                        />
                      </div>
                      <div class="form-group col-12">
                        <PrivateFieldMultiselectLocations
                          :show="true"
                          label="University locations of interest"
                          :value="getCountriesOfInterest()"
                        />
                      </div>
                      <div class="form-group col-6">
                        <PrivateField
                          :show="true"
                          label="University size of preference"
                          :value="studentData.profile.parser.institutionSize
                            ? studentData.profile.parser.institutionSize
                            : 'Not informed'"
                        />
                      </div>
                      <div class="form-group col-6 mb-0">
                        <PrivateField
                          :show="true"
                          label="University setting preferences"
                          :value="studentData.profile.parser.environmentalSetting
                            ? studentData.profile.parser.environmentalSetting
                            : 'Not informed'"
                        />
                      </div>
                      <div class="form-group col-12">
                        <PrivateField
                          :show="true"
                          label="Housing availability preferences"
                          :value="studentData.profile.parser.accommodationType
                            ? studentData.profile.parser.accommodationType
                            : 'Not informed'"
                        />
                      </div>
                      <div 
                        v-if="studentData.profile.disabilityType && !studentData.profile.disabilityType.includes('none')"
                        class="form-group col-12"
                      >
                        <PrivateFieldMultiselect
                          :show="true"
                          label="Disability services and facilities of interest"
                          :value="studentData.profile.parser.disabilityType"
                        />
                      </div>
                      <div 
                        v-if="studentData.profile.institutionServices && !studentData.profile.institutionServices.includes('none')"
                        class="form-group col-12"
                      >
                        <PrivateFieldMultiselect
                          :show="true"
                          label="Services of interest"
                          :value="studentData.profile.parser.institutionServices"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ITEMS MATCHED -->
              <div
                v-if="itemsMatchedData"
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 1 }"
                id="items-matched"
                role="tabpanel"
                aria-labelledby="items-matched-tab"
              >
                <div class="row mb-2">
                  <div class="col-12">
                    <h3 class="text-color">Areas where {{ studentData.profile.firstname }} matches with what your institution offers.</h3>
                  </div>
                </div>
                <div class="row">
                  <div 
                    v-if="itemsMatchedData.exams"
                    class="form-group col-12"
                  >
                    <PrivateExams
                      :show="true"
                      label="Exams/Tests this student has taken OR plans to take"
                      :value="itemsMatchedData.exams"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.programTypeYears"
                    class="form-group col-12"
                  >
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Type of university and length of study of interest"
                      :value="itemsMatchedData.parser.programTypeYears"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.fieldsOfStudy"
                    class="form-group col-12"
                  >
                    <PrivateFieldOfStudyMajors
                      :show="true"
                      label="Field of study and Majors/Courses of interest"
                      :value="itemsMatchedData.fieldsOfStudy"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.locationOfInterest"
                    class="form-group col-12"
                  >
                    <PrivateFieldMultiselectLocations
                      :show="true"
                      label="University locations of interest"
                      :value="getCountriesOfInterestMatched()"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.institutionSize"
                    class="form-group col-6"
                  >
                    <PrivateField
                      :show="true"
                      label="University size of preference"
                      :value="itemsMatchedData.parser.institutionSize
                        ? itemsMatchedData.parser.institutionSize
                        : 'Not informed'"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.environmentalSetting"
                    class="form-group col-6"
                  >
                    <PrivateField
                      :show="true"
                      label="University setting preferences"
                      :value="itemsMatchedData.parser.environmentalSetting
                        ? itemsMatchedData.parser.environmentalSetting
                        : 'Not informed'"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.disabilityType && !itemsMatchedData.disabilityType.includes('none')"
                    class="form-group col-12"
                  >
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Disability services and facilities of interest"
                      :value="itemsMatchedData.parser.disabilityType"
                    />
                  </div>
                  <div 
                    v-if="itemsMatchedData.institutionServices && !itemsMatchedData.institutionServices.includes('none')"
                    class="form-group col-12"
                  >
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Services of interest"
                      :value="itemsMatchedData.parser.institutionServices"
                    />
                  </div>
                </div>
              </div>
              <!-- FOLLOWING -->
              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 2 }"
                id="following"
                role="tabpanel"
                aria-labelledby="following-tab"
              >
                <div class="row mb-2">
                  <div class="col-12">
                    <h3 class="text-color">Institutions that {{ studentData.profile.firstname }} is following. The student is in these institutions’ communities and able to connect with their representatives.</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div>
                      <EmptyState
                        title="No Universities"
                        msg="This student does not follow any universities yet "
                        v-if="!universities || !universities.length"
                      >
                        <template v-slot:icon>
                          <i class="fas fa-university fa-3x text-primary"></i>
                        </template>
                      </EmptyState>
                      <div v-else class="row">
                        <div
                          class="col-2"
                          v-for="item in student.followingUniversities"
                          v-bind:key="item.id"
                        >
                          <div class="">
                            <div class="card-body p-0 mt-3 mb-2">
                              <div class="avatar avatar-xl">
                              <img
                                :src="item.institution.image"
                                :alt="item.institution.name"
                                :title="item.institution.name"
                                class="avatar-img rounded"
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-3 me-5" style="position: fixed;right: 0;">
          <div class="header-body mt-3"></div>
            <div id="card-info" class="card mt-5">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="d-flex justify-content-center">
                    <a
                      v-if="studentData && studentData.image"
                      class="avatar avatar-xl mb-3"
                      style="margin-top: -60px;"
                    >
                      <img
                        :src="studentData.image"
                        alt="..."
                        class="avatar-img rounded-circle"
                      />
                    </a>
                  </div>
                  <div class="d-flex justify-content-center fw-bold display-5">
                    {{ accepted ? studentData.name : studentData.profile.firstname }}
                  </div>
                  <div class="d-flex justify-content-center">
                    {{
                      studentData.profile.location
                        ? `${studentData.profile.location.country}, ${studentData.profile.location.region}`
                        : 'Not informed'
                    }}
                  </div>
                  <div class="d-flex justify-content-center mt-3">
                    <button
                      :disabled="loadingAccept"
                      v-if="isPeding"
                      class="btn btn-primary btn-xs fw-bold fs-3 w-3"
                      @click="accept()"
                    >
                      <span
                        v-if="loadingAccept"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="fa fa-user-plus"></span>
                      Accept
                    </button>
                    <button 
                      v-if="student.isFollower" 
                      @click="getOrCreateMessage()" 
                      :disabled="loadingMessage"
                      class="btn btn-primary btn-xs" style="margin-left: 10px;">
                        <span
                          v-if="loadingMessage"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      Send a message
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div id="icons" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center">
                  <ul>
                    <li v-if="isHotlead">
                      <img src="@/assets/img/hotlead_active.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span>Hotlead</span>
                    </li>
                    <li v-else>
                      <img src="@/assets/img/hotlead_inactive.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span class="disabled" >Hotlead</span>
                    </li>
                    <li v-if="isStudent">
                      <img src="@/assets/img/is_student_active.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span>Student</span>
                    </li>
                    <li v-else>
                      <img src="@/assets/img/is_student_inactive.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span>Student</span>
                    </li> 
                    <li v-if="isParent">
                      <img src="@/assets/img/is_parent_active.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span class="enable">Parent</span>
                    </li>
                    <li v-else>
                      <img src="@/assets/img/is_parent_inactive.png" class="img-responsive inline-block" alt="Responsive image" />
                      <span class="enable">Parent</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="card-match" class="card">
              <div class="card-body">
                <h2 class="card-title fw-bold">
                  <span v-if="student.match && student.match.value">
                    {{ student.match.value }}% match
                  </span>
                  <span v-else>0% match</span>
                </h2>
                <p class="card-text">Based on information from your institution</p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h2 class="card-title fw-bold">
                  {{
                    studentData.profile.parser.investmentPerYear
                      ? getInvestmentPerYear(studentData.profile.parser.investmentPerYear)
                      : 'Not informed'
                  }}
                </h2>
                <p class="card-text">Estimated budget allocated for tuition</p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h2 class="card-title fw-bold">
                  {{
                    studentData.profile.expectFinishSchool &&
                    studentData.profile.expectFinishSchool.year &&
                    studentData.profile.expectFinishSchool.semester
                      ? `${
                          studentData.profile.expectFinishSchool.semester == 1
                            ? 'First'
                            : 'Second'
                        } semester of ${
                          studentData.profile.expectFinishSchool.year
                        } `
                      : 'Not informed'
                  }}
                </h2>
                <p class="card-text">Expectation of finishing school</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import StudentDetailController from "./StudentDetailController";
export default StudentDetailController;
</script>
<style scoped lang="scss">


.bg-input {
  background-color: #ececec;
}
label {
  display: inline-block;
  color: #ed6125 !important;
  font-weight: bold;
}

.text-color {
  color: #4e4b4b;
}

.private-field {
  display: block;
  background-color: #f2f2f2;
  width: 100%;
  padding: 5px;
  border-radius: 7px;
  color: #434040;
  margin-top: 5px;
}

#card-match {
  background-color: #a4e2a3;
}

#card-info {
  .card-header {
    height: 180px;
  }

  .card-body {
    border-radius: 0px 0px 12px 12px;
    padding-bottom: 0px;
    background-color: #ffffff;
    ul {
      display: flex;
      width: 100%;
      padding: 0px;
      li {
        list-style: none;
        text-align: center;
        flex: 1;
        span {
          display: block;
          font-weight: bold;
          .disabled {
            color: #ececec;
          }
        }
      }
    }
    
    img {
      border: 0 none;
      // display: inline-block;
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }
  
}

.card {
  background-color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  .card-body {
    padding: 15px 20px;
    font-size: 15px;
  }
  .card-title {
    font-size: 23px;
  }
}
</style>
