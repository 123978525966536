import MultiRangeSlider from "@/components/MultiRangeSlider.vue";
import { email, required } from "vuelidate/lib/validators";
import BaseFormCheckbox from '@/components/BaseFormCheckbox'
import BaseTable from '@/components/BaseTable'
import FollowersTable from '@/components/Audience/FollowersTable'

export default {
  name: "Audience",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 0)
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  mounted() {
    this.loadingAll = true
    this.getItems()
    this.selectedTab = this.$route.query.tab || 0
  },
  data() {
    return {
      loadingAll: false,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      totalCost: 0,
      columns: {
        requests: [
          'Name',
          'Location',
          'School Type',
          'Match',
          'Cost to accept ',
        ],
        followers: [
          { key: 'name', label: 'Name' },
          { key: 'location', label: 'Location' },
          { key: 'schoolType', label: 'School Type' },
          { key: 'match', label: 'Match' },
          { key: '_buttons', label: '' },
        ]
      },
      loadings: [],
      loadingAccept: false,
      requests: [],
      followers: [],
      loadingFollowers: false,
      showingRequests: [],
      paginationFollowers: {
        limit: 30,
        showMore: false
      },
      currentPageFollowers: 1,
      totalRowsFollowers: null,
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      },

      autoAcceptFollowers: {
        enabled: false,
        match: null
      },
      loadingAutoAcceptFollowers: false,
      searchFollowerString: '',
      timerSearch: null
    };
  },
  methods: {
    updateCurrentPageFollowers(page) {
      this.currentPageFollowers = page
      this.getFollowers({ page })
    },
    inputMatchFilter(){
      let max = 100;
      let min = 1;

      let autoAcceptFollowers = this.autoAcceptFollowers.match.replace(/\D/g, "");

      if (autoAcceptFollowers) {
        this.autoAcceptFollowers.match = autoAcceptFollowers > max ? max : (autoAcceptFollowers < min ? min : autoAcceptFollowers)
      } else {
        this.autoAcceptFollowers.match = null
      }
    },
    async saveAutoAccept() {
      this.loadingAutoAcceptFollowers = true

      try {
        const res = await this.$universityService.updateConfig({ autoAcceptFollowers: this.autoAcceptFollowers })
        this.autoAcceptFollowers = res.data.data.autoAcceptFollowers

        this.$eventBus.$emit("showToast", {
          title: "Configuration updated",
          msg: `The auto accept configuration was updated successfully`,
        });
      } catch (e) {

      } finally {
        this.loadingAutoAcceptFollowers = false
      }
    },
    async confirmAutoAcceptCheckbox() {
      this.autoAcceptFollowers.enabled = !this.autoAcceptFollowers.enabled

      if (this.autoAcceptFollowers.enabled === false) {
        this.autoAcceptFollowers.match = null
        await this.saveAutoAccept()
      }
    },
    async confirmAutoAccept() {
      this.loadingAutoAcceptFollowers = true
      await this.saveAutoAccept()
    },
    async getConfig() {
      this.loadingAutoAcceptFollowers = true

      try {
        const { data } = await this.$universityService.getConfig()

        if (data.data.autoAcceptFollowers) {
          this.autoAcceptFollowers = data.data.autoAcceptFollowers
        }
      } catch (e) {

      } finally {
        this.loadingAutoAcceptFollowers = false
      }
    },
    getItems() {
      this.getRequests()
      this.getFollowers({})
      this.getConfig()
      this.totalCost = 0
      this.allSelected = false
    },
    getRequests() {
      Promise.all([this.$audienceService.getRequests()]).then((values) => {
        let requests = values[0].data.data

        requests.map(r => {
          if (r.user.profile.firstname) {
            r.userName = r.user.profile.firstname
          } else {
            const name = r.user.name.split(' ');
            const firstname = name[0];
            r.userName = firstname
          }
        })

        this.$set(this, 'requests', requests)
        this.$set(this, 'showingRequests', requests)

      }).catch(e => console.log(e)).finally(() => this.loadingAll = false)
    },
    getFollowers({ page = 1, limit = this.paginationFollowers.limit }) {
      this.loadingFollowers = true;
      const that = this
      
      this.$audienceService.getFollowers({
        page,
        limit,
        search: this.searchFollowerString
      })
        .then(res => {
          this.totalRowsFollowers = res.data.meta.count
          this.followers = res.data.data
        }).catch(e => console.log(e))
        .finally(() => {
          that.loadingFollowers = false
        })
    },

    calculateTotalCost() {
      const t = this.requests
        .filter((r) => r.selected)
        .reduce((a, b) => a + b.costToAccept, 0)
      this.totalCost = t
    },

    toggleSelectAll() {
      this.allSelected = !this.allSelected
      this.requests = this.requests.map(r => { return { ...r, selected: this.allSelected } })
      this.$set(this, 'showingRequests', this.requests)
      this.calculateTotalCost()
    },
    clearSelected() {
      this.allSelected = false
      this.requests = this.requests.map(r => { return { ...r, selected: false } })
      this.$set(this, 'showingRequests', this.requests)
      this.calculateTotalCost()
    },
    toggleSelectItem(item) {
      let req = this.requests.map(r => {
        return { ...r, selected: r._id == item._id ? !item.selected : r.selected }
      })
      this.$set(this, 'requests', req)
      this.$set(this, 'showingRequests', req)
      this.allSelected = false
      this.calculateTotalCost()
    },
    itemPressed(item) {
      this.$router.push({ path: `${this.$paths.students.detail}/${item.user._id}` })
    },
    filterChanged() {
      const schoolCount = Object.keys(this.filters.school_type).filter(s => this.filters.school_type[s]).length
      const locationCount = Object.keys(this.filters.location).filter(s => this.filters.location[s]).length
      const matchCount = (this.filters.match.min != 20 || this.filters.match.max != 100) ? 1 : 0
      this.filtersCount = schoolCount + locationCount + matchCount
    },
    matchProgressDrag(values) {
      this.filters.match = { ...values }
    },
    searchFollowers() {
      clearTimeout(this.timerSearch);
      this.timerSearch = setTimeout( async () => {
        this.currentPageFollowers = 1;
        this.getFollowers({})
      }, 500)
    },
    searchString(e) {
      const req = this.$helper.searchString(this.requests, e.target.value)
      const foll = this.$helper.searchString(this.followers, e.target.value)
      console.log(foll)
      this.$set(this, 'showingRequests', req.filter((ftr) => !ftr.hidden))
    },
    async acceptRequests(index = -1) {
      try {
        if (this.userSession.hasSubscription === false && (this.userSession.credits < this.totalCost || this.userSession.credits == 0)) {
          this.$eventBus.$emit("toggleWalletModal");
          return
        }
        let ids = []
        if (index > -1) {
          ids = [this.requests[index]._id]
          this.$set(this.loadings, index, true)
        } else {
          ids = this.requests.filter(f => f.selected).map(g => g._id)
        }
        this.loadingAccept = true
        const res = await this.$audienceService.acceptRequests(ids)
        this.getItems()
        this.$eventBus.$emit('showToast', { title: `${ids.length} Follower(s) Accepted`, msg: `${this.totalCost} credits were deducted from your wallet.` })
      } catch (e) {

      } finally {
        this.loadingAccept = false
        if (index > -1) {
          this.$set(this.loadings, index, false)
        }
      }
    },
    async declineRequests(index = -1) {
      try {
        if (this.userSession.credits < this.totalCost || this.userSession.credits == 0) {
          this.$eventBus.$emit("toggleWalletModal");
          return
        }
        let ids = []
        if (index > -1) {
          ids = [this.requests[index]._id]
          this.$set(this.loadings, index, true)
        } else {
          ids = this.requests.filter(f => f.selected).map(g => g._id)
        }
        this.loadingAccept = true
        const res = await this.$audienceService.declineRequests(ids)
        this.getItems()
        this.$eventBus.$emit('showToast', { title: `${ids.length} Follower(s) Declined`, msg: `` })
      } catch (e) {

      } finally {
        this.loadingAccept = false
        if (index > -1) {
          this.$set(this.loadings, index, false)
        }
      }
    },
    handleSelectTab(index) {
      console.log(this.userSession)
      this.$router.push({ path: `${this.$paths.audience}`, query: { tab: index } }).catch(e => { })
    },
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    MultiRangeSlider,
    BaseFormCheckbox,
    BaseTable,
    FollowersTable
  },
};