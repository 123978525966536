<template>
  <div class="signin">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 px-lg-6 mb-5 mt-7">
          <img src="@/assets/img/logo.jpg" alt="" class="small-logo mb-6" />
          <div class="my-5">
            <h1 class="header-title mb-4">Sign up</h1>
            <h4 class="text-primary">1. Representative information</h4>
          </div>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label class="form-label required"> Institution Name </label>
              <input
                class="form-control"
                v-model.trim="$v.form.institutionName.$model"
                :class="{ 'is-invalid': $v.form.institutionName.$error }"
                type="text"
                placeholder="Institution"
              />
              <div class="invalid-feedback">
                <small>Please fill in this field correctly</small>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label required"> Representative Name </label>
              <input
                class="form-control"
                v-model.trim="$v.form.representativeName.$model"
                :class="{ 'is-invalid': $v.form.representativeName.$error }"
                type="text"
                placeholder="First and Last Name"
              />
              <div class="invalid-feedback">
                <small>Please fill in this field correctly</small>
              </div>
            </div>
            <div class="form-group mb-6 row">
              <div class="col-12"></div>
              <div class="col-6">
                <label class="form-label required"> Country </label>
                <button
                  id="btnGroupDrop1"
                  type="button"
                  class="btn btn-white dropdown-toggle text-start d-flex justify-content-between align-items-center"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="
                    width: 100%;
                    height: 41px;
                    border: 1px solid #d0cfcf;
                    border-radius: 0.375rem !important;
                    font-weight: 400 !important;
                  "
                >
                  {{ eventLabel }}
                </button>
                <ul
                  class="dropdown-menu dropdown-fixed-height"
                  aria-labelledby="btnGroupDrop1"
                  style="min-width: 250px"
                >
                  <li class="w-100 me-0 position-absolute" style="top: 0">
                    <input
                      @input="searchCountry"
                      class="form-control border-0 border-bottom ps-4"
                      type="text"
                      placeholder="Search..."
                    />
                  </li>
                  <li
                    class="dropdown-item cursor-pointer"
                    v-for="(e, i) in countryPhoneCodes"
                    :key="i"
                    :class="{ 'pt-5': i == 0 }"
                    @click="setPhoneCountry(e)"
                  >
                    {{ e.countryName }}
                  </li>
                </ul>
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
              </div>
              <div class="col-6">
                <label class="form-label required"> Representative Phone </label>
                <div class="input-group input-group-merge input-group-reverse mb-3">
                  <input
                    class="form-control"
                    v-model.trim="$v.form.representativePhone.$model"
                    :class="{ 'is-invalid': $v.form.representativePhone.$error }"
                    type="text"
                    maxlength="12"
                    placeholder="1234567890"
                  />
                  <div class="input-group-text text-dark" id="inputGroupReverse">
                    <span>{{ form.phoneCode }}</span>
                  </div>
                </div>
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
              </div>
            </div>
            <small class="text-muted"
              >You must fill all the required fields
              <small class="text-danger">*</small> to continue</small
            >

            <button :disabled="$v.form.$invalid" class="btn w-100 btn-primary my-3">
              Save and continue
            </button>

            <p class="text-center">
              <small class="text-dark">
                Already have an account?
                <router-link :to="$paths.signin" tag="a"> Sign In </router-link>.
              </small>
            </p>
          </form>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-7 d-none d-lg-block">
          <div id="bgimg" class="bg-cover h-100 min-vh-100 mt-n1 me-n3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SignupInfoController from "./SignupInfoController";
export default SignupInfoController;
</script>
<style scoped>
#bgimg {
  background-image: url("../../../assets/img/register_bg.png");
}
</style>
