import NetworkService from '@/services/NetworkService';

export default class ProfileService {
    constructor() {
        this.network = new NetworkService()
    }
    getTokenUpdateProfile() {
        return this.network.post(`${this.network.endpoints.Token.getTokenUpdateProfile}`)
    }
    getTokenAccessHub() {
        return this.network.post(`${this.network.endpoints.Token.getTokenAccessHub}`)
    }
}
