import AppointmentsCalendar from "@/components/AppointmentsCalendar/AppointmentsCalendar.vue";
import Carousel from "@/components/Carousel.vue";
import MonthSelector from "@/components/MonthSelector.vue";
import Slider from "@/components/Slider.vue";
import Availability from "@/views/Agenda/Availability/Availability.vue";

export default {
  watch: {
    $route(to, from) {
      this.selectedTab = to.query.tab || 0;
    },
  },
  mounted() {
    this.loadingAll = true;
    this.getAgenda();
    this.selectedTab = this.$route.query.tab || 0;
  },
  created() {
    window.addEventListener("resize", this.calculateWidth);
  },
  data() {
    return {
      loadingAll: false,
      columns: ["Date", "Time", "Student", "Status"],
      selectedDate: null,
      monthTitle: this.$moment().format("MMMM"),
      loadings: [],
      timeZone: this.$moment.tz.names()[0],
      timeZones: this.$moment.tz.names().map((r) => {
        return { name: r };
      }),
      showingTimeZones: this.$moment.tz.names().map((r) => {
        return { name: r };
      }),
      filtersCount: 0,
      hasStarted: false,
      availabilityStep: 0,
      selectedTab: 0,
      appointmentItems: [],
      requestItems: [],
      requestsShowingItems: [],
      monthAppointments: [],
      allSelected: false,
      days: Array.from({ length: this.$moment().daysInMonth() }, (x, i) => ({
        id: i + 1,
        day: this.$moment().startOf("month").add(i, "days").format("DD"),
        date: this.$helper.weekdays.short[
          this.$moment()
            .date(i + 1)
            .get("weekdays")
        ],
        full_date: this.$moment().date(i + 1),
        selected:
          this.$moment().startOf("month").add(i, "days").format("DD") ==
          this.$moment().date(),
      })),
      hasAgenda: false,
      columnWidth: 724,
    };
  },
  methods: {
    async getAgenda() {
      try {
        const r = await this.$agendaService.getAgenda();
        this.hasStarted = true;
        this.availabilityStep = 0;
        this.getAppointments();
      } catch (e) {
        this.hasStarted = false;
        this.availabilityStep = 0;
        this.loadingAll = false;
      }
    },
    async getAppointments(date = this.$moment().format("YYYY/MM")) {
      try {
        const res = await this.$agendaService.getAppointments(date);
        res.data.data.map((s) => {
          if (s.student) {
            s.studentName = s.student.name;
          }
          s.isBefore = this.$moment().isBefore(
            `${s.dateTime.date} ${s.dateTime.start}`
          );
        });
        const requests = res.data.data.filter(
          (r) => r.status == "AWAITING_ACCEPT" && !r.accept.institution
        );
        const monthAppointments = res.data.data.filter(
          (r) => r.status != "AWAITING_ACCEPT"
        );

        this.$set(this, "requestItems", requests);
        this.$set(this, "requestsShowingItems", requests);
        this.$set(this, "monthAppointments", monthAppointments);
        this.$set(this, "appointmentItems", monthAppointments);

        // this.setAppointmentsForTheDay()
        this.calculateWidth();
      } catch (e) {
        console.log(e);
        this.$set(this.monthAppointments, []);
        this.$set(this.appointmentItems, []);
      } finally {
        this.loadingAll = false;
      }
    },
    calculateWidth() {
      setTimeout(() => {
        this.columnWidth = this.$refs.columnWidth?.clientWidth;
      }, 1000);
    },
    getStarted() {
      this.availabilityStep = 1;
    },
    saveAvailability() {
      this.$eventBus.$emit("showToast", {
        title: "Agenda Created",
        msg: `Your agenda has been created successfully.`,
      });
      this.hasStarted = true;
      this.availabilityStep = 0;
      this.getAppointments();
    },
    monthChanged(date) {
      console.log(date);
      this.$set(this, "selectedDate", date.date);
      const currentSelectedDate = this.$moment(date.date);
      let d = Array.from(
        { length: currentSelectedDate.daysInMonth() },
        (x, i) => {
          const dd = currentSelectedDate
            .startOf("month")
            .add(i, "days")
            .format("DD");
          return {
            id: i + 1,
            day: dd,
            date: this.$helper.weekdays.short[
              currentSelectedDate.date(i + 1).get("weekdays")
            ],
            full_date: currentSelectedDate.date(i + 1),
            selected:
              (i == 0 &&
                this.$moment().format("YYYY-MM") !=
                  currentSelectedDate.format("YYYY-MM")) ||
              (this.$moment().format("YYYY-MM") ==
                currentSelectedDate.format("YYYY-MM") &&
                this.$moment().format("DD") == dd),
          };
        }
      );
      console.log(d);
      this.$set(this, "days", d);
      this.getAppointments(date.date.replace("-", "/"));
    },
    itemPressed(item) {
      this.$router.push({
        path: `${this.$paths.agenda.appointment_detail}/${item._id}`,
      });
    },
    setAppointmentsForTheDay() {
      const selectedDay = this.days.filter((s) => s.selected)[0].day;

      const dayAppointments = this.monthAppointments.filter(
        (r) => this.$moment(r.dateTime.date).format("DD") == selectedDay
      );
      const dayAppointmentRequests = this.requestItems.filter(
        (r) => this.$moment(r.dateTime.date).format("DD") == selectedDay
      );

      console.log(dayAppointments);

      this.$set(this, "appointmentItems", dayAppointments);
      this.$set(this, "requestsShowingItems", dayAppointmentRequests);
    },
    calendarDayChanged(day) {
      console.log("here", day);
      this.selectDay(null, day);
    },
    selectDay(index, day = null) {
      this.days = this.days.map((e, i) => ({
        ...e,
        selected: i == index || e.day == day,
      }));

      this.setAppointmentsForTheDay();
    },
    setTimeZone(e) {
      this.timeZone = e;
    },
    async acceptAppointment(index) {
      try {
        const appointment = this.requestItems[index];
        this.$set(this.loadings, index, true);
        const r = await this.$agendaService.makeAppointmentDecision(
          appointment.lastRescheduleId,
          { decision: "true" }
        );
        this.getAppointments();
      } catch (e) {
      } finally {
        this.$set(this.loadings, index, false);
      }
    },
    searchString(e) {
      const it = this.$helper.searchString(
        this.requestItems,
        e.target.value,
        "studentName"
      );
      this.requestsShowingItems = it.filter((ftr) => !ftr.hidden);
    },
    handleSelectTab(index) {
      this.$router
        .push({ path: `${this.$paths.agenda.list}`, query: { tab: index } })
        .catch((e) => {});
    },
    searchTimeZone(e) {
      console.log(e.target.value);
      let ct = this.$helper.searchString(this.timeZones, e.target.value);
      this.$set(
        this,
        "showingTimeZones",
        ct.filter((ftr) => !ftr.hidden)
      );
    },
  },
  components: {
    Availability,
    MonthSelector,
    AppointmentsCalendar,
    Carousel,
    Slider,
  },
};
