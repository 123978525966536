<template>
  <div 
    :class="['base-input-tag', hasError ? 'base-input-tag-error' : null]"
  >
    <div
      :class="{
        'wrapper--active': isInputActive
      }"
      class="wrapper"
    >
      <span v-for="(tag, index) in innerTags" :key="index" class="input-tag">
        <span>
          {{ isObject ? options.find((op) => op.value == tag).name : tag }}
        </span>
        <a @click.prevent.stop="remove(index)" class="remove"></a>
      </span>
      <input
        :id="id"
        class="new-tag"
        v-model="input"
        :placeholder="dynamicPlaceholder()"
        @focus="focusNewTag"
        @blur="handleInputBlur"
        autocomplete="off"
      />
    </div>
    <template v-if="autocomplete">
      <div class="options" v-if="isInputActive && autocomplete.length > 0">
        <div
          v-for="(option, index) in autocomplete"
          :key="index"
          class="option"
          @mousedown="addNew($event, option)"
        >
          {{ isObject ? option.name : option }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectTags',

  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    isObject: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      innerTags: [...this.value],
      isInputActive: false,
      autocomplete: [],
      input: ''
    }
  },

  watch: {
    input() {
      this.filter(this.input)
    },
    value() {
      this.innerTags = [...this.value]
    }
  },

  methods: {
    dynamicPlaceholder() {
      return this.value.length ? '' : this.placeholder
    },
    focusNewTag() {
      this.isInputActive = true
      this.input = ''
      this.filter()
    },

    handleInputBlur() {
      this.isInputActive = false
    },

    filter(value) {
      if (this.isInputActive) {
        if (this.isObject) {
          this.autocomplete = this.options.filter((x) => {
            return !this.innerTags.find((tag) => tag == x.value)
          })
          if (value) {
            this.autocomplete = this.autocomplete.filter((tag) =>
              tag.name.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else {
          this.autocomplete = this.options.filter((x) => {
            return !this.innerTags.find((tag) => tag == x)
          })
          if (value) {
            this.autocomplete = this.autocomplete.filter((tag) =>
              tag.toLowerCase().includes(value.toLowerCase())
            )
          }
        }
      }
    },

    addNew(e, tag) {
      if (this.isObject) {
        this.innerTags.push(tag.value)
      } else {
        this.innerTags.push(tag)
      }
      this.$emit('input', this.innerTags)

      e && e.preventDefault()

      this.focusNewTag()
    },

    remove(index) {
      this.innerTags.splice(index, 1)
      this.$emit('input', this.innerTags)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-input-tag {
  width: 100%;
  position: relative;
  min-height: 60px;

  &-error {
    border: 1px solid red;
  }

  .button {
    width: 100%;
    height: 58px;
    border: none;
    border-radius: 6px;
    background-color: white;
    text-align: left;
    padding: 0 100px 0 20px;
    color: #4a4a4a;
    background-color: white;
  }

  .wrapper {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d0cfcf;
    overflow: hidden;
    padding: 1px 10px;
    cursor: text;
    text-align: left;
    min-height: 32px;

    .input-tag {
      border-radius: 3px;
      background-color: #e7e7e7;
      color: #4a4a4a;
      display: inline-block;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 2px;
      margin-right: 2px;
      padding: 5px 5px 5px 10px;

      .remove {
        cursor: pointer;
        font-weight: bold;
        color: #fd5101 !important;
        padding: 0 7px;

        &:hover {
          text-decoration: none;
        }

        &:empty::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='8' height='8'%3E%3Cpath fill='%23000000' fill-rule='evenodd' d='M6 4.294L2.025.32A1.086 1.086 0 0 0 .492.313l-.18.18A1.087 1.087 0 0 0 .32 2.025L4.294 6 .32 9.975a1.087 1.087 0 0 0-.006 1.532l.18.18c.42.421 1.11.416 1.532-.007L6 7.706l3.975 3.974a1.086 1.086 0 0 0 1.533.007l.18-.18a1.087 1.087 0 0 0-.007-1.532L7.706 6l3.975-3.975a1.087 1.087 0 0 0 .006-1.532l-.18-.18A1.086 1.086 0 0 0 9.976.32L6 4.294z'/%3E%3C/svg%3E");
        }
      }
    }

    .new-tag {
      background: transparent;
      border: none;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 2px;
      outline: none;
      padding: 4px;
      flex-grow: 1;
      height: 32px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  .options {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #a7a7a7;
    z-index: 4;
    max-height: 216px;
    overflow-y: auto;
    cursor: pointer;
    position: absolute;

    .option {
      padding: 5px;

      &:hover {
        background-color: #a7a7a7;
      }
    }
  }
}
</style>
