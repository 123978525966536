<template>
  <div class="row">
    <BaseTable
      :fields="columns.followers"
      :items="followers"
      :busy="loadingTable"
      :per-page="perPage"
      :disable-native-pagination="disableNativePagination"
      :page-updated="pageUpdated"
      :total-rows="totalRows"
      @updateCurrentPage="(page) => updateCurrentPage(page)"
      class="table table-sm table-hover table-nowrap card-table bg-white"
    >
      <template v-slot:cell(name)="row" >
        <strong @click="itemPressed(row.item)">
          {{ row.item.user.name }}
        </strong>
      </template>

      <template v-slot:cell(location)="row">
        <span v-if="row.item.user.profile && row.item.user.profile.location"
          >{{ row.item.user.profile.location.country }},
          {{ row.item.user.profile.location.city }}
        </span>
      </template>

      <template v-slot:cell(match)="row">
        <span v-if="row.item.match" class="ms-auto badge bg-primary-soft">
          {{ row.item.match.value }}%
        </span>
        <span v-else class="ms-auto badge bg-primary-soft">0%</span>
      </template>
      
      <template #cell(_buttons)="row">
        <button
          class="btn btn-sm btn-outline-primary"
          @click="itemPressed(row.item)"
        >
          <span>
            See profile
          </span>
        </button>
      </template>
    </BaseTable>
  </div>
</template>

<script>

import BaseTable from '@/components/BaseTable'

export default {
  name: 'FollowersTable',

  components: {
    BaseTable,
  },

  props: {
    loadingPageInfos: {
      type: Boolean,
      required: false,
      default: true,
    },

    followers: {
      type: Array,
      required: true,
      default: () => [],
    },

    perPage: {
      type: Number,
      default: 30,
    },

    disableNativePagination: {
      type: Boolean,
      required: false,
      default: false,
    },

    pageUpdated: {
      type: Number,
      default: null,
    },

    totalRows: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      columns: {
        followers: [
          { key: 'name', label: 'Name' },
          { key: 'location', label: 'Location' },
          { key: 'schoolType', label: 'School Type' },
          { key: 'match', label: 'Match' },
          { key: '_buttons', label: '' },
        ]
      },
    }
  },

  computed: {
    loadingTable() {
      return this.loadingPageInfos
    },
  },

  methods: {
    updateCurrentPage(page) {
      this.$emit('updateCurrentPage', page)
    },

    itemPressed(item) {
      this.$router.push({ path: `${this.$paths.students.detail}/${item.user._id}` })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .table {
  // @extend %table-default-theme;

  tr {
    th,
    td {
      &:not(:first-child) &:not(:last-child) {
        text-align: center;
      }
    }
  }
}

/deep/ .card-header {
  span {
    display: flex;
    align-items: center;
  }
}
</style>