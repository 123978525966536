<template>
  <div class="audience">
    <Sidebar />
    <NavBar current="Audience" />
    <LoadingContainer v-if="loadingAll" />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Audience</h1>
                    <h4 class="header-subtitle">View and manage your followers - students that are in or want to be in your university's community.</h4>
                  </div>
                  <div class="col-auto"></div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link"
                          :class="{ active: selectedTab == 0 }"
                          id="requests-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#requests"
                          type="button"
                          role="tab"
                          aria-controls="requests"
                          aria-selected="true"
                        >
                          Follow requests
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 0,
                              'bg-secondary-soft': selectedTab != 0,
                            }"
                            >{{ requests.length }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link"
                          :class="{ active: selectedTab == 1 }"
                          id="followers-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#followers"
                          type="button"
                          role="tab"
                          aria-controls="followers"
                          aria-selected="false"
                        >
                          Followers
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{ totalRowsFollowers }}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              
              <!-- REQUESTS -->
              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 0 }"
                id="requests"
                role="tabpanel"
                aria-labelledby="requests-tab"
              >
                <div class="search-table-form">
                  <div class="row align-items-center">
                    <div class="col">
                      <form>
                        <div
                          class="input-group input-group-flush input-group-merge input-group-reverse"
                        >
                          <input
                            class="form-control list-search"
                            type="search"
                            placeholder="Search by student name"
                            @input="searchString"
                          />
                          <span class="input-group-text">
                            <i class="fas fa-search ps-3"></i>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <!-- <ul class="nav nav-tabs border-0 mb-4" style="float: right" role="tablist">
                  <li class="m-1 mt-3" role="presentation">
                    <BaseFormCheckbox style="display: inline; margint-top: 5px" 
                      :checked="autoAcceptFollowers.enabled" 
                      @click="confirmAutoAcceptCheckbox"
                    /> 
                    <label class="inline"> Automatically accept requests from students with a match above </label>
                  </li>
                  <li class="m-1" role="presentation">                    
                    <input
                      class="form-control"
                      style="width: 90px;"
                      type="search"
                      min="1"
                      max="100"
                      placeholder="% match"
                      @input="inputMatchFilter()"
                      v-model.trim="autoAcceptFollowers.match"
                      :disabled="loadingAutoAcceptFollowers || !autoAcceptFollowers.enabled"
                    />
                  </li>
                  <li class="m-1" role="presentation">
                    <button
                      @click="confirmAutoAccept"
                      class="btn btn-md btn-primary"
                      :disabled="loadingAutoAcceptFollowers || !autoAcceptFollowers.match || (!autoAcceptFollowers.enabled && !autoAcceptFollowers.match)"
                    >
                      <span
                        v-if="loadingAutoAcceptFollowers"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                        Save
                    </button>
                  </li>
                </ul> -->

                <EmptyState
                  title="No follow requests yet"
                  msg="It looks like you have no requests"
                  v-if="requests.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-users fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="requests.length > 0"
                  class="table table-sm table-hover table-nowrap card-table bg-white"
                >
                  <thead>
                    <tr>
                      <th width="1%">
                        <a class="form-check ps-0" @click.prevent="toggleSelectAll()">
                          <i v-if="!allSelected" class="fas fa-square text-light"></i>
                          <i
                            v-if="allSelected"
                            class="fas fa-check-square text-primary"
                          ></i>
                        </a>
                      </th>
                      <th
                        v-for="(column, index) in columns.requests"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      <th width="1%">
                        <a href="#" class="text-muted"> Action </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="(item, index) in showingRequests"
                      v-bind:key="item.id"
                      class="cursor-pointer"
                    >
                      <td>
                        <a
                          class="form-check ps-0"
                          @click.prevent="toggleSelectItem(item)"
                        >
                          <i v-if="!item.selected" class="fas fa-square text-light"></i>
                          <i
                            v-if="item.selected"
                            class="fas fa-check-square text-primary"
                          ></i>
                        </a>
                      </td>
                      <td @click="itemPressed(item)" class="text-start">
                        <strong>{{ item.userName }}</strong>
                      </td>
                      <td @click="itemPressed(item)">N/A</td>
                      <td @click="itemPressed(item)">{{ item.schoolType }}</td>
                      <td @click="itemPressed(item)">
                        <span v-if="item.match" class="ms-auto badge bg-primary-soft"
                          >>{{ item.match.value }}%</span
                        >
                        <span v-else class="ms-auto badge bg-primary-soft">0%</span>
                      </td>
                      <td @click="itemPressed(item)">
                        {{ parseInt(item.costToAccept) || 0 }} credits
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-outline-primary"
                          style="margin-right: 5px;"
                          @click="itemPressed(item)"
                        >
                          <span>
                            See profile
                          </span>
                        </button>
                        <button
                          @click="declineRequests(index)"
                          class="btn btn-sm btn-danger"
                          style="margin-right: 5px;"
                          :disabled="loadings[`${index}`]"
                        >
                          <span
                            v-if="loadings[`${index}`]"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Decline
                        </button>
                        <button
                          @click="acceptRequests(index)"
                          class="btn btn-sm btn-green"
                          :disabled="loadings[index]"
                        >
                          <span
                            v-if="loadings[index]"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Accept
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- FOLLOWERS TAB -->

              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 1 }"
                id="followers"
                role="tabpanel"
                aria-labelledby="followers-tab"
              >
                <div class="search-table-form">
                  <div class="row align-items-center">
                    <div class="col">
                      <form>
                        <div
                          class="input-group input-group-flush input-group-merge input-group-reverse"
                        >
                          <input
                            class="form-control list-search"
                            type="text"
                            placeholder="Search by student name"
                            v-model="searchFollowerString"
                            @input="searchFollowers"
                          />
                          <span class="input-group-text">
                            <i class="fas fa-search ps-3"></i>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <EmptyState
                  title="No Followers"
                  msg="It looks like you have no followers"
                  v-if="followers.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-users fa-3x text-primary"></i>
                  </template>
                </EmptyState>

                <FollowersTable
                  v-if="followers.length > 0"
                  :loading-page-infos="loadingFollowers"
                  :followers="followers"
                  :per-page="paginationFollowers.limit"
                  :disable-native-pagination="true"
                  :page-updated="currentPageFollowers"
                  :total-rows="totalRowsFollowers"
                  @updateCurrentPage="(page) => updateCurrentPageFollowers(page)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="list-alert alert alert-dark alert-dismissible border fade pe-2"
      :class="{ show: requests.filter((r) => r.selected).length }"
      role="alert"
    >
      <div class="row align-items-center">
        <div class="col">
          <div class="">
            <i class="fas fa-check-square text-primary me-2"></i>
            <label class="form-check-label text-white" for="listAlertCheckbox">
              <span class="list-alert-count">{{
                requests.filter((r) => r.selected).length
              }}</span>
              selected
            </label>
          </div>
        </div>
        <div class="col-auto me-n3">
          <button
            class="btn btn-sm btn-white-20 bg-transparent pe-4"
            @click="clearSelected()"
          >
            <i class="fas fa-trash"></i> <small>Remove All</small>
          </button>
          <button
            :disabled="loadingAccept"
            @click.prevent="acceptRequests()"
            class="btn btn-sm btn-green"
          >
            <span
              v-if="loadingAccept"
              class="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            <span
              v-if="totalCost > 1"
            >
              {{ totalCost }}
              Credits to accept all
            </span>
            <span v-else>
              {{ totalCost }}
              Credit to accept all
            </span>
          </button>
          <button
            class="btn btn-sm btn-danger"
            style="margin-left: 5px;"
            @click.prevent="declineRequests()"
          >
            <span>
              Decline all
            </span>
          </button>
        </div>
        <div class="col-auto">
          <button @click="clearSelected()" type="button" class="btn" aria-label="Close">
            <i class="fas fa-times text-white"></i>
          </button>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import AudienceController from "./AudienceController";
export default AudienceController;
</script>
