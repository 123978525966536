<template>
  <div>
    <Sidebar />
    <NavBar current="Select a plan" />

    <div class="main-content">
      <div class="container-fluid bg-white vh-100">
        <div class="row">
          <div class="col-12 col-md-4 justify-content-center offset-md-4 py-6">
            <h1 class="header-title">Select a plan</h1>
            <div class="progress my-5">
              <div
                class="progress-bar"
                role="progressbar"
                :style="`width: ${percentage}%`"
                :aria-valuenow="percentage"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            <div v-if="step == 1">
              <p><strong>1. Choose a subscription plan</strong></p>

              <div class="row">
                <div class="col-12 cursor-pointer">
                  <div
                    v-for="item in plans"
                    v-bind:key="item.id"
                    class="card border-2"
                    :class="{
                      'border-primary': isSelectedPlan(item._id),
                      'border-muted': !isSelectedPlan(item._id),
                    }"
                    @click="inputSelectedPlan(item)"
                  >
                    <div class="card-body">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="h3 fw-bold">{{ item.title }} <small v-if="item.description" class="text-primary fw-bold">({{ item.description }})</small></span>
                          <h5 class="mb-2 mt-2 text-primary">
                            <!-- <strong>Cost Us$ 3,000</strong> -->
                            <strong>Cost US$ {{ getFormatAmount(item.amount) }}</strong>
                          </h5>
                        </div>
                        <div class="col-auto">
                          <span
                            v-if="isSelectedPlan(item._id)"
                            class="h2 fas fa-check-circle mb-0 text-primary"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button 
                class="btn btn-primary w-100 mt-5"
                :disabled="form.selectedPlan === null" 
                @click="nextStep(3)"
              >
                Continue
              </button>
            </div>

            <!-- STEP 1 -->
            <div v-if="step == 2">
              <p><strong>2. Choose amount</strong></p>

              <div class="row">
                <div
                  class="col-6 col-lg-6 cursor-pointer"
                  v-for="item in prices"
                  v-bind:key="item.id"
                  @click="handleItemPressed(item)"
                >
                  <div
                    class="card border-2"
                    :class="{
                      'border-primary': item._id == form.creditPack,
                      'border-muted': item._id != form.creditPack,
                    }"
                  >
                    <div class="card-body">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="h3">{{ item.credits }} credits</span>
                          <h5 class="mb-2 text-primary">
                            <strong>Cost Us${{ item.amount }}</strong>
                            <span
                              v-if="item.discount > 0"
                              class="badge bg-primary-soft ms-2"
                              >{{ item.discount }}% off</span
                            >
                          </h5>
                        </div>
                        <div class="col-auto">
                          <span
                            v-if="item._id == form.creditPack"
                            class="h2 fas fa-check-circle mb-0 text-primary"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button 
                class="btn btn-primary w-100 mt-5"
                :disabled="isInvalid($v.form.creditAmount)" 
                @click="nextStep(3)"
              >
                Continue
              </button>
            </div>
            <!-- END STEP 1 -->

            <!-- STEP 2 -->
            <div v-if="step == 3">
              <p><strong> {{ isAnualPlan() ? '2' : '3' }}. Payment Details </strong></p>
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  v-model.trim="$v.form.paymentDetails.holder.$model"
                  :class="{ 'is-invalid': $v.form.paymentDetails.holder.$error }"
                  placeholder="Name on card"
                  class="form-control"
                />
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
              </div>

              <div class="form-group">
                <label>Card</label>
                <div class="input-group input-group-merge">
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.cardNumber.$model"
                    placeholder="Card number"
                    class="form-control"
                    :class="{ 'is-invalid': $v.form.paymentDetails.cardNumber.$error }"
                    v-mask="'#### #### #### ####'"
                  />
                  <span class="input-group-text">
                    <i class="fas fa-credit-card"></i>
                  </span>
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-6">
                  <label>Expiry</label>
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.expiration.$model"
                    :class="{ 'is-invalid': $v.form.paymentDetails.expiration.$error }"
                    placeholder="MM/YYYY"
                    v-mask="'##/####'"
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    <small>Please fill in this field correctly</small>
                  </div>
                </div>

                <div class="form-group col-6">
                  <label>CVV</label>
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.cvv.$model"
                    :class="{ 'is-invalid': $v.form.paymentDetails.cvv.$error }"
                    placeholder="123"
                    v-mask="'####'"
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    <small>Please fill in this field correctly</small>
                  </div>
                </div>
              </div>
              <button
                :disabled="$v.form.paymentDetails.$invalid || loading"
                class="btn btn-primary w-100 mt-5"
                @click="isAnualPlan() ? handleCreateDeposit() : nextStep(4)"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Pay Us$ {{ getFormatAmount(form.selectedPlan.amount) }}
              </button>
            </div>
            <!-- END STEP 2 -->

            <!-- STEP 3 -->
            <div v-if="step == 4">
              <p><strong> 4. Credit Usage </strong></p>
              <p>Please let us know who may use these credits you are purchasing:</p>
              <div class="form-group">
                <!-- <label>Name</label> -->
                <BaseFormRadioGroup
                  v-model.trim="$v.form.creditUsage.type.$model"
                  :options="[
                    {value: 'JUST-MYSELF', name: 'Just myself'},
                    {value: 'MYSELF-PLUS-THE-COLLEAGUES-BELOW', name: 'Myself plus the colleagues below'},
                  ]"
                />
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
              </div>

              <div
                v-if="form.creditUsage.type == 'MYSELF-PLUS-THE-COLLEAGUES-BELOW'"
                class="table-responsive mt-5 tab-pane"
                id="representatives"
              >
                <table
                  v-if="institutionRepresentatives.length > 0"
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in representativesColumns"
                        :key="column"
                        class="text-start"
                        :width="index == 0 ? '1%' : 'auto'"
                      >
                        <div v-if="index == 0">
                          <span>
                            <BaseFormCheckbox :checked="allSelected()" @click="selectAllRepresentatives()"/>
                          </span>
                        </div>
                        <div v-else>
                          {{ column }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in institutionRepresentatives"
                      v-bind:key="item"
                      class="cursor-pointer"
                    >
                      <td class="text-start">
                        <BaseFormCheckbox :checked="isSelected(item._id)" @click="selectRepresentative(item._id)"/>
                      </td>
                      <td class="d-flex text-start align-items-center">
                        <div
                          v-if="item.image"
                          id="name-badge"
                          class="rounded-circle d-flex justify-content-center align-items-center"
                        >
                          <img class="h-100 w-100 rounded-circle" :src="item.image" alt="">
                        </div>
                        <strong>{{item.name}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button
                class="btn btn-primary w-100 mt-5"
                @click.prevent="handleCreateDeposit()"
                :disabled="
                  (form.creditUsage.type == 'JUST-MYSELF' && isInvalid($v.form.creditUsage.type)) ||
                  (form.creditUsage.type == 'MYSELF-PLUS-THE-COLLEAGUES-BELOW' && !form.creditUsage.representatives.length)
                "
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Pay US${{ form.creditAmount }}
              </button>
            </div>
            <!-- END STEP 3 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditsController from "./AddCreditsController";
export default AddCreditsController;
</script>

<style lang="scss"  scoped>
.border-muted {
  border-color: #d9d9d9 !important;
}
#name-badge {
  width: 25px;
  height: 25px;
  background-color: gray;
  margin-right: 10px;
}
#checkbox {
  font-size: 15px;
  font-weight: 300;

  &.disabled{
    opacity: 0.5
  }

  .label {
    cursor: pointer;
    span {
      margin-right: 20px;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label span {
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox'] + label span:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -1px;
    left: -1px;
    border: solid 1px #979797;
    border-radius: 4px;
    background: white;
  }

input[type='checkbox'] {
  background: orange !important;
}
  input[type='checkbox']:checked + label span:before {
    border: none;
    background: orange !important;
  }

  input[type='checkbox']:checked + label span:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
  }
}
</style>
