<template>
  <div class="" v-if="post">
    <div class="card-body">
      <!-- Header -->
      <div class="mb-3">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <a href="#!" class="avatar w-100">
              <img
                v-if="post.institution && post.institution.image"
                :src="post.institution.image"
                alt="..."
                class="avatar-img rounded post-avatar"
              />
              <img
                v-else
                src="@/assets/img/no-img-placeholder.png"
                alt="..."
                class="avatar-img rounded post-avatar"
              />
            </a>
          </div>
          <div class="col ms-n2">
            <!-- Title -->
            <h4 class="mb-1 h4" v-if="post.institution">
              {{ post.institution.name }}
            </h4>

            <!-- Time -->
            <p
              v-if="
                post.institutionProfile &&
                post.institutionProfile[0] &&
                post.institutionProfile[0].institution.location
              "
              class="card-text small text-muted"
            >
              {{
                `${post.institutionProfile[0].institution.location.region}/${post.institutionProfile[0].institution.location.country}`
              }}
            </p>
            <p v-else class="card-text small text-muted">No location</p>
          </div>
          <div class="col" v-if="postIsMine">
            <div class="text-end">
              <button class="btn btn-outline-danger" @click.prevent="removePost()">
                <span class="fas fa-trash-alt"></span>
              </button>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>

      <!-- Image -->
      <p class="text-center mb-3">
        <img :src="post.image" alt="..." class="img-fluid rounded" />
      </p>

      <!-- Text -->
      <p class="mb-3">
        {{ post.description }}
      </p>
      <p class="card-text small text-muted">
        {{ formatDate(post.createdAt) }}
      </p>

      <!-- Divider -->
      <hr />

      <div class="actions">
        <div @click.prevent="openModalLikes" class="d-flex align-items-center d-column mb-3 me-4">
          <span class="far fa-heart ic-medium-size me-2 text-primary"></span>
          <h4 class="h4 mb-0 cursor-pointer">
            {{ post.likesCount }} {{ post.likesCount == 1 ? "Like" : "Likes" }}
          </h4>
        </div>
        <div @click.prevent="getComment()" class="d-flex align-items-center d-column mb-3">
        <span
            v-if="loadingComment"
            class="me-2 fa-2x text-primary ic-medium-size spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>
          <span v-else class="far ic-medium-size me-2 fa-comment text-primary"></span>
          <h4 class="h4 mb-0 cursor-pointer">
            {{ post.commentsCount }} {{ post.commentsCount == 1 ? "Comment" : "Comments" }}
          </h4>
        </div>
      </div>
      <!-- Comments -->
      <CommentComponent
          class="mb-3"
          v-for="(c, index) in comments"
          :comment="c"
          v-bind:key="'comments-' + index"
          :canReply="postIsMine"
          @commentAdded="() => post.commentsCount += 1"
          :followers="followers"
      />
      <hr />
      <!-- Modal Likes -->
      <ModalComponent title="Likes" :id="modalLikesId">
        <template v-slot:body>
          <div v-if="loadingLikes && paginationLikes.page === 1" class="d-flex justify-content-center">
            <span
                class="spinner-border spinner-border text-primary"
                role="status"
                aria-hidden="true"
            ></span>
          </div>
          <div v-else class="likes-container">
            <div v-if="noHasLikes" class="d-flex justify-content-center">
              No information to show
            </div>
            <div v-for="like in likes" v-bind:key="like._id" class="like">
              <div class="like__image">
                <ImagePlaceholder v-if="like.student.image" :src="like.student.image">
                  <template v-slot:placeholder>
                    {{ like.student.name.charAt(0) }}
                  </template>
                </ImagePlaceholder>
                <div
                    v-else
                    class="avatar-placeholder rounded-circle d-flex justify-content-center align-items-center"
                >
                  <h3 class="text-white mb-0">
                    {{ like.student.name.charAt(0) }}
                  </h3>
                </div>
              </div>
              <div class="like__title">
                <strong>
                  {{ getStudentName(like) }} <small class="text-muted">({{ like.student.profile.location.country }})</small>
                </strong>
              </div>
              <div class="like__profile ms-auto">
                <span
                  class="btn btn-link"
                  @click="openStudent(like)"
                >
                  See profile
                </span>
              </div>
            </div>
            <div v-if="haveMoreCommentsToLoad" class="likes-container__load-more">
              <button type="button" class="btn btn-primary" @click="loadMoreLikes" :disabled="loadingLikes">
                <span
                    v-if="loadingLikes"
                    class="spinner-border spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Load More
              </button>
            </div>
          </div>
        </template>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Modal } from "bootstrap";
import LoadingContainer from "../../components/LoadingContainer";
import CommentComponent from "./CommentComponent";

export default {
  name: "NewPostComponent",
  props: {
    post: {},
    postIsMine: {
      type: Boolean,
      default: false,
    },
    followers: {
      type: Array,
    },
  },
  components: { LoadingContainer, CommentComponent },
  data() {
    return {
      comments: [],
      loadingComment: false,
      likes: [],
      paginationLikes: {
        page: 1,
        limit: 15,
        showMore: false
      },
      loadingLikes: false
    };
  },
  computed: {
    noHasLikes() {
      return this.likes.length === 0;
    },
    modalLikesId() {
      return `modalLikes-${this.post._id}`
    },
    haveMoreCommentsToLoad() {
      return this.paginationLikes.showMore
    }
  },
  methods: {
    getStudentName(item) {
      let isFollower = this.followers.includes(item.student._id.toString())
      return isFollower ? item.student.name : item.student.profile.firstname
    },
    openStudent(item) {
      const newRoute = this.$router.resolve({ path: `${this.$paths.students.detail}/${item.student._id}` })
      window.open(newRoute.href, '_blank');
    },
    async getComment() {
      try {
        this.loadingComment = true;
        const res = await this.$socialService.getPostComments(this.post._id);
        this.$set(this, "comments", res.data.data);
      } catch (e) {
      } finally {
        this.loadingComment = false;
      }
    },
    formatDate(date) {
      return moment(date).format("MMM,YY HH:mm");
    },
    removePost() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want do delete this post?",
        confirmCallback: async () => {
          try {
            this.$socialService.deletePost(this.post._id);
            this.$eventBus.$emit("PostDeleted", this.post._id);
          } catch (e) {
            console.log(e);
          }
        },
      });
      // if (confirm("Are you sure you want do delete this post?")) {
      //   try {
      //     this.$socialService.deletePost(this.post._id);
      //     this.$eventBus.$emit("PostDeleted", this.post._id);
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    },
    openModalLikes() {
      this.paginationLikes.page = 1;

      const el = document.getElementById(this.modalLikesId);
      this.newPostModal = new Modal(el, {
        backdrop: 'static',
        keyboard: false
      });
      this.newPostModal.toggle();
      this.loadLikes();
    },
    loadMoreLikes() {
      this.paginationLikes.page += 1;
      this.loadLikes();
    },
    async loadLikes() {
      this.loadingLikes = true;
      try {
        const { page, limit } = this.paginationLikes;
        const { data } = await this.$socialService.getPostLikes(this.post._id, {
          page, limit
        });

        const likes = data.data
        const meta = data.meta

        if(meta.page === 1) {
          this.$set(this, "likes", likes);
          this.paginationLikes.total = meta.count;
        } else {

          likes.forEach((like) => {
            this.likes.push(like)
          })
        }

        this.paginationLikes.showMore = meta.page * meta.limit < meta.count;
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingLikes = false;
      }
    }
  },
};
</script>

<style scoped>
.ic-medium-size {
  font-size: 1.5em;
}

.actions {
  display: flex;
}

.likes-container {
  display: flex;
  flex-direction: column;
}

.likes-container__load-more {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.like {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.like__title {
  margin-left: 15px;
}
</style>
