import NetworkService from '@/services/NetworkService';

export default class FinancialService {
    constructor() {
        this.network = new NetworkService()
    }
    getDeposits(date) {
        const d = date || ''
        return this.network.get(`${this.network.endpoints.Financial.deposits}/${d}`)
    }
    getPacks() {
        return this.network.get(this.network.endpoints.Financial.packs)
    }
    getPlans() {
        return this.network.get(this.network.endpoints.Financial.plans)
    }
    createDeposit(params) {
        params.paymentDetails.cardNumber = params.paymentDetails.cardNumber.replace(/ /g,'')
        return this.network.post(this.network.endpoints.Financial.deposits, params)
    }
    createSubscription(params) {
        params.paymentDetails.cardNumber = params.paymentDetails.cardNumber.replace(/ /g,'')
        return this.network.post(this.network.endpoints.Financial.subscriptions, params)
    }
    createRepresentativeDeposit(params) {
        params.paymentDetails.cardNumber = params.paymentDetails.cardNumber.replace(/ /g,'')
        return this.network.post(this.network.endpoints.Financial.representative.deposits, params)
    }
    getPurchases(date) {
        return this.network.get(`${this.network.endpoints.Financial.purchases}/${date}`)
    }
    
}
