import NetworkService from '@/services/NetworkService';
import store from '@/store'
export default class UserService {
    constructor() {
        this.network = new NetworkService()
    }
    notificationIcons = {
        'APPOINTMENT_REQUEST': 'fa-calendar-day',
        'APPOINTMENT_ACCEPTED': 'fa-calendar-day',
        'APPOINTMENT_REJECTED': 'fa-calendar-day',
        'CREDITS_PURCHASE': 'fa-coins',
        'POST_COMMENT': 'fa-comments',
        'FOLLOWER_REQUEST': 'fa-user-plus',
        'REQUEST_ACCEPT': 'fa-user-plus',
        'STUDENT_START_CHAT': 'fa-comments',
    }
    register(params) {
        return this.network.post(this.network.endpoints.User.register, params)
    }
    validateEmail(accessToken) {
        return this.network.get(this.network.endpoints.User.validateEmail, accessToken)
    }
    async signin(params) {
        return this.network.post(`${this.network.endpoints.User.signin}`, params)
    }

    async signinWithToken(params) {
        return this.network.post(`${this.network.endpoints.User.signinWithToken}`, params)
    }

    forgotPassword(params) {
        const url = `${this.network.endpoints.User.forgotPassword}`
        return this.network.post(url, params)
    }
    recoverPassword(params, token) {
        const url = `${this.network.endpoints.User.forgotPassword}/${token}`
        return this.network.put(url, params)
    }
    getAccount() {
        const url = `${this.network.endpoints.User.account}`
        return this.network.get(url)
    }
    updateUser(id, params) {
        const url = `${this.network.endpoints.User.account}/${id}`
        return this.network.put(url, params)
    }
    updatePassword(params) {
        const url = `${this.network.endpoints.User.updatePassword}`
        return this.network.put(url, params)
    }
    deleteUserAccount() {
        const url = `${this.network.endpoints.User.account}`
        return this.network.delete(url)
    }
    getNotifications(type) {
        return this.network.get(this.network.endpoints.Notifications)
    }
    activateAccount(token) {
        return this.network.put(this.network.endpoints.User.accountActivation, { token })
    }
    updateAccount(params) {
        return this.network.put(this.network.endpoints.User.account, params)
    }
    resendEmail(email) {
        return this.network.post(this.network.endpoints.User.resendEmail, { email })
    }
    getInstitutionRepresentatives(includeCurrentUser = false) {
        const url = `${this.network.endpoints.User.institutionRepresentatives}/${includeCurrentUser}`;
        return this.network.get(url);
    }
    getUserSubscription() {
        return this.network.get(this.network.endpoints.User.subscription)
    }
}
