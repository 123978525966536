<template>
  <div ref="vonageShareScreen" class="vonage-share-screen"></div>
</template>

<script>
import OT from '@opentok/client'

const checkScreenSharingCapability = () => {
  return new Promise((resolve, reject) => {
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        return reject({
          message: 'This browser does not support screen sharing.',
        })
      }

      if (response.extensionInstalled === false) {
        return reject({
          message: 'Please install the screen-sharing extension.',
        })
      }

      resolve()
    })
  })
}

const sessionPublish = (vonageSession, publisher) => {
  return new Promise((resolve, reject) => {
    vonageSession.publish(publisher, function (error) {
      if (error) {
        return reject(error)
      }

      resolve()
    })
  })
}

const initPublisher = (vonageShareScreen) => {
  return new Promise((resolve, reject) => {
    const publisher = OT.initPublisher(
      vonageShareScreen,
      {
        insertMode: 'append',
        videoSource: 'screen',
        width: '100%',
        height: '100%',
        publishAudio: true,
        showControls: false,
      },
      (error) => {
        if (error) {
          return reject(error)
        }

        return resolve(publisher)
      }
    )
  })
}

export default {
  name: 'VonageShareScreen',

  props: {
    vonageSession: {
      type: OT.Session,
      required: true,
    },
  },

  async mounted() {
    let publisher = null

    try {
      await checkScreenSharingCapability()

      publisher = await initPublisher(this.$refs.vonageShareScreen)

      if (publisher) await sessionPublish(this.vonageSession, publisher)
    } catch (e) {
      let message = null

      if (e.name && e.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
        message = 'Your browser is not allowed to share the screen.'
      } else {
        message = e.message || 'Sorry, unable to share the screen.'
      }

      this.$emit('error', message)
    }

    this.$emit('publish', publisher)
  },
}
</script>

<style lang="scss" scoped>
.vonage-share-screen {
  height: auto;
  /deep/.OT_publisher {
    border-radius: 10px;
    height: 100%;
  }
}
</style>
