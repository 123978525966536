<template>
  <div>
    <Sidebar />
    <NavBar current="Member Detail" :link="$paths.team.list" linkName="Team" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-8" v-else>
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col-xs-12 col-lg-8" style="min-width:90px;">
              <div v-if="!isEditing" class="align-items-center">
                <div class="row">
                  <div class="col-auto">
                    <div class="avatar avatar-xl float-start">
                      <img
                        class="avatar-img rounded-circle"
                        :src="form.image"
                        alt="..."
                      />
                    </div>
                    <div class="float-start mt-4 ms-2">
                      <h6 class="header-pretitle">Member Detail</h6>
                      <h1 class="header-title">{{ form.firstname }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="align-items-end">
                <div class="col-auto">
                  <div class="avatar avatar-xl">
                    <img
                      :src="form.image"
                      alt="..."
                      id="profile-img"
                      class="avatar-img rounded-circle border-white"
                    />
                    <span
                      v-if="loadingMemberImg"
                      class="spinner-border spinner-border-sm text-primary"
                      role="status"
                      style="
                        bottom: -4px;
                        right: -5px;
                        position: absolute;
                        z-index: 20;
                        width: 30px;
                        height: 30px;
                      "
                      aria-hidden="true"
                    ></span>
                    <span
                      v-else
                      class="fas fa-pen text-primary"
                      style="
                        bottom: -4px;
                        right: -5px;
                        position: absolute;
                        font-size: 1.3rem;
                        z-index: 20;
                      "
                    ></span>
                    <input
                      ref="input"
                      type="file"
                      name="image"
                      accept="image/*"
                      style="left: 0; z-index: 21"
                      @change="setMemberImage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-4 d-flex justify-content-end">
              <button class="btn btn-outline-secondary me-3" @click="deleteMember">
                <i class="fas fa-trash"></i> Delete
              </button>
              <button v-if="isEditing" class="btn btn-link text-primary" @click="disableEditing()">Cancel editing</button>
              <button v-else class="btn btn-primary" @click="enableEditing()"><i class="fas fa-pencil-alt"></i> Edit</button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs nav-overflow header-tabs">
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab = 0"
                    :class="{ active: selectedTab == 0 }"
                    id="info-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#info"
                    type="button"
                    role="tab"
                    aria-controls="info"
                    aria-selected="true"
                  >
                    Basic info
                  </a>
                </li>
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab = 1"
                    :class="{ active: selectedTab == 1 }"
                    id="territories-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#territories"
                    type="button"
                    role="tab"
                    aria-controls="territories"
                    aria-selected="true"
                  >
                    Territories
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="row justify-content-between align-items-center mb-5">
          <div class="tab-content" id="myTabContent">
            <!-- BASIC INFO -->
            <div
              class="table-responsive mt-2 tab-pane fade overflow-hidden"
              :class="{'show active': selectedTab == 0}"
              id="info"
              role="tabpanel"
              aria-labelledby="info-tab"
            >
              <div class="col">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label class="form-label"> {{labels.firstname}} </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.firstname"
                        :disabled="!isEditing"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label class="form-label"> {{labels.lastname}} </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.lastname"
                        :disabled="!isEditing"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                      <label class="form-label"> Position </label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="form.position"
                        :disabled="!isEditing"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="form-label"> Email </label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="form.email"
                        :disabled="!isEditing"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- TERRITORIES -->
            <div 
              id="territories"
              role="tabpanel"
              aria-labelledby="territories-tab"
              class="table-responsive mt-2 tab-pane fade overflow-hidden"
              :class="{'show active': selectedTab == 1}"
            >
              <label class="form-label">Responsible for the following territories:</label>
              <div class="row" v-if="isEditing">
                <div class="col-6">
                  <div class="form-group"
                    :label="labels.territory"
                    label-for="territory"
                  >
                    <BaseFormInputLocation
                      id="territory"
                      v-model="region"
                      :value="region"
                      :types="['(regions)']"
                      placeholder="Enter the name of the country or region"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    size="sm"
                    class="btn btn-sm btn-primary"
                    @click.prevent="addTerritory(region)"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div class="row" v-if="territories.length > 0">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <ul class="list-group list-group-horizontal territories-list" style="display: inline-block;">
                      <li class="list-group-item mt-3" style="display: inline-block;position:relative;" v-for="item in territories" v-bind:key="item.id">
                        {{ toLocationFormat(item) }} 
                        <span
                          v-if="isEditing"
                          class="cursor-pointer fas fa-times text-danger ms-2"
                          @click.prevent="removeTerritory(item.id)"
                        >
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else-if="!isEditing">
                <span class="fw-bold">No territory selected. Click Edit to add.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isEditing">
          <div class="col-12">
            <button
              size="sm"
              class="btn w-100 btn-primary my-3"
              @click.prevent="saveMember"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Container>
    <ModalComponent
      title="Crop Image"
      description="To ensure the most professional look, please try and use a logo with 1:1 (square) dimensions"
      id="memberImageModal"
    >
      <template v-slot:body>
        <vue-cropper
          :modal="false"
          :movable="false"
          :zoomable="false"
          :original="true"
          :background="true"
          ref="cropperMember"
          :src="imgSrc"
          alt="Source Image"
          :viewMode="2"
          :aspect-ratio="1"
          :minContainerWidth="550"
          :minContainerHeight="$helper.cropMinHeight"
          :aspectRatio="1/1"
          :initialAspectRatio="1/1"
        >
        </vue-cropper>
      </template>
      <template v-slot:footer>
        <button type="button" @click="cropMemberImage" class="btn btn-primary">
          Crop
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import MemberDetailController from "./MemberDetailController";
export default MemberDetailController;
</script>

<style lang="scss">
#profile-img {
  -webkit-box-shadow: 5px 5px 15px -6px #000000;
  box-shadow: 5px 5px 15px -6px #000000;
}
.avatar-img {
  /* width: 200px; */
}

.header-avatar-top {
  width: 100%;
}
input[type="file"] {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.card-programs {
  max-width: 178px;
  min-height: 210px;
  background-color: #F2F2F2;
  .card-body {
    padding-top: 0px;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }
  .card-header {
    border: none;
    padding: none;
    height: 8px;
    padding-right: 6px;
  }
}

.programs-list {
  margin-top: 25px;

  li {
    background-color: #F2F2F2;
    width: 180px;
    height: 210px;
    padding: 20px;
    border-radius: 10px !important;
    margin-right: 15px;
    font-weight: bold;
    color: #434040;
    border: none;
  }
}

.territories-list {
  margin-top: 25px;

  li {
    background-color: #F2F2F2;
    padding: 7px;
    border-radius: 10px !important;
    margin-right: 15px;
    font-weight: bold;
    color: #434040;
    border: none;
  }
}
</style>