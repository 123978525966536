<template>
  <div class="row">
    <div class="col-12">
      <div class="header mt-md-4">
        <div class="header-body" v-if="!isModal">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">New Post</h1>
              <p>Tell students something you want to share</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label">Post description</label>
          <input
            type="text"
            v-model.trim="$v.description.$model"
            :class="{ 'is-invalid': $v.description.$error }"
            placeholder="Enter description here"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please fill in this field correctly</small>
          </div>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Upload Photo</label>
          <ImageCropper v-on:cropEnd="cropEnd" :aspectRatio="16/9" />
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>
        <div class="d-grid gap-2 mt-4">
          <button
            class="btn btn-primary mt-3"
            @click="save"
            :disabled="$v.$invalid || !fileImage || loading"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import "cropperjs/dist/cropper.css";
import ImageCropper from "@/components/ImageCropper/ImageCropper";
export default {
  props: {
    isModal: Boolean,
  },
  mounted() {},
  data() {
    return {
      loading: false,
      errorMessage: "",
      fileImage: "",
      description: "",
      events: [
        { id: "campus", label: "Campus" },
        { id: "students", label: "Students" },
        { id: "class_room", label: "Class Room" },
      ],
      percentage: 0,
      uploading: false,
      counter: null,
    };
  },
  validations: {
    description: { required },
  },
  components: {
    ImageCropper,
  },
  methods: {
    cropEnd(image) {
      this.fileImage = image;
    },
    async save() {
      this.errorMessage = ""
      this.loading = true;
      try {
        const res = await this.$socialService.createPost({
          description: this.description,
          image: this.fileImage,
          timezone: this.$moment.tz.guess(),
        });
        this.$eventBus.$emit("clearImageCropper")
        this.fileImage = "";
        this.description = "";
        this.$eventBus.$emit("showToast", {
          title: "New Post published",
          msg: `Post was created successfully.`,
        });
        this.$emit("newPostCreated", {});
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  height: 200px;
  width: 724px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.form-control-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s;
  width: 100%;
  height: 200px;
  border: 2px #ecebeb dashed;
}
.form-control-custom .text {
  position: absolute;
  color: #a19f9f;
  text-align: center;
}
</style>
