var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Sidebar'),_c('NavBar',{attrs:{"current":"Messages"}}),(_vm.loadingAll)?_c('LoadingContainer'):_c('Container',{attrs:{"col":"col-12"}},[_c('div',{staticClass:"header mt-md-5"},[_c('div',{staticClass:"header-body border-0"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"header-title"},[_vm._v("Messages")]),_c('h4',{staticClass:"header-subtitle"},[_vm._v(" View & manage your messages with students ")])])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"nav nav-tabs header-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{staticClass:"nav-link active",attrs:{"id":"members-tab","data-bs-toggle":"tab","data-bs-target":"#members","type":"button","role":"tab","aria-controls":"members","aria-selected":"true"},on:{"click":function($event){$event.preventDefault();_vm.selectedTab = 0}}},[_vm._v(" All Messages "),_c('span',{staticClass:"ms-auto badge",class:{
                      'bg-primary-soft': _vm.selectedTab == 0,
                      'bg-secondary-soft': _vm.selectedTab != 0,
                    }},[_vm._v(_vm._s(_vm.items.length))])])])])])])])]),_c('div',{staticClass:"search-table-form"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('form',[_c('div',{staticClass:"input-group input-group-flush input-group-merge input-group-reverse"},[_c('input',{staticClass:"form-control list-search",attrs:{"type":"search","placeholder":"Search by student name"},on:{"input":_vm.searchString}}),_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fas fa-search ps-3"})])])])])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"table-responsive mt-5 tab-pane fade show active",attrs:{"id":"requests","role":"tabpanel","aria-labelledby":"requests-tab"}},[(_vm.items.length == 0)?_c('EmptyState',{attrs:{"title":"No Messages","msg":"It looks like you have no messages"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fas fa-comments fa-3x text-primary"})]},proxy:true}],null,false,2391802383)}):_vm._e(),(_vm.items.length > 0)?_c('table',{staticClass:"table table-sm table-hover table-nowrap card-table bg-white"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"1%"}}),_vm._l((_vm.columns),function(column,index){return _c('th',{key:column,class:{ 'text-start': [0, 1].includes(index) },attrs:{"width":index != 0 ? '1%' : 'auto'}},[_vm._v(" "+_vm._s(column)+" ")])}),_c('th',{attrs:{"width":"1%"}})],2)]),_c('tbody',{staticClass:"list"},_vm._l((_vm.showingItems),function(item){return _c('tr',{key:item.id,staticClass:"cursor-pointer",class:{
                'bg-secondary-soft': _vm.lastMessageNotRead(item.lastMessage),
              }},[_c('td',{staticClass:"text-start",on:{"click":function($event){return _vm.itemPressed(item)}}},[(item.student.image)?[_c('ImagePlaceholder',{attrs:{"src":item.student.image,"online":item.online},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" "+_vm._s(item.studentName.charAt(0))+" ")]},proxy:true}],null,true)})]:[_c('div',{staticClass:"avatar-placeholder rounded-circle d-flex justify-content-center align-items-center"},[_c('h3',{staticClass:"text-white mb-0"},[_vm._v(" "+_vm._s(item.studentName.charAt(0))+" ")])])]],2),_c('td',{staticClass:"text-start",on:{"click":function($event){return _vm.itemPressed(item)}}},[_c('strong',[_vm._v(_vm._s(item.studentName))])]),_c('td',{staticClass:"text-start",on:{"click":function($event){return _vm.itemPressed(item)}}},[(item.student.location)?_c('span',[_vm._v(" "+_vm._s(((item.student.location.city) + ", " + (item.student.location.region) + ", " + (item.student.location.country)))+" ")]):_vm._e()]),_c('td',{staticClass:"strong",on:{"click":function($event){return _vm.itemPressed(item)}}},[(item.lastMessage.length > 0)?_c('span',[_c('span',{class:{
                      'fw-bold text-black': _vm.lastMessageNotRead(
                        item.lastMessage
                      ),
                    }},[_vm._v(" "+_vm._s(_vm.$helper.limitString(item.lastMessage[0].message))+" ")]),(_vm.lastMessageViewed(item.lastMessage))?_c('span',{staticClass:"text-blue-link"},[_c('small',{staticClass:"fas fa-check"}),_c('small',{staticClass:"fas fa-check",staticStyle:{"left":"-9px","position":"relative","right":"0px","top":"1px"}})]):_vm._e()]):_vm._e()]),_c('td',{on:{"click":function($event){return _vm.itemPressed(item)}}},[_c('span',{staticClass:"ms-auto badge bg-primary-soft"},[_vm._v(_vm._s(item.match || 0)+"%")])])])}),0)]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }