<template>
  <div class="location-field">
    <input
      class="form-control"
      ref="autocomplete"
      id="autocomplete"
      type="text"
      :placeholder="placeholder"
      :value="autocompleteValue"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  name: 'BaseFormInputLocation',

  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    types: {
      type: Array,
      default: function() {
        return ['(cities)']
      }
    }
  },

  data() {
    return {
      autocomplete: '',
      autocompleteValue: ''
    }
  },

  methods: {
    formatValue(place) {
      return [
        place.city,
        place.region,
        place.country
      ].filter(Boolean).join(', ')
    },

    initPlaces() {
      if (this.value && this.value.country) {
        this.autocompleteValue = this.formatValue(this.value)
      }

      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs['autocomplete'],
        { types: Object.values(this.types) }
      )

      this.autocomplete.setFields(['address_component', 'geometry'])

      this.autocomplete.addListener('place_changed', () => {
        const places = this.autocomplete.getPlace()
        const place = {}

        places.address_components.forEach((item) => {
          switch (item.types[0]) {
            case 'country':
              place.country = item.long_name
              place.countryInitials = item.short_name
              break

            case 'administrative_area_level_1':
              place.region = item.long_name
              break

            case 'locality':
              place.city = item.long_name

              // for a city-state like Monaco or Singapore, we don't have the
              // administrative_area_level_1, Google only returns country and locality,
              // so the last should be used as region as well.
              if (!place.hasOwnProperty('region')) {
                place.region = item.long_name
              }

              break

            case 'administrative_area_level_2':
            case 'colloquial_area':
              if (!place.hasOwnProperty('city')) {
                place.city = item.long_name
              }
              break
          }
        })

        if (places.geometry.location) {
          place.coordinates = {}
          place.coordinates.lat = places.geometry.location.lat()
          place.coordinates.lng = places.geometry.location.lng()
        }

        this.autocompleteValue = this.formatValue(place)

        this.$emit('input', place)
      })
    }
  },

  async mounted() {
    await new Promise((resolve) => {
      const scriptId = 'google-place-autocomplete'
      // CHECK IF SCRIPT HAS BEEN ADDED IN CASE OF MULTIPLES COMPONENTS IN ONE VIEW
      let mapAttached = !!document.getElementById(scriptId)
      if (mapAttached) {
        const self = this
        // TODO: remove GooglePlaceAutocomplete file and this timeout gambiarra
        // Best way would be a plugin that initialize places.Autocomplete using xpath class selector and not refs, then thi init would be called just once in script onload()
        setTimeout(function(){
        self.initPlaces()

        }, 3000);
        return resolve()
      }

      // CREATE AND ATTACH SCRIPT ELEMENT
      const js = document.createElement('script')
      js.id = scriptId
      js.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&language=en`
      document.body.appendChild(js)
      js.onload = () => {
        // INITIALIZE LISTENER AND LOAD SAVED VALUES
        this.initPlaces()
        return resolve()
      }
    })
  },

  watch: {
    value(value) {
      if (value && value.country) {
        this.autocompleteValue = this.formatValue(value)
      } else if (!value) {
        this.autocompleteValue = ''
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.location-field {
  width: 100%;
  .simpleInput {
    // @extend %form-field;
    width: 100%;
    height: 32px;
    border-radius: 0.25rem;
    padding: 6 12px;
  }
}
</style>
