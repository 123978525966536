<template>
  <div class="d-inline-flex align-items-center">
    <div class="info-content">
      <i
        class="fa fa-info-circle me-2"
        aria-hidden="true"
        @mouseover="setShowInfo(true)"
        @mouseleave="setShowInfo(false)"
      />
      <p
        v-show="showInfo"
        class="info-content-text bg-dark text-white rounded p-3"
      >
        Check & edit your FULL institutional profile. Whatever you update/save there will be reflected here automatically. Remember, your profile info is what influences the unique % match you get for each student, so make sure it's always up to date & accurate!
      </p>
    </div>
    <button
      :disabled="loadingToken"
      class="btn btn-xs btn-outline-primary me-2"
      @click="goToHub()"
    >
      <div class="d-flex justify-content-center align-items-center">
        <i v-if="!loadingToken" class="fas fa-user me-2"></i>
        <span
          v-else
          class="spinner-border spinner-border-sm text-primary me-2"
          role="status"
          aria-hidden="true"
        />
        Edit your FULL profile
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonEditProfile',

  props: {},

  data() {
    return {
      loadingToken: false,
      showInfo: false
    }
  },

  methods: {
    async goToHub() {
      this.loadingToken = true

      try {
        const { data } = await this.$tokenService.getTokenUpdateProfile()
        const url = data.data && data.data.url ? data.data.url : ''

        window.open(url, '_blank');
      } catch (error) {
        console.error('[GoToHub]', error)
      } finally {
        this.loadingToken = false
      }
    },

    setShowInfo(val) {
      this.showInfo = val
    }
  }
}
</script>

<style lang="scss" scoped>
.info-content {
  position: relative;

  &-text {
    position: absolute;
    width: 400px;
    top: 35px;
    left: -100px;

    &::before,
    &::after {
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #43403B;
      position: absolute;
      content: '';
      top: -14px;
      left: 94px;
    }

    &:before {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      top: -16px;
      left: 93px;
    }
  }
}
</style>
