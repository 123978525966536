<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div :class="col">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    col: String,
  },
};
</script>

<style>
</style>