<template>
  <div>
    <Container col="col-12 col-lg-4 col-xl-4">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Password recovery</h6>
              <h1 class="header-title">Please, type your new password</h1>
            </div>
            <div class="col-auto"></div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label">New Password</label>
          <input
            type="password"
            v-model.trim="$v.form.password.$model"
            :class="{ 'is-invalid': $v.form.password.$error }"
            placeholder="Enter old password"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please fill in this field correctly</small>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Confirm new password</label>
          <input
            type="password"
            v-model.trim="$v.form.passwordConfirmation.$model"
            :class="{ 'is-invalid': $v.form.passwordConfirmation.$error }"
            placeholder="Enter new password"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Passwords must match</small>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button
            class="btn btn-primary mt-3"
            @click="changePassword"
            :disabled="$v.$invalid || loading"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Change
          </button>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import PasswordRecoveryController from "./PasswordRecoveryController";
export default PasswordRecoveryController;
</script>

<style scoped>
.main-content {
  margin-left: 0;
}
</style>
