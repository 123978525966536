<template>
  <div>
    <Sidebar />
    <NavBar current="Upload Photo" :link="$paths.university.list" linkName="Resources" />

    <Container col="col-12 col-lg-10 col-xl-8">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">Upload Photo</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- <div class="form-group">
          <label class="form-label">Title</label>
          <input
            ref="titleInput"
            type="text"
            v-model.trim="$v.form.title.$model"
            :class="{ 'is-invalid': $v.form.title.$error }"
            placeholder="Name"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please fill in this field correctly</small>
          </div>
        </div> -->

        <div class="form-group">
          <label for="" class="form-label">Album</label>
          <div class="d-grid gap-2">
            <button
              id="btnGroupDrop1"
              type="button"
              class="btn btn-white dropdown-toggle text-start d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="
                border: 1px solid #d0cfcf;
                border-radius: 0.375rem !important;
                font-weight: 400 !important;
              "
            >
              {{ eventLabel }}
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="btnGroupDrop1"
              :style="{ width: customSelectWidth + 'px' }"
            >
              <li
                class="dropdown-item text-dark cursor-pointer"
                @click="openNewAlbumModal()"
              >
                <i class="fas fa-plus-circle me-2"></i>
                <strong>Create Album</strong>
              </li>
              <li
                class="dropdown-item cursor-pointer"
                v-for="(e, i) in albums"
                :key="i"
                @click="setCollection(e)"
              >
                {{ e.title }}
              </li>
            </ul>
          </div>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Upload Photo</label>
          <ImageCropper
            :customFileInputWidth="customFileInputWidth"
            :aspectRatio="16/9"
            v-on:cropEnd="cropEnd"
          />
        </div>

        <div class="form-group">
          <p>
            Add photo albums that will be available to students in the <strong>NOVVA Connect APP</strong>.
            <br />
            <strong>IMPORTANT:</strong> For faster navigation please  ensure that all photos are smaller than 2MB.
            Resize your photos at www.reduceimages.com. 
          </p>
        </div>

        <div class="row">
          <div
            v-for="(e, i) in form.imageArray"
            :key="i"
            class="col-3 text-center mb-3 position-relative"
          >
            <div class="img-preview-container position-absolute">
              <button class="btn btn-dark opacity-75" @click.prevent="removeImage(i)">
                <span class="fas fa-trash"></span>
              </button>
            </div>
            <img :src="e" alt="" class="image-preview rounded" />
          </div>
          <div v-if="loadingPic" class="col-3 text-center mb-3 position-relative">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
            ></span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>

        <div class="d-grid gap-2 mt-4">
          <button
            class="btn btn-primary mt-3"
            @click="save"
            :disabled="$v.form.$invalid || loading || !form.imageArray.length"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Save and continue
          </button>
        </div>
      </div>
    </Container>

    <ModalComponent id="weekdays_modal" title="New Album">
      <template v-slot:body>
        <!-- <p>
          <strong>Creat</strong>
        </p> -->
        <form>
          <div class="form-group">
            <label class="form-label"> Album Name </label>
            <input
              id="newCollectionInput"
              class="form-control"
              placeholder="Album name"
              v-model.trim="$v.newCollectionTitle.$model"
              :class="{ 'is-invalid': $v.newCollectionTitle.$error }"
              type="text"
            />
            <div class="invalid-feedback">
              <small>Please fill in this field correctly</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{ newCollectionErrorMessage }}</span>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              :disabled="loadingCreatingCollection || $v.newCollectionTitle.$error"
              class="btn btn-primary mt-5"
              @click.prevent="createCollection()"
            >
              <span
                v-if="loadingCreatingCollection"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Done
            </button>
          </div>
        </form>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AddPhotoController from "./AddPhotoController";
export default AddPhotoController;
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  height: 200px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.form-control-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s;
  width: 100%;
  height: 200px;
  border: 2px #ecebeb dashed;
}
.form-control-custom .text {
  position: absolute;
  color: #a19f9f;
  text-align: center;
}
.btn-white:hover,
.btn-white:focus {
  background-color: #fff;
  border-color: #ed6125 !important;
}
.image-preview {
  width: 100% !important;
  max-height: 150px;
}
</style>
