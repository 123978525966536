<template>
  <div class="row justify-content-center align-items-center">
    <div class="col-12 col-md-5 col-xl-4 my-5">
      <div class="text-center">
        <!-- Heading -->
        <h1 class="display-4 mb-3">Meeting ended</h1>

        <!-- Subheading -->
        <p class="text-muted mb-4">You left the meeting</p>

        <!-- Button -->
        <router-link tag="a" :to="$paths.home" class="btn btn-lg btn-primary">
          Return to home
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetingEnded",
};
</script>
