<template>
  <div class="checkbox" :class="disabled ? 'disabled' : ''">
    <input type="checkbox" :id="id" :checked="checked" :disabled="disabled" v-on="$listeners" />
    <label :for="id" class="label">
      <span></span>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.id = this._uid
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  font-size: 15px;
  font-weight: 300;

  &.disabled{
    opacity: 0.5
  }

  .label {
    cursor: pointer;
    span {
      margin-right: 20px;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label span {
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox'] + label span:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -1px;
    left: -1px;
    border: solid 1px #979797;
    border-radius: 4px;
    background: white;
  }

  input[type='checkbox']:checked + label span:before {
    border: none;
    background: #FE5101;
  }

  input[type='checkbox']:checked + label span:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
  }
}
</style>
