import TimeCounter from "@/components/TimeCounter/TimeCounter";
import "cropperjs/dist/cropper.css";
import OT from "@opentok/client";
import VonageShareScreen from "@/components/Vonage/VonageShareScreen";
import VonagePublisher from "@/components/Vonage/VonagePublisher";
import VonageSubscriber from "@/components/Vonage/VonageSubscriber";
import MeetingEnded from "@/components/Meeting/MeetingEnded";

export default {
  mounted() {
    window.onbeforeunload = function () {
      this.disconnectVonageSession();
    };
    this.checkAppointmentToken();
    this.getAppointment();
  },

  destroyed() {
    this.disconnectVonageSession();
  },

  data() {
    return {
      vonageSession: null,
      vonagePublisher: null,
      vonageStream: null,
      vonageSubscriber: null,
      vonageShareScreen: null,
      vonageToken: "",
      vonageSessionId: "",
      vonageApiKey: "",
      appointment: {},
      initialMinutes: 0,
      initialSeconds: 0,
      meetingStartTime: this.$moment(),
      studentName: "",
      hostLeft: false,
      myControls: {
        videoEnabled: true,
        micEnabled: true,
        screenShareEnabled: false,
      },
      guestControls: {
        videoEnabled: true,
        micEnabled: true,
      },
      waitingGuest: true,
      showCounter: false,
    };
  },

  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },

  components: {
    TimeCounter,
    VonageShareScreen,
    VonagePublisher,
    VonageSubscriber,
    MeetingEnded,
  },
  methods: {
    async getAppointment() {
      try {
        const res = await this.$agendaService.showAppointment(
          this.$route.params.id
        );
        let it = res.data.data;
        this.$set(this, "studentName", it.student.name);
        this.$set(this, "appointment");
        if (it.status != "ACCEPTED") {
          this.$router.push({ path: "/not-found" });
        }
      } catch (e) {
        console.log(e);
      } finally {
      }
    },

    async checkAppointmentToken() {
      try {
        this.loadingCheck = true;

        const { data } = await this.$agendaService.createVonageToken(
          this.$route.params.id
        );
        this.vonageToken = data.data.vonageToken;
        this.vonageSessionId = data.data.vonageSessionId;
        this.vonageApiKey = data.data.vonageApiKey;

        this.meetingStartTime = this.$moment();
        let totalSeconds = this.$moment().diff(this.meetingStartTime, "s");
        const s = parseInt(totalSeconds % 60);
        const m = parseInt(totalSeconds / 60);
        this.initialSeconds = s.toString();
        this.initialMinutes = m.toString();

        this.setupVonage();

        this.showCounter = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingCheck = false;
      }
    },

    setupVonage() {
      this.vonageSession = OT.initSession(
        this.vonageApiKey,
        this.vonageSessionId
      );
      this.vonageSession.connect(this.vonageToken, async (err) => {
        if (err) {
          console.error("sessionConnect", err);
        }
      });

      this.setupVonageCallbacks();
    },

    setupVonageCallbacks() {
      this.vonageSession
        .on("sessionConnected", () => {
          try {
            this.$agendaService.connectSessionVonage(this.$route.params.id);
          } catch (e) {
            console.error("sessionConnected", e);
          }
        })
        .on("sessionDisconnected", ({ reason }) => {
          if (reason !== "clientDisconnected") {
            this.disconnectVonageSession();
          }
        })
        .on("streamCreated", ({ stream }) => {
          this.vonageStream = stream;
          this.guestControls.micEnabled = stream.hasAudio;
          this.guestControls.videoEnabled = stream.hasVideo;
        })
        .on("streamDestroyed", () => {
          this.vonageStream = null;
          this.waitingGuest = true;
          this.guestControlsInit();
        })
        .on(
          "streamPropertyChanged",
          ({ changedProperty, newValue, stream }) => {
            if (
              this.vonagePublisher.stream?.connection?.connectionId ==
              stream.connection.connectionId
            ) {
              return;
            }

            if (changedProperty === "hasAudio") {
              this.guestControls.micEnabled = newValue;
            } else if (changedProperty === "hasVideo") {
              this.guestControls.videoEnabled = newValue;
            }
          }
        );
    },

    toggleVideo() {
      try {
        this.myControls.videoEnabled = !this.myControls.videoEnabled;
        this.vonagePublisher.publishVideo(this.myControls.videoEnabled);
      } catch (e) {
        console.log("toggleVideo", e);
      }
    },

    toggleMic() {
      try {
        this.myControls.micEnabled = !this.myControls.micEnabled;
        this.vonagePublisher.publishAudio(this.myControls.micEnabled);
      } catch (e) {
        console.log("toggleMic", e);
      }
    },

    toggleShareScreen() {
      if (!this.myControls.screenShareEnabled) {
        this.myControls.screenShareEnabled = true;
      } else {
        this.myControls.screenShareEnabled = false;
        if (this.vonageShareScreen) {
          this.vonageSession.unpublish(this.vonageShareScreen);
        }
      }
    },

    async leaveMeeting() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want to leave the meeting?",
        confirmCallback: async () => {
          this.disconnectVonageSession();
          this.hostLeft = true;
          await this.$agendaService.completeAppointment(this.$route.params.id);
        },
      });
    },

    disconnectVonageSession() {
      if (this.vonageSession) {
        this.vonageSession.disconnect();
        this.vonageSession = null;
      }
    },

    vonageShareScreenErrorHandler(msg) {
      this.myControls.screenShareEnabled = false;
      this.vonageErrorHandler(msg);
    },

    vonageErrorHandler(msg) {
      this.$eventBus.$emit("showToast", {
        title: "Error",
        msg,
      });
    },

    async vonageShareScreenHandler(data) {
      if (data) {
        this.vonageShareScreen = data;

        this.vonageShareScreen.on({
          destroyed: async () => await this.vonageShareScreenDestroyed(),
        });
      } else {
        await this.vonageShareScreenDestroyed();
      }
    },

    async vonageShareScreenDestroyed() {
      this.shareScreenStatus = false;
      this.shareScreenPublish = null;
    },

    async vonagePublisherHandler(data) {
      this.vonagePublisher = data;
    },

    async vonageSubscriberHandler(data) {
      this.vonageSubscriber = data;
      this.waitingGuest = false;
    },

    guestControlsInit() {
      this.guestControls.micEnabled = true;
      this.guestControls.videoEnabled = true;
    },
  },
};
