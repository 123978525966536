import { render, staticRenderFns } from "./AddBrochure.vue?vue&type=template&id=fb952632&scoped=true&"
import script from "./AddBrochure.vue?vue&type=script&lang=js&"
export * from "./AddBrochure.vue?vue&type=script&lang=js&"
import style0 from "./AddBrochure.vue?vue&type=style&index=0&id=fb952632&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb952632",
  null
  
)

export default component.exports