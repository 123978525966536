<template>
  <div>
    <label v-if="label" class="form-label  mb-1">{{ label }}</label>
    <div class="input-group input-group-merge mb-3">
      <input
        disabled
        class="
          form-control
          border-0
        "
        type="text"
        :value="value"
        aria-label="Input group appended"
        aria-describedby="inputGroup"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadOnlyInput",
  props: {
    label: String,
    value: String
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}
label {
  display: inline-block;
  color: #ed6125;
  font-weight: bold;
}
</style>
