<template>
  <textarea
    :rows="rows"
    :value="value"
    :placeholder="placeholder"
    class="form-field-textarea"
    @input="$emit('input', $event.target.value)"
  ></textarea>
</template>
<script>
export default {
  name: 'Textarea',
  props: {
    rows: {
      type: [Number, String],
      default: 5
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss">
</style>
