import Sidebar from '@/components/Sidebar'
import NavBar from '@/components/NavBar'
import Container from '@/components/Container'
import LoadingContainer from "@/components/LoadingContainer.vue";
import BaseFormInputLocation from "@/components/BaseFormInputLocation.vue";
import BaseFormInputTagsAutocomplete from "@/components/BaseFormInputTagsAutocomplete.vue";
import ImageCropper from "@/components/ImageCropper/ImageCropper";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import Vue from "vue";
import VueCropper from "vue-cropperjs";

export default {
    mounted() {
        this.loadingAll = true
        this.getItem()
    },
    data() {
        return {
            loadingAll: false,
            loadingMemberImg: false,
            imgSrc: null,
            isEditing: false,
            territories: [],
            region: null,
            selectedTab: 0,
            territoryColumns: [
                'Territory',
                '',
            ],
            form: {
                firstname: '',
                lastname: '',
                email: '',
                position: '',
                image: '',
                territory: []
            },
            memberId: this.$route.params.id,
            memberData: [],
            labels: {
                firstname: 'First Name:',
                lastname: 'Last Name:',
                email: 'Email:',
                position: 'Position:',
                image: 'Photo:',
                territory: 'Territory:',
            },
        }
    },
    methods: {
        toLocationFormat(location) {
            return [
                location.city,
                location.region,
                location.country,
            ].filter(Boolean).join(', ') || ""
        },
        async getItem() {
            try {
                this.loadingAll = true
                this.memberData = (await this.$teamService.showMember(this.memberId)).data

                this.setMemberLoadedValues()
            }catch(e) {
    
            } finally {
                this.loadingAll = false
            }
        },
        setMemberLoadedValues() {
            const memberData = this.memberData.data

            this.form = { 
                ...memberData.profile,
                image: memberData.image,
                email: memberData.email
            }

            if (memberData.profile && memberData.profile.territory) {
                this.territories = memberData.profile.territory
            }
        },
        async disableEditing() {
            this.isEditing = false;
            await this.getItem()
        },
        enableEditing() {
            this.isEditing = true;
        },
        addTerritory(territory) {
            if (territory) {
                this.territories.push(territory)
                this.region = null
            }
        },
        removeTerritory(index) {
            this.territories.splice(index, 1)
        },
        setMemberImage(e) {
            this.memberImageModal = new Modal(
              document.getElementById("memberImageModal"),
              {backdrop: 'static', keyboard: false}
            );
            this.memberImageModal.toggle();
            const file = e.target.files[0];

            if (file.type.indexOf("image/") === -1) {
              alert("Please select an image file");
              return;
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader();
              reader.onload = (event) => {
                //   this.profileImgSrc = event.target.result;
                // rebuild cropperjs with the updated source
                this.$refs.cropperMember.replace(event.target.result);
              };
              reader.readAsDataURL(file);
            } else {
              alert("Sorry, FileReader API not supported");
            }
        },
        cropMemberImage(e) {
            this.form.image = this.$refs.cropperMember.getCroppedCanvas().toDataURL()
            this.memberImageModal.toggle();
        },
        async deleteMember(e) {
            e.preventDefault()

            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Are you sure you want to remove this member permanently?",
                confirmCallback: async () => {
                  try {
                    this.loadingAll = true
                    const res =await this.$teamService.deleteMember(this.memberId)

                    this.$eventBus.$emit('showToast', { title: 'Member deleted', msg: `The member was deleted successfully` })
                    this.$router.back()
                  } catch (e) {
                    console.log(e)
                  } finally {
                    this.loadingAll = false
                  }
                },
            });
        },

        async saveMember(e) {
            e.preventDefault()
            this.serverError = ''
            this.loadingAll = true

            try {
                this.form.territory = this.territories
                delete this.form.type

                const { firstname, lastname, email, position, territory } = this.form
                const userData = { firstname, lastname, email, position, territory }

                let userId = null

                if (this.memberId) {
                    await this.$teamService.updateMember(this.memberId, {
                        _id: this.form._id,
                        ...userData,
                    })
                    userId = this.memberId
                } else {
                    let userMember = await this.$teamService.createMember(userData)
                    userId = userMember.data._id
                }

                if (this.form.image && this.form.image.includes('data:image')) {
                    try {
                        const fileImage = this.$helper.dataURLtoFile(
                            this.form.image,
                            "text.png"
                        );

                        await this.$teamService.updateMemberImage({ 
                            img: { file: fileImage },
                            id: userId,
                        })

                    } catch (error) {
                        console.error('Representative/Create/uploadImagem', error)
                    }
                }

                this.loadingAll = false
                this.isEditing = false
            } catch (err) {
        
            }

            this.loadingAll = false
        }
    },
    components: {
        BaseFormInputLocation,
        VueCropper,
        BaseFormInputTagsAutocomplete
    }
}