import NetworkService from '@/services/NetworkService';

export default class TourService {
  constructor() {
    this.network = new NetworkService()
  }
  finishGeneralTour() {
    return this.network.put(this.network.endpoints.Tour.update, { tour: 'APP-WEB-GENERAL', situation: 'DONE' })
  }
  skipGeneralTour() {
    return this.network.put(this.network.endpoints.Tour.update, { tour: 'APP-WEB-GENERAL', situation: 'SKIP' })
  }
}