import EmptyState from "@/components/EmptyState.vue";
import ModalComponent from "@/components/ModalComponent";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Modal } from "bootstrap";
import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";
import NewPostComponent from "./NewPostComponent";
import PostComponent from "./PostComponent";
import LoadingContainer from "@/components/LoadingContainer.vue";

export default {
  name: "Social",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 1)
    }
  },
  mounted() {
    this.loadingAll = true
    this.getPosts()
    this.getProfile()
    this.getFollowers()
    this.selectedTab = this.$route.query.tab || 0


    this.$eventBus.$on("PostDeleted", (postIndex) => {
      let myPosts = this.myPosts.filter(r => r._id != postIndex)
      let allPosts = this.allPosts.filter(r => r._id != postIndex)
      this.$set(this, 'myPosts', myPosts);
      this.$set(this, 'allPosts', allPosts);
      
    });

  },
  data() {
    return {
      //default, perguntar que logica é essa 
      isEmpty: false,
      loadingAll: false,
      newPost: false,
      profile: {},
      // isEmpty: false,
      // newPost: false,
      walletModal: null,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      allPosts: [
        {
          id: 'asdSDasd',
          institution: {
            name: ''
          }
        }
      ],
      myPosts: [
        {
          institution: {
            name: ''
          }
        }
      ],
      followers: []
    };
  },
  methods: {
    async getFollowers() {
      let followers = (await this.$audienceService.getFollowers()).data

      this.followers = followers.data.map(follower => follower.user._id)
    },
    async getProfile() {
      const res = await this.$universityService.getProfile()
      let p = res.data.data
      let bgImage = this.$universityService.setupBgImages(p.albums)
      if (bgImage.length == 0) {
        bgImage = null
      } else {
        bgImage = bgImage[0]
      }
      p.bgImage = bgImage
      this.$set(this, `profile`, p)
    },
    async getPosts() {

      Promise.all([this.$socialService.getPosts(), this.$socialService.getMyPosts()]).then((values) => {
        this.myPosts = values[0].data.data
        this.allPosts = values[1].data.data
      }).catch(e => {
        this.errorMessage = e.message;
        this.myPosts = []
        this.allPosts = []
      })
        .finally(() => {
          this.loadingAll = false;
        })
    },

    getStarted() {
      // this.isEmpty = false
      this.newPost = true
    },
    handleSelectTab(index) {
      this.$router.push({ path: `${this.$paths.social}`, query: { tab: index } }).catch(e => { })
    },
    postCreated() {
      console.log('post created')
      this.newPost = false;
      this.isEmpty = false
      this.newPostModal.toggle();
      this.getPosts()
    },
    addPostClicked() {
      this.newPostModal = new Modal(document.getElementById("newPostModal"), {
        backdrop: 'static',
        keyboard: false
      });
      this.newPostModal.toggle();
    },
    accessFollowers() {
      this.$router.push({ path: `${this.$paths.audience}`, query: { tab: 1 } }).catch(e => { })
    }
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    PostComponent,
    NewPostComponent,
  },
};