import NetworkService from '@/services/NetworkService';

export default class AudienceService {
    constructor() {
        this.network = new NetworkService()
    }
    getFollowers(params) {
        const url = `${this.network.endpoints.Audience.followers}?limit=${params?.limit || 1000}&page=${params.page}&search=${params.search}`
        return this.network.get(url)
    }
    showFollower(id) {
        const url = `${this.network.endpoints.Audience.followers}/${id}`
        return this.network.post(url)
    }
    createFollower(params) {
        return this.network.post(this.network.endpoints.Audience.followers)
    }
    async getRequests(params) {
        return new Promise((resolve, reject) => {
            const url = `${this.network.endpoints.Audience.requests}?limit=${params?.limit || 1000}`
            this.network.get(url).then(res => {
                let o = res
                o.data.data.map(request => {
                    if (!request.costToAccept || request.costToAccept == 'N/A') {
                        request.costToAccept = 0
                    }
                    request.costToAccept = parseInt(request.costToAccept)
                })
                resolve(o)
            }).catch(e => reject(e))

        })
    }
    showRequest(id) {
        const url = `${this.network.endpoints.Audience.requests}/${id}`
        return this.network.get(url)
    }
    updateRequest(id, params) {
        const url = `${this.network.endpoints.Audience.requests}/${id}`
        return this.network.post(url)
    }
    acceptRequests(requests) {
        const url = `${this.network.endpoints.Audience.requests}`
        return this.network.put(url, { requestsToFollow: requests })
    }
    declineRequests(requests) {
        const url = `${this.network.endpoints.Audience.requestsDescline}`
        return this.network.put(url, { requestsToFollow: requests })
    }
}
