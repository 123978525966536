import BaseFormInputLocation from "@/components/BaseFormInputLocation.vue";
import BaseFormInputTagsAutocomplete from "@/components/BaseFormInputTagsAutocomplete.vue";
import ModalComponent from "@/components/ModalComponent";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
    mounted() {
    },
    data() {
        return {
            loadingAll: false,
            loadingMemberImg: false,
            imgSrc: null,
            territories: [],
            typeOfProgramsAllOptions: [],
            typeOfProgramsOptions: [],
            typeOfProgramsSelected: [],
            region: null,
            selectedTab: 0,
            territoryColumns: [
                'Territory',
                '',
            ],
            form: {
                firstname: '',
                lastname: '',
                email: '',
                position: '',
                image: 'https://content.novva.tech/upload/student/5fc6a8a69175ef31b1910177/1606854823109.png',
                territory: [],
            },
            memberId: this.$route.params.id,
            memberData: [],
            labels: {
                firstname: 'First Name:',
                lastname: 'Last Name:',
                email: 'Email:',
                position: 'Position:',
                image: 'Photo:',
                territory: 'Territory:',
            },
        }
    },
    validations: {
        form: {
          email: {
            required,
            email,
          },
          firstname: {
            required,
            minLength: minLength(3)
          },
          lastname: {
            required,
            minLength: minLength(3)
          },
          position: {
            required,
          }
        }
    },
    computed: {
        getOptions() {
            return this.typeOfProgramsOptions
        },
        invalidBasicInfo() {
            return this.$v.form.email.$invalid || 
                this.$v.form.firstname.$invalid || 
                this.$v.form.lastname.$invalid || 
                this.$v.form.position.$invalid 
        },
    },
    methods: {
        isTheLastStep() {
            return this.selectedTab == 1
        },
        nextStep() {
            this.selectedTab++
        },
        toLocationFormat(location) {
            return [
                location.city,
                location.region,
                location.country,
            ].filter(Boolean).join(', ') || ""
        },
        setMemberLoadedValues() {
            const memberData = this.memberData.data

            this.form = { 
                ...memberData.profile,
                image: memberData.image,
                email: memberData.email
            }

            if (memberData.profile && memberData.profile.territory) {
                this.territories = memberData.profile.territory
            }

        },
        addTerritory(territory) {
            if (territory) {
                this.territories.push(territory)
                this.region = null
            }
        },
        removeTerritory(index) {
            this.territories.splice(index, 1)
        },
        setMemberImage(e) {
            this.memberImageModal = new Modal(
              document.getElementById("memberImageModal"),
              {backdrop: 'static', keyboard: false}
            );
            this.memberImageModal.toggle();
            const file = e.target.files[0];

            if (file.type.indexOf("image/") === -1) {
              alert("Please select an image file");
              return;
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader();
              reader.onload = (event) => {
                //   this.profileImgSrc = event.target.result;
                // rebuild cropperjs with the updated source
                this.$refs.cropperMember.replace(event.target.result);
              };
              reader.readAsDataURL(file);
            } else {
              alert("Sorry, FileReader API not supported");
            }
        },
        cropMemberImage(e) {
            this.form.image = this.$refs.cropperMember.getCroppedCanvas().toDataURL()
            this.memberImageModal.toggle();
        },
        async deleteMember(e) {
            e.preventDefault()

            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Are you sure you want to remove this member permanently?",
                confirmCallback: async () => {
                  try {
                    this.loadingAll = true
                    const res =await this.$teamService.deleteMember(this.memberId)

                    this.$eventBus.$emit('showToast', { title: 'Member deleted', msg: `The member was deleted successfully` })
                    this.$router.back()
                  } catch (e) {
                    console.log(e)
                  } finally {
                    this.loadingAll = false
                  }
                },
            });
        },

        async saveMember(e) {
            e.preventDefault()
            this.serverError = ''
            this.loadingAll = true

            try {
                this.form.territory = this.territories
                delete this.form.type

                const { firstname, lastname, email, position, territory } = this.form
                const userData = { firstname, lastname, email, position, territory }

                var userMember = await this.$teamService.createMember(userData)
                let userId = userMember.data._id

                if (this.form.image && this.form.image.includes('data:image')) {
                    try {
                        const fileImage = this.$helper.dataURLtoFile(
                            this.form.image,
                            "text.png"
                        );

                        await this.$teamService.updateMemberImage({ 
                            img: { file: fileImage },
                            id: userId,
                        })

                    } catch (error) {
                        console.error('Representative/Create/uploadImagem', error)
                    }
                }

                this.loadingAll = false
                this.$eventBus.$emit('showToast', { title: 'Member added', msg: `The member was added successfully` })
                this.$router.back();
            } catch (err) {
                if (err.message) {
                    if (err.message == 'G00030') {
                        this.$eventBus.$emit('showToast', { title: 'E-mail already exists!', msg: 'Enter another email to continue' })
                        this.form.email = '';
                        this.selectedTab = 0;
                    }
                }
            }

            this.loadingAll = false
        }
    },
    components: {
        BaseFormInputLocation,
        VueCropper,
        ModalComponent,
        BaseFormInputTagsAutocomplete
    }
}