<template>
  <div class="signin">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 px-lg-6 mb-5 mt-7">
          <img src="@/assets/img/logo_register.png" alt="" class="small-logo mb-6" />
          <div class="my-5">
            <h1 class="header-title mb-2">Forgot password</h1>
            <small>Enter your email to get a password reset link</small>
          </div>
          <form>
            <div class="form-group mb-6">
              <label class="form-label"> Email Address </label>
              <input
                class="form-control"
                v-model.trim="$v.form.email.$model"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="email"
                placeholder="name@address.com"
              />
              <div class="invalid-feedback">
                <small>Please fill in this field correctly</small>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
                <span class="text-success text-center">{{ successMessage }}</span>
              </div>
            </div>

            <button
              @click.prevent="submit()"
              :disabled="$v.form.$invalid || loading"
              class="btn w-100 btn-primary my-3"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Reset password
            </button>

            <p class="text-center">
              <small class="text-dark">
                Remember your password?
                <router-link :to="$paths.signin" tag="a">Sign In.</router-link>
              </small>
            </p>
          </form>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-7 d-none d-lg-block">
          <!-- style="background-image: url(https://picsum.photos/1901/2376)" -->
          <div id="bgimg" class="bg-cover h-100 min-vh-100 mt-n1 me-n3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPasswordController from "./ForgotPasswordController";
export default ForgotPasswordController;
</script>

<style scoped>
.small-logo {
    width: auto !important;
  }
  #bgimg {
  background-image: url("../../assets/img/login_bg.png");
}
</style>
