<template>
  <nav
    class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light border-right"
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm avatar-online">
              <!-- <img
                src="./assets/img/avatars/profiles/avatar-1.jpg"
                class="avatar-img rounded-circle"
                alt="..."
              /> -->
            </div>
          </a>

          <!-- Menu -->
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="sidebarIcon"
          >
            <a href="./profile-posts.html" class="dropdown-item">Profile</a>
            <a href="./account-general.html" class="dropdown-item">Settings</a>
            <hr class="dropdown-divider" />
            <a href="./sign-in.html" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Form -->
        <form class="mt-4 mb-3 d-md-none">
          <div
            class="input-group input-group-rounded input-group-merge input-group-reverse"
          >
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </form>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <router-link
            v-slot="{ navigate, isActive }"
            active-class="router-link-active"
            :to="$paths.home"
            tag="li"
            class="nav-item"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-1"
            >
              <img
                :src="isActive ? icons.dashboardActive : icons.dashboard"
                class="sidebar-icon"
              />
              Dashboard
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.agenda.list"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-2"
            >
              <img
                :src="isActive ? icons.agendaActive : icons.agenda"
                class="sidebar-icon"
              />
              Agenda
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.social"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-3"
            >
              <img
                :src="isActive ? icons.communityActive : icons.community"
                class="sidebar-icon"
              />
              Community
            </a>
          </router-link>
        </ul>

        <!-- Divider -->
        <hr class="navbar-divider my-3" />

        <!-- Heading -->
        <h6 class="navbar-heading px-3">University</h6>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <router-link
            active-class="router-link-active"
            :to="$paths.profile"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-4"
            >
              <img
                :src="isActive ? icons.profileActive : icons.profile"
                class="sidebar-icon"
              />
              Profile
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.messages.list"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-5"
            >
              <img
                :src="isActive ? icons.messagesActive : icons.messages"
                class="sidebar-icon"
              />
              Messages
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.audience"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-6"
            >
              <img
                :src="isActive ? icons.audienceActive : icons.audience"
                class="sidebar-icon"
              />
              Audience
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.university.list"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-7"
            >
              <img
                :src="isActive ? icons.resourcesActive : icons.resources"
                class="sidebar-icon"
              />
              Resources
            </a>
          </router-link>
        </ul>

        <!-- Divider -->
        <hr class="navbar-divider my-3" />

        <!-- Heading -->
        <h6 class="navbar-heading px-3">Other</h6>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <router-link
            active-class="router-link-active"
            :to="$paths.team.list"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-8"
            >
              <img
                :src="isActive ? icons.teamActive : icons.team"
                class="sidebar-icon"
              />
              Team
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.account"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-9"
            >
              <img
                :src="isActive ? icons.accountActive : icons.account"
                class="sidebar-icon"
              />
              Account
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.wallet.list"
            tag="li"
            class="nav-item"
            v-slot="{ navigate, isActive }"
            custom
          >
            <a
              class="nav-link cursor-pointer"
              @click="navigate"
              :class="{ 'text-black': isActive }"
              id="step-tour-10"
            >
              <img
                :src="isActive ? icons.walletActive : icons.wallet"
                class="sidebar-icon"
              />
              Wallet
            </a>
          </router-link>
        </ul>
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      icons: {
        dashboard: require("../assets/img/sidebar/dashboard.png"),
        dashboardActive: require("../assets/img/sidebar/dashboard_active.png"),
        agenda: require("../assets/img/sidebar/agenda.png"),
        agendaActive: require("../assets/img/sidebar/agenda_active.png"),
        community: require("../assets/img/sidebar/community.png"),
        communityActive: require("../assets/img/sidebar/community_active.png"),
        profile: require("../assets/img/sidebar/profile.png"),
        profileActive: require("../assets/img/sidebar/profile_active.png"),
        messages: require("../assets/img/sidebar/messages.png"),
        messagesActive: require("../assets/img/sidebar/messages_active.png"),
        audience: require("../assets/img/sidebar/audience.png"),
        audienceActive: require("../assets/img/sidebar/audience_active.png"),
        resources: require("../assets/img/sidebar/resources.png"),
        resourcesActive: require("../assets/img/sidebar/resources_active.png"),
        team: require("../assets/img/sidebar/team.png"),
        teamActive: require("../assets/img/sidebar/team_active.png"),
        account: require("../assets/img/sidebar/account.png"),
        accountActive: require("../assets/img/sidebar/account_active.png"),
        wallet: require("../assets/img/sidebar/wallet.png"),
        walletActive: require("../assets/img/sidebar/wallet_active.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding-top: 100px;
}
.navbar-heading {
  text-align: left;
  font-weight: 700;
}
#sidebar {
  background-color: #f7f7f7;
}
.sidebar-icon {
  width: 25px;
  text-align: left;
}
.nav-link {
  padding: 0.7rem 1.5rem;
  img {
    width: 18px;
    margin-right: 12px;
  }
}
.navbar-vertical.navbar-expand-md {
  padding-right: 1em;
  padding-left: 1em;
}
</style>
