import Container from "@/components/Container";
import ModalComponent from "@/components/ModalComponent";
import NavBar from "@/components/NavBar";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Sidebar from "@/components/Sidebar";
import { mapActions, mapGetters } from "vuex";
import Slider from "@/components/Slider";
import LoadingContainer from "@/components/LoadingContainer";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import Vue from "vue";
import VueCropper from "vue-cropperjs";
import { required } from "vuelidate/lib/validators";
import BaseInputLocation from "./../../components/BaseInputLocation";
import MobileProfilePreview from "./../../components/MobileProfilePreview.vue";
import ButtonEditProfile from "./../../components/ButtonEditProfile";

export default {
  mounted() {
    this.loadingAll = true;
    this.getData();
    this.getOnboardingQuestions();
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}&callback=Function.prototype`
    );
    document.head.appendChild(externalScript);
    setTimeout(() => {
      this.calculateWidth();
      this.scriptLoaded = true;
    }, 1000);
  },
  created() {
    window.addEventListener("resize", this.calculateWidth);
  },
  computed: {
    ...mapGetters(["LOADING_PREVIEW"]),

    loadingPreview() {
      return this.LOADING_PREVIEW;
    },

    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      locationErrorMsg: "",
      scriptLoaded: false,
      loadingAll: false,
      item: {
        institution: {},
        scholarships: [],
        brochures: [],
        videos: [],
        photos: [],
      },
      form: {
        about: "",
        location: {},
        name: "",
      },
      imageModal: null,
      cropImg: null,
      fileImage: null,
      imgSrc: null,
      columnWidth: 724,
      focusInputs: {
        about: false,
      },
      questions: {},
      loadingProfileImg: false,
      quillOptions: {
        placeholder: "About",
        readonly: false,
        bold: false,
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
      loadingToken: false,
    };
  },
  validations() {
    return {
      form: {
        about: { required },
        location: { required },
        name: { required },
      },
    };
  },
  components: {
    VueCropper,
    Slider,
    VueGoogleAutocomplete,
    BaseInputLocation,
    MobileProfilePreview,
    ButtonEditProfile,
  },
  methods: {
    ...mapActions(["syncSessionWithApi"]),
    fetchProfileClick() {
      document.getElementById("button-profile-preview").click();
    },
    async getOnboardingQuestions() {
      const res = await this.$universityService.getOnboardingQuestions();
      const questions = res.data.data.filter((q) => q != null);
      let q = {};
      questions
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
        .map((r, index) => {
          q[`${r.name}`] = r.value;
        });
      console.log(q);
      this.$set(this, "questions", q);
    },
    getData() {
      this.$universityService
        .getProfile()
        .then((res) => {
          let data = res.data.data;
          let videos = data.videos;
          videos.map((r) => {
            r.embededUrl = this.$helper.getEmbededUrl(
              this.$helper.youtubeParser(r.url)
            );
          });
          data.videos = videos;
          data.bgImages = this.$universityService.setupBgImages(data.albums);
          this.$set(this.form, `about`, data.institution.description);
          this.$set(this.form, `name`, data.institution.name);

          let allPhotos = [];
          if (res.data.data.albums) {
            res.data.data.albums
              .filter((s) => s.photos.length > 0)
              .map((p) => {
                allPhotos = allPhotos.concat(p.photos);
              });
          }

          data.photos = allPhotos;

          this.$set(this, `item`, data);
        })
        .catch((e) => console.log(e))
        .finally(() => (this.loadingAll = false));
    },

    cropProfileImage() {
      // get image data for post processing, e.g. upload or setting image src
      const fileImage = this.$helper.dataURLtoFile(
        this.$refs.cropperProfile.getCroppedCanvas().toDataURL(),
        "text.png"
      );

      this.loadingProfileImg = true;
      this.$universityService
        .updateProfileImage({
          img: { file: fileImage },
          profileId: this.userSession.profileId,
        })
        .then((r) => {
          this.syncSessionWithApi();
          this.getData();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingProfileImg = false;
          }, 1200);
        });
      this.profileImageModal.toggle();

      //   this.cropProfileImg = this.$refs.cropperProfile
      //     .getCroppedCanvas()
      //     .toDataURL();
      //   // console.log(
      //   console.log("asjhbdjnasdjaks ");
      //   this.$refs.cropperProfile.getCroppedCanvas().toBlob((blob) => {
      //     const formData = new FormData();
      //     formData.append("profileImage", blob, "avatar");
      //   });
    },

    setProfileImage(e) {
      this.profileImageModal = new Modal(
        document.getElementById("profileImageModal"),
        { backdrop: "static", keyboard: false }
      );
      this.profileImageModal.toggle();
      const file = e.target.files[0];
      console.log(file);
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          //   this.profileImgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropperProfile.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    calculateWidth() {
      this.columnWidth = this.$refs.columnWidth?.clientWidth;
    },
    editClicked(input) {
      switch (input) {
        case "about":
          this.$set(this.focusInputs, "about", true);
          setTimeout(() => {
            document.getElementById("about-text-area").focus();
          }, 200);
          break;
        case "name":
          this.$set(this.focusInputs, "name", true);
          setTimeout(() => {
            document.getElementById("name-input").focus();
          }, 200);
          break;
        case "location":
          this.$set(this.focusInputs, "location", true);
          break;
      }
    },
    handleBlur(input) {
      switch (input) {
        case "about":
          this.$universityService
            .updateProfile(this.userSession.profileId, {
              about: this.form.about,
              image: this.item.institution.image,
            })
            .then((r) => {
              this.syncSessionWithApi();
            });
          this.$set(this.item.institution, `description`, this.form.about);
          this.$set(this.focusInputs, "about", false);
          break;
        case "name":
          if (!this.$v.form.name.$invalid) {
            this.$universityService
              .updateProfile(this.userSession.profileId, {
                name: this.form.name,
              })
              .then((r) => {
                this.syncSessionWithApi();
              });
            this.$set(this.item.institution, `name`, this.form.name);
          }
          this.$set(this.focusInputs, "name", false);
          break;
        case "location":
          this.$set(this.focusInputs, "location", false);
          break;
      }
    },
    saveLocation() {
      this.$universityService
        .updateProfile(this.userSession.profileId, {
          location: this.form.location,
        })
        .then((r) => {
          this.syncSessionWithApi();
        });
    },
    addressChanged(data) {
      this.locationErrorMsg = "";
      if (
        !data.city ||
        !data.region ||
        !data.country ||
        !data.coordinates.lat ||
        !data.coordinates.lng
      ) {
        this.locationErrorMsg = "Please, select a more detailed address";
      } else {
        this.$set(this.form, "location", data);
        this.$set(this.item.institution, `location`, {
          ...this.item.institution.location,
          ...this.form.location,
        });
        this.$set(this.focusInputs, "location", false);
        this.saveLocation();
      }
    },
    resetLocation() {
      const l = {
        city: "",
        region: "",
        country: "",
        countryInitials: "",
        coordinates: {
          lat: "",
          lng: "",
        },
      };
      this.$set(this.form.questions, "location", l);
    },
  },
};
