import moment from "moment";

import { USER_TYPE } from "@/utils/Constants";

const CHATS = (state) => {
  return state.arrChats.slice(0).map((r) => {
    return {
      ...r,
      created: r.createdAt
        ? moment(r.createdAt).format("MMM, DD YYYY")
        : moment().format("MMM, DD YYYY"),
      studentName: r.student
        ? `${r.student.firstname} ${r.student.lastname}`
        : "",
      lastMessageIsMine:
        r.lastMessage &&
        r.lastMessage.length &&
        r.lastMessage[0].user.type != USER_TYPE.STUDENT,
    };
  });
};

const GET_CHAT = (state) => (studentId) => {
  return state.arrChats.find((c) => c.studentId == studentId);
};

const GET_CHAT_MESSAGES = (state, getters) => (studentId) => {
  let chat = getters.GET_CHAT(studentId);

  if (!chat) {
    return [];
  }

  const messages = chat.messages;

  if (!messages) {
    return [];
  }

  return messages;
};

const GET_CHAT_INFO = (state, getters) => (studentId) => {
  let chat = getters.GET_CHAT(studentId);

  if (!chat) {
    return {};
  }

  const chatInfo = chat.chatInfo;

  if (!chatInfo) {
    return {};
  }

  return chatInfo;
};

export { CHATS, GET_CHAT, GET_CHAT_MESSAGES, GET_CHAT_INFO };
