<template>
  <div>
    <router-view />
    <NotificationToast />
    <WalletModal />
    <ConfirmationAlert />
  </div>
</template>

<script>
import NotificationToast from "@/components/NotificationToast";
import WalletModal from "@/components/WalletModal";

import socket from '@/mixins/socket'

export default {
  name: "App",

  mixins: [socket],

  data() {
    return {
    }
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },

  components: {
    NotificationToast,
    WalletModal,
  },
};
</script>
