import NetworkService from "@/services/NetworkService";

export default class UniversityService {
  constructor() {
    this.network = new NetworkService();
  }

  // VIDEOS

  getVideos(params) {
    return this.network.get(this.network.endpoints.University.videos);
  }
  createVideo(params) {
    return this.network.post(this.network.endpoints.University.videos, params);
  }
  showVideo(id) {
    const url = `${this.network.endpoints.University.videos}/${id}`;
    return this.network.get(url);
  }
  deleteVideo(id) {
    const url = `${this.network.endpoints.University.videos}/${id}`;
    return this.network.delete(url);
  }

  // SCHOLARSHIP
  createScholarship(params) {
    return this.network.post(
      this.network.endpoints.University.scholarships,
      params
    );
  }
  getScholarships() {
    return this.network.get(this.network.endpoints.University.scholarships);
  }
  showScholarship(id) {
    const url = `${this.network.endpoints.University.scholarships}/${id}`;
    return this.network.get(url);
  }
  updateScholarshipStatus(id, params) {
    const url = `${this.network.endpoints.University.scholarshipsStatus}/${id}`;
    return this.network.put(url, params);
  }
  deleteScholarship(id) {
    const url = `${this.network.endpoints.University.scholarships}/${id}`;
    return this.network.delete(url);
  }
  getScholarshipFields() {
    const url = `${this.network.endpoints.University.scholarshipsFields}`;
    return this.network.get(url);
  }

  // PHOTOS
  async createPhotoUrl(params) {
    const fileImage = params.fileImage;
    return this.network.createSignedUrl("photo", fileImage, false);
  }
  async createPhoto(params) {
    return this.network.post(this.network.endpoints.University.photos, {
      album: params.collection,
      photos: params.imageArray,
    });
  }
  getPhotos() {
    return this.network.get(this.network.endpoints.University.photos);
  }

  getAlbums() {
    const url = `${this.network.endpoints.University.albums}`;
    return this.network.get(url);
  }

  createCollection(params) {
    const url = `${this.network.endpoints.University.albums}`;
    return this.network.post(url, params);
  }
  updateAlbum(id, params) {
    const url = `${this.network.endpoints.University.albums}/${id}`;
    return this.network.put(url, params);
  }
  deletePhoto(params) {
    const url = `${this.network.endpoints.University.photos}`;
    return this.network.delete(url, params);
  }

  // BROCHURES
  async createBrochure(params) {
    const res = await this.network.createSignedUrl(
      "brochure",
      params.fileImage
    );
    return this.network.post(this.network.endpoints.University.brochures, {
      title: params.title,
      file: res.signedUrl,
    });
  }
  getBrochures() {
    return this.network.get(this.network.endpoints.University.brochures);
  }
  showBrochure(id) {
    const url = `${this.network.endpoints.University.brochures}/${id}`;
    return this.network.get(url);
  }

  deleteBrochure(id) {
    const url = `${this.network.endpoints.University.brochures}/${id}`;
    return this.network.delete(url);
  }

  // PROFILE

  getProfile() {
    const url = `${this.network.endpoints.University.profile}`;
    return this.network.get(url);
  }
  updateProfile(id, params) {
    return this.network.put(
      `${this.network.endpoints.University.onboarding}/${id}`,
      params
    );
  }

  fetchMobileProfilePreview() {
    const url = `${this.network.endpoints.University.profile}/mobile-preview`;
    return this.network.get(url);
  }

  async updateProfileImage(params) {
    const res = await this.network.createSignedUrl("logo", params.img);
    return this.network.put(
      `${this.network.endpoints.University.onboarding}/${params.profileId}`,
      {
        image: res.signedUrl,
      }
    );
  }
  async completeProfile(params) {
    const res = await this.network.createSignedUrl("logo", params.img);
    return this.network.put(
      `${this.network.endpoints.University.onboarding}/${params.profileId}`,
      {
        about: params.about,
        image: res.signedUrl,
      }
    );
  }

  //OTHER
  getOverview() {
    const url = `${this.network.endpoints.Overview}`;
    return this.network.get(url);
  }
  getOnboardingQuestions() {
    return this.network.get(this.network.endpoints.University.steps);
  }

  async completeOnboarding(params) {
    return this.network.put(this.network.endpoints.University.steps, params);
  }

  setupBgImages(albuns) {
    if (!albuns || albuns.length == 0) return [];
    let pics = [];

    albuns.map((r) => {
      r.photos.map((p) => {
        pics.push(p);
      });
    });
    return pics;
  }

  updateConfig(params) {
    const url = `${this.network.endpoints.University.updateConfig}`
    return this.network.put(url, { ...params })
  }
  getConfig() {
    const url = `${this.network.endpoints.University.getConfig}`
    return this.network.get(url)
  }
}
