<template>
  <div>
    <Sidebar />
    <NavBar
      current="Chat Detail"
      :link="$paths.messages.list"
      linkName="Messages"
    />
    <Container col="col-12 col-lg-10 col-xl-6">
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="#!" class="avatar">
                <img
                  :src="chatInfo.studentPicture"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1 h4">{{ chatInfo.studentName }}</h4>

              <!-- Time -->
              <p class="card-text text-muted">
                <small>{{ chatInfo.studentLocation }}</small>
              </p>
            </div>
            <div class="col-auto">
              <!-- Dropdown -->
              <router-link
                tag="button"
                :to="{
                  path: `${$paths.students.detail}/${chatInfo.studentId}`,
                }"
                class="btn btn-link"
              >
                See full profile
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12 text-center">
            <small class="text-muted" v-if="chatInfo.firstMessage"
              >First message on
              {{ $moment(chatInfo.firstMessage).format("MMM DD, YYYY") }}
            </small>
          </div>
          <div
            id="main-chat-container"
            class="col-12 chat-container overflow-scroll"
          >
            <div
              v-for="(message, index) in items"
              v-bind:key="'message' + index"
            >
              <template
                v-if="message.user && message.user.type != userStudentType"
              >
                <div
                  :class="`message-container d-flex flex-column align-items-end ${
                    message.status == messageStatus.SENDING ? 'opacity-6' : ''
                  }`"
                >
                  <div class="d-flex align-items-end">
                    <ImagePlaceholder :src="message.user.image">
                      <template v-slot:placeholder>
                        <span
                          class="fas fa-user-circle fa-2x text-primary"
                        ></span>
                      </template>
                    </ImagePlaceholder>
                    <div
                      class="chat-bubble chat-primary left-bubble bg-primary"
                    >
                      <span class="message-name float-left">{{
                        message.user.name
                      }}</span>
                      <span class="text text-left text-white">{{
                        message.message
                      }}</span>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <small class="text-muted me-2">{{
                      $moment(message.createdAt).format("L HH:mm")
                    }}</small>
                    <template v-if="message.status == messageStatus.SENDING">
                      <span class="text-secondary">
                        <small class="fas fa-check"></small>
                      </span>
                    </template>
                    <template v-else-if="message.status == messageStatus.SENT">
                      <span class="text-secondary">
                        <small class="fas fa-check"></small>
                        <small class="fas fa-check right-check"></small>
                      </span>
                    </template>
                    <template
                      v-else-if="message.status == messageStatus.VIEWED"
                    >
                      <span class="text-blue-link">
                        <small class="fas fa-check"></small>
                        <small class="fas fa-check right-check"></small>
                      </span>
                    </template>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="message-container d-flex flex-column">
                  <div class="d-flex flex-row align-items-end">
                    <ImagePlaceholder :src="chatInfo.studentPicture">
                      <template v-slot:placeholder>
                        <span
                          class="fas me-2 fa-user-circle fa-2x text-primary"
                        ></span>
                      </template>
                    </ImagePlaceholder>

                    <div class="chat-bubble left-bubble bg-secondary-soft">
                      <span class="message-name float-left">{{
                        message.user.name
                      }}</span>
                      <span class="text text-left text-dark message">{{
                        message.message
                      }}</span>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <small class="text-muted me-2 ps-5">{{
                      $moment(message.createdAt).format("HH:mm")
                    }}</small>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="input-group input-group-merge input-group mb-3">
              <Textarea
                rows="5"
                class="form-control"
                v-model.trim="textMessage"
                type="text"
                ref="message"
                @keyup.enter.native.exact.prevent="sendMessage()"
                @keyup.enter.shift.native.exact.stop.prevent="
                  textMessage += '\n'
                "
                :maxlength="maxTextLength"
                placeholder="Type..."
              />
              <div
                class="input-group-text text-dark align-items-start"
                id="inputGroupReverse"
              >
                <button class="btn btn-link" @click.prevent="sendMessage()">
                  Send
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 text-end">
            <small class="text-muted"
              >Chars left: {{ maxTextLength - textMessage.length }}</small
            >
          </div>
        </div>
      </form>
    </Container>
  </div>
</template>

<script>
import ChatController from "./ChatController";
export default ChatController;
</script>

<style>
div.chat-container {
  height: calc(100vh - 400px);
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
}
div.chat-bubble {
  background-color: pink;
  border-radius: 30px;
  padding: 12px !important;
}

div.text {
  word-wrap: break-word;
  word-break: break-word;
}

div.chat-bubble.right-bubble {
  border-bottom-right-radius: 0 !important;
}
div.chat-bubble.left-bubble {
  border-bottom-left-radius: 0 !important;
  margin-left: 10px;
}
textarea {
  resize: none;
}
.message-name {
  font-size: 15px;
  font-weight: bold;
  display: block;
  margin-bottom: 3px !important;
  padding-bottom: 0px !important;
  color: white;
}
.message {
  margin-top: 0;
  padding-top: 0;
}

.right-check {
  margin-left: -6px;
  /* margin-right: 6px; */
}

.chat-footer {
  padding-right: 5px;
  margin-bottom: 10px;
}

.opacity-6 {
  opacity: 0.6;
}
</style>
