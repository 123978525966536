<template>
  <div class="signin">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 px-lg-6 mb-5 mt-7">
          <img src="@/assets/img/logo_register.png" alt="" class="small-logo mb-6" />
          <h1 class="header-title my-5">Sign in</h1>
          <form>
            <div class="form-group">
              <label class="form-label"> Email Address </label>
              <input
                class="form-control"
                v-model.trim="$v.form.email.$model"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="email"
                placeholder="name@address.com"
              />
              <div class="invalid-feedback">
                <small>Please fill in this field correctly</small>
              </div>
            </div>
            <div class="form-group mb-6">
              <div class="row">
                <div class="col">
                  <label class="form-label"> Password </label>
                </div>
              </div>

              <div class="input-group input-group-merge">
                <input
                  v-model.trim="$v.form.password.$model"
                  :class="{ 'is-invalid': $v.form.password.$error }"
                  class="form-control border-right-0"
                  :type="passwordFieldType"
                  placeholder="Enter your password"
                />
                <span class="input-group-text cursor-pointer fix-input-group-border">
                  <i
                    @click="togglePassword()"
                    class="fas"
                    :class="{
                      'fa-eye': passwordFieldType == 'text',
                      'fa-eye-slash': passwordFieldType == 'password',
                    }"
                  ></i>
                </span>
                <div class="invalid-feedback">
                  <small>Please fill in this field correctly</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <button
              @click.prevent="signin()"
              :disabled="$v.$invalid || loading"
              class="btn w-100 btn-primary my-3"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              SIGN IN
            </button>

            <p class="text-center">
              <router-link
                to="/forgot-password"
                tag="a"
                class="form-text small text-primary"
              >
                Forgot your password?
              </router-link>
            </p>
            <!-- <p class="text-center">
              <small class="text-dark">
                Don't have an account yet?
                <router-link :to="$paths.signup.info" tag="a"> Sign Up </router-link>.
              </small>
            </p> -->
          </form>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-7 d-none d-lg-block">
          <!-- style="background-image: url(https://picsum.photos/1901/2376)" -->
          <div id="bgimg" class="bg-cover h-100 min-vh-100 mt-n1 me-n3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SigninController from "./SigninController";
export default SigninController;
</script>

<style scoped>
#bgimg {
  background-image: url("../../assets/img/login_bg.png");
}
.small-logo {
  width: auto !important;
}
</style>
