<template>
  <div ref="vonagePublisher" class="vonage-publisher"></div>
</template>

<script>
import OT from "@opentok/client";

const initPublisher = (vonagePublisher) => {
  return new Promise((resolve, reject) => {
    const publisher = OT.initPublisher(
      vonagePublisher,
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        fitMode: "contain",
        audioFallbackEnabled: false,
        style: {
          nameDisplayMode: "on",
          buttonDisplayMode: "off",
        },
      },
      (error) => {
        if (error) {
          return reject(error);
        }

        return resolve(publisher);
      }
    );
  });
};

export default {
  name: "VonagePublisher",

  props: {
    vonageSession: {
      type: OT.Session,
      required: true,
    },
  },

  async mounted() {
    let publisher = null;

    try {
      publisher = await initPublisher(this.$refs.vonagePublisher);

      if (publisher) {
        this.vonageSession.on("sessionConnected", () => {
          this.vonageSession.publish(publisher, (error) => {
            if (error) {
              this.$emit("error", error);
            }
          });
        });
      }
    } catch (e) {
      this.$emit("error", e);
    }

    this.$emit("publish", publisher);
  },
};
</script>

<style lang="scss" scoped>
.vonage-publisher {
  height: 100%;
  width: 100%;
}
</style>
