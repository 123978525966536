<template>
  <div class="audience">
    <Sidebar />
    <NavBar current="Community" />
    <LoadingContainer v-if="loadingAll" />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div
          class="empty-container align-items-center d-flex"
          v-if="myPosts.length == 0 && allPosts.length == 0 && !newPost"
        >
          <EmptyState
            title="Community"
            msg="Here you can manage all posts, comments and accept new followers"
          >
            <template v-slot:icon>
              <i class="fas fa-clone fa-3x text-primary"></i>
            </template>
            <template v-slot:button>
              <button type="button" @click="getStarted()" class="btn btn-primary">
                Get started
              </button>
            </template>
          </EmptyState>
        </div>
        <div class="row d-flex justify-content-center" v-if="newPost">
          <div class="col-8">
            <NewPostComponent v-on:newPostCreated="postCreated()" :isModal="false" />
          </div>
        </div>
        <div class="row" v-if="myPosts.length > 0 || allPosts.length > 0">
          <div class="col-12 col-xl-8">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Community</h1>
                    <h4 class="header-subtitle">See what's being posted in the community feed and manage your own posts</h4>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="addPostClicked">
                      <i class="me-2 fas fa-plus-circle"></i>
                      Add post
                    </button>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link tab-1"
                          :class="{ active: selectedTab == 1 }"
                          id="my-posts-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#my-posts"
                          type="button"
                          role="tab"
                          aria-controls="my-posts"
                          aria-selected="false"
                        >
                          My posts
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{ myPosts.length }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link tab-0"
                          :class="{ active: selectedTab == 0 }"
                          id="social-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#social"
                          type="button"
                          role="tab"
                          aria-controls="social"
                          aria-selected="true"
                        >
                          Community feed
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <!-- SOCIAL -->
              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 0 }"
                id="social"
                role="tabpanel"
                aria-labelledby="social-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <PostComponent
                      v-for="item in allPosts"
                      v-bind:key="item.id"
                      :post="item"
                      :followers="followers"
                    ></PostComponent>
                  </div>
                </div>
              </div>
              <!-- MY POSTS -->
              <div
                class="mt-5 tab-pane fade"
                :class="{ 'show active': selectedTab == 1 }"
                id="my-posts"
                role="tabpanel"
                aria-labelledby="my-posts-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <PostComponent
                      :postIsMine="true"
                      v-for="item in myPosts"
                      v-bind:key="item.id"
                      :post="item"
                      :followers="followers"
                    ></PostComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-3 me-5" id="profile-right-container">
            <div class="header-body mt-3"></div>
            <div class="card border-2">
              <img
                v-if="profile.bgImage"
                :src="profile.bgImage"
                alt="..."
                class="card-img-top"
              />
              <img
                v-else
                src="https://picsum.photos/500/300"
                alt="..."
                class="card-img-top"
              />

              <!-- Body -->
              <div class="card-body">
                <!-- Image -->
                <div
                  class="avatar ms-0 avatar-xl avatar-rectangle card-avatar card-avatar-top"
                >
                  <img
                    v-if="profile && profile.institution"
                    :src="profile.institution.image"
                    class="avatar-img avatar-rectangle rounded border border-4 border-card"
                    alt="..."
                  />
                  <img
                    v-else
                    src="@/assets/img/no-img-placeholder.png"
                    class="avatar-img avatar-rectangle rounded border border-4 border-card"
                    alt="..."
                  />
                </div>

                <!-- Heading -->
                <h2 v-if="profile.institution" class="card-title mb-1 h2">
                  {{ profile.institution.name }}
                </h2>

                <!-- Text -->
                <p
                  v-if="profile.institution && profile.institution.location"
                  class="mt-0"
                >
                  {{ profile.institution.location.city }},
                  {{ profile.institution.location.region }} -
                  {{ profile.institution.location.country }}
                </p>

                <!-- Badges -->
                <h4 @click="accessFollowers" class="h4 mb-0 cursor-pointer">
                  {{ profile.followersCount }}
                  <span class="h4 mb-0 ms-1 text-muted">Followers</span>
                </h4>
              </div>

              <!-- Footer -->
              <div class="card-footer card-footer-boxed">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <router-link
                      :to="$paths.profile"
                      tag="a"
                      class="ps-0 btn btn-sm text-primary"
                    >
                      See Profile
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Weekly Hours -->
            <div class="card border-2">
              <div class="card-header border-0">
                <!-- Title -->
                <h2 class="card-title mb-1 h2">Follower Requests</h2>
              </div>
              <div class="card-body">
                <!-- Chart -->
                <h4 class="h4 mb-0">
                  {{ profile.requestsToFollowCount }}
                  <span class="h4 mb-0 ms-1 text-muted">New Follower requests</span>
                </h4>
              </div>
              <div class="card-footer card-footer-boxed">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <router-link
                      :to="$paths.audience"
                      tag="a"
                      class="ps-0 btn btn-sm text-primary"
                    >
                      See Requests
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent title="New Post" id="newPostModal">
      <template v-slot:body>
        <NewPostComponent v-on:newPostCreated="postCreated()" :isModal="true" />
      </template>
      <template v-slot:footer>
        <!-- <button type="button" @click="cropImage" class="btn btn-primary">
          Publish
        </button> -->
      </template>
    </ModalComponent>

  </div>
</template>
<script>
import SocialController from "./SocialController";
export default SocialController;
</script>
<style scoped>
.empty-container {
  height: calc(100vh - 70px);
}
#profile-right-container {
  position: fixed;
  right: 0;
}
</style>
