import { email, required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { USER_TYPE, NOVVA_APP_SOCKET_EVENT } from "@/utils/Constants";

export default {
  name: "Messages",
  data() {
    return {
      loadingAll: false,
      columns: ["Name", "Location", "Last message", "Match"],
      walletModal: null,
      allSelected: false,
      filtersCount: 0,
      showingItems: [],
      selectedTab: 0,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters("chat", ["CHATS"]),

    userSession() {
      return this.$store.state.userSession;
    },

    items() {
      return this.CHATS.slice(0);
    },
  },
  watch: {
    items: {
      handler(its) {
        this.$set(this, "showingItems", its);
      },
    },
  },
  methods: {
    ...mapActions("chat", ["getChats"]),
    async getData() {
      this.loadingAll = true;

      try {
        await this.getChats();
        this.$set(this, "showingItems", this.items);
      } catch (e) {
        console.log(e);
        this.$set(this, "showingItems", []);
      } finally {
        this.loadingAll = false;
      }
    },

    itemPressed(item) {
      this.$router.push({
        path: `${this.$paths.messages.chat}/${item.student._id}`,
      });
    },

    searchString(e) {
      const it = this.$helper.searchString(this.items, e.target.value);
      this.$set(
        this,
        "showingItems",
        it.filter((ftr) => !ftr.hidden)
      );
    },

    lastMessageNotRead(lastMessage) {
      const lM = lastMessage[0];
      if (!lM) return false;

      return (
        lM.user?.type === USER_TYPE.STUDENT &&
        (!lM.viewedBy || !lM.viewedBy.includes(this.userSession._id))
      );
    },

    lastMessageViewed(lastMessage) {
      const lM = lastMessage[0];
      if (!lM) return false;
      return lM.user?.type !== USER_TYPE.STUDENT && lM.viewedAt;
    },

    echo() {
      const studentIds = this.items.map((i) => i.student._id);

      if (studentIds.length > 0) {
        this.$socket.emit(NOVVA_APP_SOCKET_EVENT.echo, {
          data: {
            users: studentIds,
          },
        });
      }
    },
  },
  beforeMount() {
    this.getData().finally(() => {
      this.echo();
    });
  },
};
