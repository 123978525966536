
<template>
  <!-- TODO: include option do show checkbox on the first column -->
  <div class="base-table">
    <b-table
      responsive
      bordered
      hover
      show-empty
      :items="items"
      :fields="fields"
      v-bind="attrs"
      v-on="listeners"
    >
      <!-- pass on all scoped slots -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>

      <template #table-busy>
        <p>Loading...</p>
      </template>

      <template #empty>
        <p>{{ emptyMessage }}</p>
      </template>
    </b-table>

    <b-pagination
      :class="{ disabled: !hasPages }"
      v-if="showPagination"
      v-model="currentPage"
      v-bind="attrsPagination"
      v-on="listenersPagination"
      @page-click="emitUpdateCurrentPage"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue'

const PAGINATION = {
  PER_PAGE: 50
}

export default {
  name: 'BaseTable',

  inheritAttrs: false,

  components: {
    BTable,
    BPagination
  },

  props: {
    fields: {
      type: Array,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true
    },
    disableNativePagination: {
      type: Boolean,
      required: false,
      default: false
    },
    emptyMessage: {
      type: String,
      default: 'Nothing found.'
    },
    totalRows: {
      type: Number,
      default: null
    },
    pageUpdated: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      currentPage: 1
    }
  },

  computed: {
    itemsPerPage() {
      return this.$attrs['per-page'] || PAGINATION.PER_PAGE
    },

    listeners() {
      const { input, change, ...listeners } = this.$listeners
      return listeners
    },

    listenersPagination() {
      const { input, change, ...otherListeners } = this.$listeners
      let listeners = { input, change }

      Object.keys(listeners).forEach((key) =>
        listeners[key] === undefined ? delete listeners[key] : {}
      )

      return listeners
    },

    attrs() {
      const { 'per-page': perPage, ...attrs } = this.$attrs

      attrs.perPage = this.disableNativePagination ? 0 : this.itemsPerPage
      attrs.currentPage = this.currentPage

      return attrs
    },

    attrsPagination() {
      return {
        perPage: this.itemsPerPage,
        totalRows: this.totalRows || this.items.length
      }
    },

    hasPages() {
      return this.items.length > PAGINATION.PER_PAGE
    }
  },

  methods: {
    updateCurrentPage(page) {
      this.currentPage = page
    },

    emitUpdateCurrentPage(ev, page) {
      this.$emit('updateCurrentPage', page)
    }
  },

  watch: {
    pageUpdated(newVal) {
      if (newVal) this.updateCurrentPage(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .table {
  color: #757575;
  background-color: #FFFFFF !important;
  width: 100%;
  margin-bottom: 1.5rem;
  vertical-align: top;
  border-color: #ecebeb;
  caption-side: bottom;
  border-collapse: collapse;
  border: transparent;

  thead > tr > th {
    border: transparent;
  }

  tbody > tr > td {
    border-right: transparent;
    border-left: transparent;
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }

  .b-table-has-details > td {
    border-bottom: 0px;
  }

  .b-table-has-details > td:first-child {
    border-bottom-left-radius: 0px;
  }

  .b-table-has-details > td:last-child {
    border-bottom-right-radius: 0px;
  }

  .b-table-details > td {
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

/deep/ .pagination {
  justify-content: center;
  margin-top: 1.5rem;
}
</style>
