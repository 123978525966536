<template>
  <div class="scholarships">
    <div class="search-table-form">
      <div class="row align-items-center">
        <div class="col">
          <form>
            <div
              class="input-group input-group-flush input-group-merge input-group-reverse"
            >
              <input
                class="form-control list-search"
                type="search"
                @input="searchString"
                placeholder="Search by scholarship title"
              />
              <span class="input-group-text">
                <i class="fas fa-search ps-3"></i>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>

    <EmptyState
      title="No Scholarships"
      msg="It looks like you have no scholarships"
      v-if="items.length == 0"
    >
      <template v-slot:button>
        <router-link
          tag="button"
          :to="$paths.university.scholarship.add"
          type="button"
          class="btn btn-primary"
        >
          <i class="fas fa-plus-circle"></i>
          Add Scholarship
        </router-link>
      </template>
    </EmptyState>

    <table
      v-if="items.length > 0"
      class="table table-sm table-hover table-nowrap card-table bg-white"
    >
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="column"
            :class="{ 'text-start': index == 0 }"
            :width="index != 0 ? '1%' : 'auto'"
          >
            {{ column }}
          </th>
          
        </tr>
      </thead>
      <tbody class="list">
        <tr v-for="item in showingItems" v-bind:key="item.id" class="cursor-pointer">
          <td @click="itemPressed(item)" class="text-start">
            <strong>{{item.name}}</strong>
          </td>
          <td @click="itemPressed(item)">{{item.seats ? item.seats : '-'}}</td>
          <td @click="itemPressed(item)">
            <div class="progress progress-sm">
              <div
                ref="filledProgressToast"
                id="filled-progress-toast"
                class="progress-bar"
                role="progressbar"
                :style="`width: ${item.occupancyRate}%`"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </td>
          <td @click="itemPressed(item)">{{ item.applicationsCount }}</td>
          <td @click="itemPressed(item)">{{ $moment(item.deadline).format('MMMM,YYYY') }}</td>
          <td v-if="item.isActive" @click="itemPressed(item)">
            <span class="ms-auto badge bg-primary-soft">Active</span>
          </td>
          <td v-else @click="itemPressed(item)">
            <span class="ms-auto badge bg-secondary-soft">Inactive</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ScholarshipsController from "./ScholarshipsController";
export default ScholarshipsController;
</script>
<style scoped>
.progress {
  width: 160px;
}
</style>
