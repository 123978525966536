import PrivateContent from "@/components/PrivateContent.vue";
import PrivateField from "@/components/PrivateField.vue";
import PrivateExams from "@/components/PrivateExams.vue";
import PrivateFieldMultiselect from "@/components/PrivateFieldMultiselect.vue";
import PrivateFieldOfStudyMajors from "@/components/PrivateFieldOfStudyMajors.vue";
import PrivateFieldMultiselectLocations from "@/components/PrivateFieldMultiselectLocations.vue";
import ReadOnlyInput from "@/components/ReadOnlyInput.vue";
import { USER_TYPE } from '@/utils/Constants'

export default {
  name: "Signin",
  created() {
    this.loadingAll = true
    this.getData()
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },

    isHotlead() {
      return this.student.match && (this.student.match.value >= 90 ||
        this.student.match.value >= 70 && (this.student.match.itemsMatched && this.student.match.itemsMatched.investmentPerYear !== null))
    },

    isStudent() {
      return this.student.student.type === USER_TYPE.STUDENT
    },

    isParent() {
      return this.student.student.type === USER_TYPE.STUDENT_PARENT 
    },

    itemsMatchedData() {
      return this.student.match ? this.student.match.itemsMatched : []
    },

    studentData() {
      return this.student.student
    }
  },
  data() {
    return {
      loadingAll: false,
      loadingAccept: false,
      loadingMessage: false,
      errorMessage: '',
      accepted: false,
      isPeding: false,
      studentId: this.$route.params.id,
      student: {},
      universities: [],
      questions: [
        { id: 1, title: 'Question 1', answer: 'Answer to Question 1' },
        { id: 2, title: 'Question 2', answer: 'Answer to Question 2' },
        { id: 3, title: 'Question 3', answer: 'Answer to Question 3' },
        { id: 4, title: 'Question 4', answer: 'Answer to Question 4' },
      ],
      selectedTab: 0,
    };
  },
  methods: {
    getInvestmentPerYear(investmentPerYear) {
      return `${investmentPerYear.currency} ${this.$options.filters.numeral(investmentPerYear.value, '0,0[.]00')}` 
    },
    async getOrCreateMessage() {
      this.loadingMessage = true
      try {
        const { data } = await this.$messagesService.sendMessage({ 
          student: this.studentId
        })

        this.$router.push({ path: `${this.$paths.messages.chat}/${data.data.student}` })
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingMessage = false
      }
    },
    async getData() {
      try {
        const res = await this.$socialService.getStudent(this.studentId)
        let item = res.data.data
        this.$set(this, 'student', item)
        if (!item.student.name) {
          item.student.name = `${item.student.profile.firstname} ${item.student.profile.lastname}`
        }
        this.$set(this, 'accepted', item.isFollower)
        this.$set(this, 'isPeding', item.userFollowRequest ? item.userFollowRequest.status == 'PENDING' : false)
        this.$set(this, 'universities', item.followingUniversities)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAll = false
      }
    },
    async accept() {
      if (this.isFollower) return
      if (this.userSession.credits < this.student.costToAccept) {
        this.$eventBus.$emit("toggleWalletModal");
        return
      }
      try {
        this.errorMessage = ''
        this.loadingAccept = true
        const res = await this.$audienceService.acceptRequests([this.student.userFollowRequest._id])
        this.$eventBus.$emit('showToast', { title: `Follower Accepted`, msg: `${this.student.costToAccept} credits were deducted from your wallet.` })
      } catch (e) {
        console.log(e)
        this.errorMessage = e.message
      } finally {
        this.loadingAccept = false
        this.getData()
      }
    },
    getFieldLevelOfImportance(field) {
      if (this.student.student.profile.fieldsLevelOfImportance) {
        field = this.student.student.profile.fieldsLevelOfImportance.find((f) => f.field == field);
        return field ? field.level : ''
      }

      return ''
      
    },
    getCountriesOfInterest() {
      if (this.student.student.profile.parser.locationOfInterest) {
        return this.student.student.profile.parser.locationOfInterest.map((location) => {
          return {
            country: location.country,
            regions: location.regions ? location.regions : []
          }
        })
      }

      return []
    },
    getCountriesOfInterestMatched() {
      if (this.student.match) {
        return this.student.match.itemsMatched.locationOfInterest.map((location) => {
          return {
            country: location.country,
            regions: location.regions ? location.regions.map(region => region.name) : []
          }
        })
      }

      return []
    }
  },
  components: {
    PrivateField,
    PrivateExams,
    PrivateFieldMultiselect,
    PrivateFieldOfStudyMajors,
    PrivateFieldMultiselectLocations,
    PrivateContent,
    ReadOnlyInput,
  },
};