import Vue from "vue";
import Vuex from "vuex";
import NetworkService from "@/services/NetworkService";
import { USER_TYPE } from "@/utils/Constants";

import chat from "@/store/modules/chat";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userSession: JSON.parse(localStorage.getItem("userSession")),
    token: localStorage.getItem("token") ?? null,
    tour: JSON.parse(localStorage.getItem("tour")),
    loadingPreview: false
  },

  mutations: {
    saveTour(state, newSession) {
      state.tour = newSession;
      localStorage.setItem("tour", JSON.stringify(newSession));
    },
    saveSession(state, newSession) {
      state.userSession = newSession;
      localStorage.setItem("userSession", JSON.stringify(newSession));
    },
    saveToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    clearSession(state) {
      state.userSession = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userSession");
    },
    setLoadingPreview(state, val) {
      state.loadingPreview = val
    }
  },

  actions: {
    clearSession: ({ commit }) => {
      commit('clearSession')
    },

    syncSessionWithApi: async ({ commit }, { token } = { token: null }) => {
      const network = new NetworkService();

      const account = await network.get(network.endpoints.User.account, token);
      const resCredits = await network.get(
        network.endpoints.Financial.deposits,
        token
      );
      let credits = 0;
      let totalCreditsUsed = resCredits.data.data[0]
        ? resCredits.data.data[0].usedCredits
        : 0;
      resCredits.data.data.map((a) => {
        credits += a.totalCredits;
      });
      const isRepresentative =
        account.data.data.type == USER_TYPE.INSTITUTION_REPRESENTATIVE;
      const institution = isRepresentative
        ? account.data.data.account.profile.institution
        : account.data.data.profile.institution;


      const tour = {
        generalTour: account.data.data.tour ? account.data.data.tour.situation : null
      }
      commit("saveTour", tour);

      const response = {
        ...account.data.data,
        // email: account.data.data.email,
        id: account.data.data._id,
        profileId: isRepresentative
          ? account.data.data.account.profile._id
          : account.data.data.profile._id,
        isProfileCompleted: institution.location ? true : false,
        hasSubscription: account.data.data.hasSubscription,
        image: account.data.data.image,
        credits: credits - totalCreditsUsed,
        tour
      };

      if (token) {
        commit("saveToken", token);
      }
      commit("saveSession", response);
      return response;
    },
    setLoadingPreview: ({ commit }, val) => {
      commit("setLoadingPreview", val)
    }
  },

  getters: {
    TOUR: (state) => {
      return state.tour;
    },
    SOCKET_USER: (state) => {
      if (!state.userSession) {
        return {};
      }

      const user = state.userSession;
      let mainAccountId = user._id;
      let name = user.name;

      if (user.account) {
        mainAccountId = user.account?._id;
      } else {
        name = user.profile?.institution?.contact?.name || user.name;
      }

      return {
        _id: user._id,
        name,
        image: user.image,
        mainAccountId,
        slug: user.slug,
        type: user.type,
      };
    },
    TOKEN: (state) => {
      return state.token;
    },
    LOADING_PREVIEW: (state) => {
      return state.loadingPreview;
    },
  },

  modules: {
    chat,
  },
});
