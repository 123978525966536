<template>
  <div>
    <div>
      <label class="form-label mb-0">{{ label }}</label>
      <div class="input-group input-group-merge mb-0">
        <div
          v-if="!show"
          class="
            input-group-text
            border-right-0
            border-top-right-radius-0
            border-bottom-right-radius-0
            border-0
            mt-1
          "
          id="inputGroup"
        >
          <span class="fas fa-lock"></span>
        </div>
        <div
          v-if="typeof value == 'object' && show"
          class="
            border-top-left-radius-0 border-bottom-left-radius-0 border-left-0
            ml-0 pl-0 ml-0 mt-1
            border-0
          ">
          <span 
            v-for="(fieldsOfStudy, index) in value"
            :key="index"
            class="mb-0 me-2 mt-2 rounded bg-input field-span"
            :style="fieldsOfStudy.majors && fieldsOfStudy.majors.length === 0 ? 'vertical-align: text-bottom' : '' "
            >
              <span class="d-block field-name">{{fieldsOfStudy.name}}</span>

              <span 
                v-for="(major, majorindex) in fieldsOfStudy.majors"
                :key="majorindex"
                class="box-white field-span">
                {{ major.name }}
              </span>
          </span>
        </div>
        <input
          v-if="!show"
          disabled
          class="
            form-control
            border-top-left-radius-0 border-bottom-left-radius-0 border-left-0
            border-0
            mt-1
          "
          type="text"
          value="To see content, you need to accept"
          aria-label="Input group appended"
          aria-describedby="inputGroup"
        />
      </div>
    </div>
    <ReadOnlyInput v-if="value.length == 0 && show" class="mt-0" :value="'Not informed'" />
  </div>
</template>

<script>
import ReadOnlyInput from "./ReadOnlyInput.vue";
export default {
  name: "PrivateFieldOfStudyMajors",
  props: {
    label: String,
    show: Boolean,
    value: Array,
  },
  components: { ReadOnlyInput },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}
.bg-input {
  background-color: #ececec;
}
.field-span {
  padding: 0.5rem 0.75rem;
  display: inline-block;
  color: #4e4b4b;
  .field-name {
    margin-bottom: 5px;
  }
  .box-white {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-right: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

label {
  display: inline-block;
  color: #ed6125;
  font-weight: bold;
}

</style>
