<template>
  <div id="month-container" class="d-flex align-items-center">
    <button
      type="button"
      class="btn btn-sm bg-secondary-soft btn-circle"
      @click="prevMonth"
    >
      <i class="fas fa-chevron-left text-muted"></i>
    </button>

    <h2 class="mb-0 mx-4">
      <strong> {{ currentDate.monthName }}</strong
      >,
      <small class="text-muted">{{ currentDate.year }}</small>
    </h2>

    <button
      type="button"
      class="btn btn-sm bg-secondary-soft btn-circle"
      @click="nextMonth"
    >
      <i class="fas fa-chevron-right text-muted"></i>
    </button>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "MonthSelector",
  mounted() {
    const d = moment().startOf("month");
    this.currentDate = {
      date: d.format("YYYY-MM"),
      year: d.format("YYYY"),
      monthName: d.format("MMMM"),
    };
  },
  data() {
    return {
      currentDate: {},
    };
  },
  methods: {
    nextMonth() {
      const nextMonth = moment(this.currentDate.date).add(1, "M");
      this.currentDate = {
        date: nextMonth.format("YYYY-MM"),
        year: nextMonth.format("YYYY"),
        monthName: nextMonth.format("MMMM"),
      };
      this.$emit("monthSelectorChanged", this.currentDate);
    },
    prevMonth() {
      const nextMonth = moment(this.currentDate.date).add(-1, "M");
      this.currentDate = {
        date: nextMonth.format("YYYY-MM"),
        year: nextMonth.format("YYYY"),
        monthName: nextMonth.format("MMMM"),
      };
      this.$emit("monthSelectorChanged", this.currentDate);
    },
  },
  props: {
    title: String,
    msg: String,
  },
};
</script>

<style scoped>
#month-container {
  min-width: 200px;
  margin-bottom: 1px;
}
</style>
