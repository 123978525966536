<template>
  <div>
    <NavBar current="Onboarding" />
    <div
      class="
        container-fluid
        bg-dark
        vh-100
        py-7
        onboarding-content
        h-100
        justify-content-center
        align-items-center
      "
    >
      <div class="d-flex align-items-center flex-column my-6">
        <TimeCounter
          v-if="!hostLeft && showCounter"
          :title="'Metting duration'"
          :initialSeconds="initialSeconds"
          :initialMinutes="initialMinutes"
        />
      </div>
      <div class="row" v-if="!hostLeft">
        <div class="col-10 offset-1">
          <div class="row">
            <div
              :class="{
                'col-auto': myControls.screenShareEnabled,
                'col-12': !myControls.screenShareEnabled,
              }"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                :class="{ 'flex-column': myControls.screenShareEnabled }"
              >
                <div
                  class="
                    col-6
                    me-3
                    my-2
                    video-display
                    d-flex
                    align-items-end
                    overflow-hidden
                  "
                  :class="{ 'share-enabled': myControls.screenShareEnabled }"
                >
                <VonagePublisher
                  v-if="vonageSession"
                  v-show="myControls.videoEnabled"
                  :vonageSession="vonageSession"
                  @error="vonageErrorHandler"
                  @publish="vonagePublisherHandler"
                />
                  <h2
                    class="
                      h2
                      text-white
                      ps-3
                      participant-label
                    "
                  >
                    {{ userSession.name }}
                  </h2>
                  <div
                    v-if="!myControls.videoEnabled"
                    class="
                      col-6
                      me-3
                      overflow-hidden
                      video-display
                      bg-cover
                      d-flex
                      align-items-end
                    "
                    :class="{ 'bg-gray': !userSession.image }"
                    id="my-video-disabled-placeholder"
                  >
                    <img
                      v-if="userSession.image"
                      class="h-100 w-100"
                      :src="userSession.image"
                      alt=""
                    />
                    <img
                      v-else
                      style="object-fit: contain"
                      class="h-100 w-100"
                      src="@/assets/img/no-img-placeholder.png"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="
                    col-6
                    me-3
                    my-2
                    video-display
                    bg-black
                    flex-column
                    d-flex
                    align-items-start
                    justify-content-end
                    overflow-hidden
                  "
                  :class="{ 'share-enabled': myControls.screenShareEnabled }"
                >
                  <VonageSubscriber
                    v-if="vonageStream"
                    v-show="guestControls.videoEnabled"
                    :vonageSession="vonageSession"
                    :vonageStream="vonageStream"
                    @error="vonageErrorHandler"
                    @subscribe="vonageSubscriberHandler"
                  />
                  <h2 class="h2 text-white ps-3 participant-label">
                    {{ studentName }}
                    <small
                      v-if="!guestControls.videoEnabled"
                      class="fas fa-video-slash me-3 text-white"
                    ></small>
                    <small
                      v-if="!guestControls.micEnabled"
                      class="fas fa-microphone-slash text-white"
                    ></small>
                  </h2>
                  <div
                    v-if="waitingGuest"
                    id="waiting-guest"
                    class="
                      col-6
                      me-3
                      video-display
                      bg-black
                      flex-column
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    :class="{ 'share-enabled': myControls.screenShareEnabled }"
                  >
                    <span class="fas fa-clock fa-2x text-white"></span>
                    <h3 class="pt-2 text-white h3">Waiting for participant</h3>
                  </div>
                  <div
                    v-if="guestControls.videoEnabled"
                    class="video-display position-absolute"
                    :class="{ 'share-enabled': myControls.screenShareEnabled }"
                    id="guest-video-display"
                  ></div>
                </div>
              </div>
            </div>
            <VonageShareScreen
              v-if="myControls.screenShareEnabled"
              class="col"
              :vonageSession="vonageSession"
              @error="vonageShareScreenErrorHandler"
              @publish="vonageShareScreenHandler"
            />
          </div>

          <div class="row">
            <div class="d-flex justify-content-center">
              <div id="video-controls" class="mt-4">
                <button
                  class="btn me-3 btn-secondary btn-square"
                  :class="{ 'btn-success': myControls.screenShareEnabled }"
                  @click.prevent="toggleShareScreen()"
                >
                  <span class="fas fa-desktop"></span>
                </button>
                <button
                  class="btn me-3 btn-secondary btn-square"
                  @click.prevent="toggleVideo()"
                >
                  <span
                    v-if="myControls.videoEnabled"
                    class="fas fa-video"
                  ></span>
                  <span v-else class="fas fa-video-slash"></span>
                </button>
                <button
                  class="btn me-3 btn-secondary btn-square"
                  @click.prevent="toggleMic()"
                >
                  <span
                    v-if="myControls.micEnabled"
                    class="fas fa-microphone"
                  ></span>
                  <span v-else class="fas fa-microphone-slash"></span>
                </button>
                <button
                  class="btn me-3 btn-danger"
                  @click.prevent="leaveMeeting()"
                >
                  Leave
                  <span class="fas fa-door-open"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MeetingEnded v-else />
    </div>
  </div>
</template>

<script>
import MeetingController from "./MeetingController";
export default MeetingController;
</script>

<style scoped>
.video-display.share-enabled {
  height: 250px;
  width: 250px;
}
.video-display {
  height: 400px;
  width: 400px;
  border-radius: 10px;
}
/* #my-video-disabled-placeholder {
  background-image: url("https://i.pravatar.cc/300");
} */
#guest-video-disabled-placeholder {
  background: black;
}
.btn-square {
  width: 50px;
}
.guest-controls {
  z-index: 2;
}
.participant-label {
  z-index: 2;
  position: absolute;
  width: inherit;

}
div.bg-gray {
  background: #ebebeb;
}
</style>
