<template>
  <div ref="vonageSubscriber" class="vonage-subscriber"></div>
</template>

<script>
import OT from "@opentok/client";

const sessionSubscribe = (vonageSession, vonageSubscriber, vonageStream) => {
  return new Promise((resolve, reject) => {
    const subscriber = vonageSession.subscribe(
      vonageStream,
      vonageSubscriber,
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        fitMode: "contain",
        style: {
          nameDisplayMode: "on",
          buttonDisplayMode: "off",
        },
      },
      (error) => {
        if (error) {
          return reject(error);
        }

        return resolve(subscriber);
      }
    );
  });
};

export default {
  name: "VonageSubscriber",

  props: {
    vonageSession: {
      type: OT.Session,
      required: true,
    },
    vonageStream: {
      type: OT.Stream,
      required: true,
    },
  },

  async mounted() {
    let subscriber = null;
    subscriber = await sessionSubscribe(
      this.vonageSession,
      this.$refs.vonageSubscriber,
      this.vonageStream
    );

    try {
    } catch (e) {
      this.$emit("error", e);
    }

    this.$emit("subscribe", subscriber);
  },
};
</script>

<style lang="scss" scoped>
.vonage-subscriber {
  height: 100%;
  width: 100%;
}
</style>
