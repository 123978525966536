import NavBar from "@/components/NavBar";
import Sidebar from "@/components/Sidebar";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import { isExpirationDateValid } from '@/utils/Validators';
import { Toast } from 'bootstrap'
import FinancialService from '@/services/FinancialService'
import { mapActions } from 'vuex';
import { USER_TYPE } from '@/utils/Constants'
import BaseFormRadioGroup from '@/components/BaseFormRadioGroup'
import BaseFormCheckbox from '@/components/BaseFormCheckbox'

export default {
    mounted() {
        // this.getPacks()
        this.getPlans()
        this.getInstitutionRepresentatives()
    },
    components: {
        BaseFormRadioGroup,
        BaseFormCheckbox
    },
    data() {
        return {
            errorMessage: '',
            loading: false,
            form: {
                creditAmount: 0,
                paymentDetails: {
                    holder: "",
                    cardNumber: "",
                    expiration: "",
                    cvv: ""
                },
                billingAddress: {
                    address: "",
                    city: "",
                    state: "",
                    zipcode: ""
                },
                creditUsage: {
                    type: "JUST-MYSELF",
                    representatives: []
                },
                creditPack: '',
                creditType: 'PLAN',
                selectedPlan: null
            },
            step: 1,
            percentage: 25,
            paymentMethod: 'credit_card',
            prices: [],
            plans: [],
            institutionRepresentatives: [],
            representativesColumns: [
                '',
                'Name'
            ],
            purchaseType: {
                CREDIT: 'CREDIT',
                PLAN: 'PLAN'
            },
        };
    },
    validations: {
    form: {
        creditAmount: {
            required,
            minValue: minValue(1),
        },
        paymentDetails: {
            holder: { required },
            cardNumber: {
                required,
                minLength: minLength(16)
            },
            expiration: { required, minLength: minLength(7), isExpirationDateValid },
            cvv: { required }
        },
        billingAddress: {
            address: { required },
            city: { required },
            state: { required },
            zipcode: { required }
        },
        creditUsage: {
            type: { required },
            representatives: { required }
        }
    },
    },
    methods: {
        ...mapActions([
            'syncSessionWithApi'
        ]),
        getFormatAmount(amount) {
            let USDollar = new Intl.NumberFormat("en-US");
            return USDollar.format(amount);      
        },
        isAnualPlan() {
            return this.form.creditType == this.purchaseType.PLAN
        },
        isSelectedPlan(plan) {
            return this.form.selectedPlan && this.form.selectedPlan._id === plan
        },
        inputCreditType(type) {
            this.form.creditType = type;
            this.percentage = this.isAnualPlan() ? 50 : 25
        },
        inputSelectedPlan(plan) {
            this.form.selectedPlan = plan;
        },
        isInvalid(field) {
            return field && field.$invalid && field.$dirty
        },
        isSelected(id) {
            return this.form.creditUsage.representatives.includes(id)
        },
        allSelected() {
            return this.form.creditUsage.representatives.length == this.institutionRepresentatives.length
        },
        selectAllRepresentatives() {
            if (this.allSelected()) {
            this.form.creditUsage.representatives = []
            } else {
            this.form.creditUsage.representatives = this.institutionRepresentatives.map(i => i._id)
            }
        },
        selectRepresentative(id) {
            let index = this.form.creditUsage.representatives.indexOf(id)

            if (index >= 0) {
            this.form.creditUsage.representatives.splice(index, 1)
            } else {
            this.form.creditUsage.representatives.push(id)
            }
        },
        async getPlans() {
            const res = await this.$financialService.getPlans()
            let it = res.data.data
            this.$set(this, 'plans', res.data.data)
            // this.handleItemPressed(it[0])
        },
        async getPacks() {
            const res = await this.$financialService.getPacks()
            let it = res.data.data
            this.$set(this, 'prices', res.data.data)
            this.handleItemPressed(it[0])
        },
        async getInstitutionRepresentatives() {
            const res = await this.$userService.getInstitutionRepresentatives()
            this.institutionRepresentatives = res.data.data
        },
        nextStep(s) {
            this.step = s
            let percent = this.isAnualPlan() ? 50 : 25
            this.percentage = this.percentage + percent
        },
        handleItemPressed(item) {
            this.$set(this, 'prices', this.prices.map(r => { return { ...r, selected: r._id == item._id } }))
            const selected = this.prices.filter(r => r.selected)[0]
            this.$set(this.form, 'creditAmount', selected.credits)
            this.$set(this.form, 'creditPack', selected._id)
        },
        async handleCreateDeposit() {
            this.errorMessage = ''
            this.loading = true
            let toastMessages
            try {
                if (this.form.creditType == this.purchaseType.CREDIT) {
                    await this.$financialService.createDeposit({ ...this.form })
                    toastMessages = { title: 'Credits Added', msg: `${this.form.creditAmount} credits were added to your wallet.` }
                } else {
                    delete this.form.creditAmount
                    delete this.form.billingAddress
                    delete this.form.creditUsage
                    delete this.form.creditPack

                    await this.$financialService.createSubscription({ ...this.form })
                    toastMessages = { title: 'Plan purchased', msg: `Plan added to your account.` }
                }
                this.syncSessionWithApi()
                this.$eventBus.$emit('showToast', toastMessages)
                this.$router.push({ path: this.$paths.wallet.list })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        }
    },
}
