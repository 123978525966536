import MessagesService from "@/services/MessagesService";
import { MESSAGE_STATUS } from "@/utils/Constants";
import { USER_TYPE } from "@/utils/Constants";
import { uuid } from "vue-uuid";

const MESSAGE_SERVICE = new MessagesService();
const bulkMessages = {};
const DEBOUNCE_API = 2500;
const timer = {};

function debounce({ id, action }, func, wait) {
  if (!timer[id]) timer[id] = {};

  return function () {
    clearTimeout(timer[id][action]);
    timer[id][action] = setTimeout(func, wait);
  };
}

async function getChats({ commit }) {
  const res = await MESSAGE_SERVICE.getChatsObj();
  const {
    data: { data },
  } = res;

  commit("SET_CHATS", data ?? {});
  commit("SYNC_CHATS");
}

function setChatOnline({ state, commit }, payload) {
  let chats = { ...state.chats };

  if (payload.length > 0) {
    for (const [key, _] of Object.entries(chats)) {
      chats[key] = {
        ...chats[key],
        online: payload.includes(key),
      };
    }
  }

  commit("SET_CHATS", chats);
  commit("SYNC_CHATS");
}

async function getChat({ commit, state }, studentId) {
  if (!state.chats[studentId] || !state.chats[studentId]._id) {
    await MESSAGE_SERVICE.sendMessage({ student: studentId });
  }

  const res = await MESSAGE_SERVICE.getChatMessages(studentId);
  const {
    data: { data },
  } = res;

  const student = data.student;

  const messages = data.chat.messages.map((msg) => {
    let userName = msg.user.name;

    if (msg.user.type == USER_TYPE.INSTITUTION) {
      userName = msg.user.profile.institution.contact.name;
    }
    return {
      ...msg,
      user: {
        ...msg.user,
        name: userName,
      },
      status: msg.viewedAt ? MESSAGE_STATUS.VIEWED : MESSAGE_STATUS.SENT,
    };
  });

  let location = student.profile.location
    ? `${student.profile.location.city}, ${student.profile.location.region}`
    : "";

  const chatInfo = {
    firstMessage:
      data.chat.messages && data.chat.messages.length > 0
        ? data.chat.messages[0].createdAt
        : null,
    studentName: student.name,
    studentId: student._id,
    studentPicture: student.image || "",
    studentLocation: location,
  };

  commit("ADD_CHAT_MESSAGES", { studentId, messages });
  commit("ADD_CHAT_INFO", { studentId, chatInfo });
  commit("ADD_CHAT_ID", { studentId, _id: data._id });
  commit("SYNC_CHATS");
}

async function sendViewed({ commit }, studentId) {
  debounce(
    {
      id: studentId,
      action: "view",
    },
    async () => {
      await MESSAGE_SERVICE.readMessages(studentId);
    },
    DEBOUNCE_API
  )();
}

async function setMessageAsViewed({ commit }, studentId) {
  commit("SET_MESSAGE_AS_VIEWED", { studentId });
}

function addChat({ commit }, data) {
  const { studentId, chat } = data;

  commit("ADD_CHAT", { studentId, chat });
  commit("SYNC_CHATS");
}

async function addChatMessage({ commit, state, rootGetters }, data) {
  let { studentId, message } = data;
  const localId = uuid.v4();

  const chat = state.chats[studentId];

  const user = rootGetters["SOCKET_USER"];

  commit("ADD_CHAT_MESSAGE", {
    studentId,
    message,
    localId,
    status: MESSAGE_STATUS.SENDING,
  });
  commit("SYNC_CHATS");

  try {
    return await MESSAGE_SERVICE.sendMessage({
      messages: [
        {
          chatId: chat._id,
          message: message.message,
          localId,
          status: MESSAGE_STATUS.SENDING,
          userId: user._id,
        },
      ],
      student: studentId,
    }).then(({ data: { data } }) => {
      commit("UPDATE_CHAT_MESSAGE", {
        message: {
          ...data.messages[0],
          status: MESSAGE_STATUS.SENT,
          user,
        },
        studentId,
      });
      commit("SYNC_CHATS");
      return data.messages[0];
    });
  } catch (error) {
    console.log(`[sendMessage]/[${studentId}]`, error);
  }
}

function addMessage({ state, commit }, data) {
  const { studentId, message } = data;

  commit("ADD_CHAT_MESSAGE", { studentId, message });
}

export {
  getChats,
  getChat,
  sendViewed,
  addChat,
  setMessageAsViewed,
  addChatMessage,
  addMessage,
  setChatOnline,
};
