import { NOVVA_APP_SOCKET_EVENT } from "@/utils/Constants";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {},

  data() {
    return {
      sendingMessage: false,
    };
  },

  computed: {
    ...mapGetters(["SOCKET_USER"]),
    studentId() {
      return this.$route.params.id;
    },
    currentUser() {
      return this.SOCKET_USER;
    },
    visitorRoom() {
      let room = `GROUP:${this.currentUser.slug}:${this.currentUser._id}`;

      if (this.studentId) {
        room = `PRIVATE:STUDENT:${this.studentId}`;
      }

      return room;
    },
  },

  methods: {
    ...mapActions("chat", ["addChatMessage"]),

    async send({ text }) {
      if (!text) {
        return;
      }

      if (this.sendingMessage) {
        //return;
      }

      this.$socket.emit(NOVVA_APP_SOCKET_EVENT.viewedMessage, {
        room: this.visitorRoom,
        data: {},
      });

      this.sendingMessage = true;

      await this.addChatMessage({
        studentId: this.studentId,
        message: {
          message: text,
          user: this.currentUser,
        },
      })
        .then((res) => {
          this.$socket.emit(NOVVA_APP_SOCKET_EVENT.chatMessage, {
            room: this.visitorRoom,
            data: res,
          });
        })
        .finally(() => {
          this.sendingMessage = false;
        });
    },
  },
};
